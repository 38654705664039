import React, { Component } from 'react';
import { Redirect,Link } from "react-router-dom";
import { listpayment } from "../../Utils/auth";
import graphic from "../../Utils/images/graphic1.png";
import { NotificationManager} from 'react-notifications';
import dateFormat from 'dateformat';



class PaymentHistory extends Component {

    state = {
        job_id: "",
        timestamp: "",
        data: [],
        paymentlist: [],
        redirect: false,
        logout: false
    };

    componentDidMount() {
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const params = { client_id: client_id };

        listpayment(params)
            .then((data) => {
                console.log(data);
                if (data.error) {
                    this.setState({ error: data.error });
                    return;
                }
                localStorage.setItem("payment_history", JSON.stringify(data.data));
                const lists = JSON.parse(localStorage.getItem("payment_history"));
                this.setState({ paymentlist: lists});

                if (data.length === 0)
                    NotificationManager.info('No Payment Done ');


            });

        }


        render() {
            if (this.state.logout) {
                return <Redirect to="/" />
            }
            var file = this.state.paymentlist.map((ele, i) => {

                return (

                    <tr key={i} >
                        {/*<tr key={i} onClick={this.props.jobId.bind(this, ele.id)}>*/}
                        <td className="ele-city " style={{ textAlign: "center" }}>
                        {dateFormat(ele.timestamp, "dddd, mmmm dS, yyyy, h:MM:ss TT")}
                        </td>
                        <td className="ele-city " style={{ textAlign: "center" }}>
                            {ele.paid}
                        </td>

                        <td className="ele-city " style={{ textAlign: "center" }}>
                            {ele.credit}
                        </td>
                        <td className="ele-city " style={{ textAlign: "center" }}>
                            {ele.gst}
                        </td>
                        <td className="ele-city " style={{ textAlign: "center" }}>
                            {ele.status}
                        </td>
                        <td className="ele-city " style={{ textAlign: "center" }}>
                            {ele.status}
                        </td>
                        <td className="ele-city " style={{ textAlign: "center" }}><a href="# " style={{ color: "black"  }}><i class="fa fa-download"></i></a></td>

                    </tr>
                );
            });


            return (
                <div className="app-table" style={{ overflow: "scroll", height: "800px" }}>
                    <div className="header">
                        <div className="second-top-bar">
                            <h1 className="heading" style={{ color: "black" }}>Payment History</h1>
                            <br></br>
                        </div>
                    </div>

                    <div className="header">
                        <div className="second-top-bar">

                            <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>GST period</button>
                            <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>Online payments</button>
                        </div>
                    </div>
                    <table className="table">
                        <thead>
                            <tr>
                                {/* <th style={{ textAlign: "center" }}>Company Name</th> */}

                                <th style={{ textAlign: "center" }}>DATE OF PAYMENT</th>
                                <th style={{ textAlign: "center" }}>AMOUNT PAID</th>
                                <th style={{ textAlign: "center" }}>AMOUNT CREDITED	</th>
                                <th style={{ textAlign: "center" }}>GST</th>
                                <th style={{ textAlign: "center" }}>DESCRIPTION</th>
                                <th style={{ textAlign: "center" }}>STATUS</th>
                                <th style={{ textAlign: "center" }}>DOWNLOADS</th>


                            </tr>
                        </thead>
                        <tbody className="main-table">{file}</tbody>
                    </table>
                    <p className="aligncenter">
                        <img

                            src={graphic}
                            alt=""
                            style={{ width: "40%", align: "center" }}
                        />
                    </p>

                </div>



            );

        }
    }

    export default PaymentHistory;
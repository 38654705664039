import React, { Component } from "react";

import JobDetails from "./JobDetails/JobDetails";
import Button from "../../Utils/UI/Button/Button";
import "../../Utils/UI/SearchBar/Search.css";


import JobsDashboard from "../../TrialsInProject/JobsDashboard";
// import PopUp from "./UI/PopUp/PopUp"

class JobListing extends Component {
  state = {
    id: "",
    searchQuery: "",
    totalJob: 0,
    completed:0,
    total_candidate:0,
    filter1:"",
    filter2:"",
    filter3:"",
  };


  callbackFunction = (totalJob,total_candidate) => {
        this.setState({totalJob: totalJob})
        this.setState({total_candidate: total_candidate})
  };

  userFunction = (id) => {
    this.setState({ id });
    let x = document.getElementById("collapseTwo");
    x.classList.add("show");
    
    let y = document.getElementById("collapseOne");
    y.classList.remove("show")
  };

  handleChange = (name) => (event) => {
    this.setState({ error: "" });
    this.setState({ [name]: event.target.value });
  };

  componentDidMount() {
    document.title = "Signo Client Dashboard";
    if (localStorage.getItem("jobs")) {
      const applicants = JSON.parse(localStorage.getItem("jobs")).length;
      this.setState({ totalJob: applicants });
    }
    if (localStorage.getItem("jobs_meta")) {
      const data = JSON.parse(localStorage.getItem("jobs_meta"));
      
      this.setState({ completed: data.completed });
      this.setState({ total_candidate: data.total_interested });
    }

  }

  // componentWillUpdate() {
    // const t = JSON.parse(localStorage.getItem("jobs")).length;
    // if (this.state.totalJob !== t)
    //   this.setState({ totalJob: t });

  // }


  filter = () => {
    let filter1 = 1;
    let filter2;
    if (document.getElementById("inlineRadio1").checked) {
      filter2 = true;
    } else if (document.getElementById("inlineRadio2").checked) {
      filter2 = false;
    } else {
      filter2 = null;
    }
   
    if (!filter1) {
      filter1 = 1;
    }
  
    this.setState({filter1,filter2});
  };

  render() {

      return (
      <div>
        <div className="search-container  mt-2">
          <form link="/">
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
            <input
           
           
              type="text"
              placeholder="Search for any job"
              name="search"
              onChange={this.handleChange("searchQuery")}
            />
          </form>
        </div>


            {/* <div style={{ backgroundColor: "#ffcf0d", textAlign: "center" }}> */}
            {/*     <p ><b>Recruiter Update:</b> <b style={{ color: "maroon" }}>DHL scored 200 Drivers in october month in pune | Gitforce Sourced 130 delivery boys in kolkata | Amazon sourced 250 packaging executive in Bangalore</b></p> */}
            {/* </div> */}

        <div className="container-fluid m-2">
          <div className="row">
            <div className="col-sm-9">
              <div
                className="row"
                style={{ marginLeft: "70px", marginTop: "15px" }}
              >
                <Button count={this.state.totalJob} title="Active Jobs"></Button>
                <Button count={this.state.completed} title="Completed"></Button>
                <Button count={this.state.total_candidate} title="Total Candidates"></Button>
                <Button count={"--"} title="New Candidates"></Button>
                {/* <Button  title="Job Details" /> */}
                
              </div>
              <JobsDashboard
                
                jobId={this.userFunction}
                searchQuery={this.state.searchQuery}
                filter1 = {this.state.filter1}
                filter2 = {this.state.filter2}
                filter3 = {this.state.filter3}
                parentCallback = {this.callbackFunction}
              />
            </div>
            <div className="col-sm-3" style={{marginTop:"15px"}}>

              <div id="accordion">
               <div className="card">
                 <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" id="filterbtn" aria-expanded="true" aria-controls="collapseOne" style={{textDecoration:"none"}}>
                        FILTER
                      </button>
                    </h5>
                  </div>

                  <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">

                       
                  <div className="card-body">
                    <div className="row" style={{ margin: "10px 0px" }}>
                      <div className="col-4">Active</div>

                      <div className="col-8">
                        <label className="radio-inline" style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="yes"
                            onChange={this.filter}
                          />
                          YES&nbsp;
                        </label>
                        <label className="radio-inline" style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="no"
                            onChange={this.filter}
                          />
                          NO&nbsp;
                        </label>
                        <label className="radio-inline" style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio3"
                            value="all"
                            onChange={this.filter}
                          />
                          ALL
                        </label>
                      </div>
                    </div>
                    {/* <div className="row" style={{ margin: "0px" }}> */}
                    {/*   <div className="col-12"> */}
                    {/*     <select className="custom-select" id="filter3"  */}
                    {/*         onChange={this.filter} value="Registration Date"> */}
                    {/*       <option defaultValue>Registration Date</option> */}
                    {/*       <option value="0">Today</option> */}
                    {/*       <option value="7">Last 7 days</option> */}
                    {/*       <option value="30">Last month</option> */}
                    {/*     </select> */}
                    {/*   </div> */}
                    {/* </div> */}
                  </div> 
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" id="profileBtn" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{textDecoration:"none"}}>
                        JOB DETAILS
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      {/* {this.state.id!="" ? ():()} */}
                      <JobDetails jobId={this.state.id} />
                   
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
        {/* <PopUp/> */}
      </div>
    );
  }
}

export default JobListing;

import React, { Component } from "react";
import "./MakePayment.css";
import logo from "../../Utils/dummy/logo.jpg";
// import Axios from 'axios'

function loadScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = src;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

 function generateOrderID(tAmount) {

  fetch(`${process.env.REACT_APP_API_URL}/business/razor_get_order_id/`, {
  method: "POST",
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization:`Bearer ${JSON.parse(localStorage.getItem('jwt')).new_token}`
  },
  redirect: "follow",
  body: JSON.stringify({
    "amount":tAmount,
    "package_id":"3",
    "client_id":JSON.parse(localStorage.getItem('jwt')).client_id
}),
}).then((response) => {
  // console.log(response.json())
  return response.json();
   
},(err)=>{
  alert(err)
  console.log(err)
}).then((data)=>{
  // alert("response")
  console.log(data.order_id)
  // return data.order_id
  console.log(tAmount)
  if(data.order_id.length > 1) {
   fundedRazorpay(tAmount,data.order_id)}
   else {
     alert("Order id is not generated")
   }
})

}
 

async function fundedRazorpay(tAmount,orderId) {
  console.log(orderId)
  tAmount = parseInt(tAmount) * 100;
  // console.log(orderId)
  // console.log(typeof(orderId))

  const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");

  if (!res) {
    alert("Razorpay SDK failed to load. Are you online?");
    return;
  }
  const options = {
    key: "rzp_live_w1xIJqGefVChFR",
    currency: "INR",
    amount: tAmount,
    order_id: orderId,
    name: "SIGNO",
    description: "",
    image: { logo },
    handler: function(response) {
      if (response.razorpay_payment_id) {
        alert("Successfully Payment Done");
      } else {
        alert("Payment Failed");
      }
      alert(response.razorpay_order_id);
      alert(response.razorpay_signature);
    },
    prefill: {
      name: localStorage.getItem('name'),
      email: localStorage.getItem('email'),
      phone_number: localStorage.getItem('phone_number'),
    },
  };
  const paymentObject = new window.Razorpay(options);
  paymentObject.open();
}

// async function fundedRazorpay1() {
//     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

//     if (!res) {
//         alert('Razorpay SDK failed to load. Are you online?')
//         return
//     }
//     const options = {
//         key: 'rzp_live_w1xIJqGefVChFR',
//         currency: 'INR',
//         amount:'236000',
//         order_id: '',
//         name: 'SIGNO',
//         description: '',
//         image: { logo },
//         handler: function (response) {
//             if (response.razorpay_payment_id) {
//                 alert("Successfully Payment Done");
//             }
//             else {
//                 alert("Payment Failed");
//             }
//             alert(response.razorpay_order_id)
//             alert(response.razorpay_signature)
//         },
//         prefill: {
//             name: 'ashutosh',
//             email: 'sdfdsjfh2@ndsfdf.com',
//             phone_number: '9899999999'
//         }
//     }
//     const paymentObject = new window.Razorpay(options)
//     paymentObject.open()
// }

// async function fundedRazorpay2() {
//     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

//     if (!res) {
//         alert('Razorpay SDK failed to load. Are you online?')
//         return
//     }
//     const options = {
//         key: 'rzp_live_w1xIJqGefVChFR',
//         currency: 'INR',
//         amount:'590000',
//         order_id: '',
//         name: 'SIGNO',
//         description: '',
//         image: { logo },
//         handler: function (response) {
//             if (response.razorpay_payment_id) {
//                 alert("Successfully Payment Done");
//             }
//             else {
//                 alert("Payment Failed");
//             }
//             alert(response.razorpay_order_id)
//             alert(response.razorpay_signature)
//         },
//         prefill: {
//             name: 'ashutosh',
//             email: 'sdfdsjfh2@ndsfdf.com',
//             phone_number: '9899999999'
//         }
//     }
//     const paymentObject = new window.Razorpay(options)
//     paymentObject.open()
// }

// async function fundedRazorpay3() {
//     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

//     if (!res) {
//         alert('Razorpay SDK failed to load. Are you online?')
//         return
//     }
//     const options = {
//         key: 'rzp_live_w1xIJqGefVChFR',
//         currency: 'INR',
//         amount:'1018000',
//         order_id: '',
//         name: 'SIGNO',
//         description: '',
//         image: { logo },
//         handler: function (response) {
//             if (response.razorpay_payment_id) {
//                 alert("Successfully Payment Done");
//             }
//             else {
//                 alert("Payment Failed");
//             }
//             alert(response.razorpay_order_id)
//             alert(response.razorpay_signature)
//         },
//         prefill: {
//             name: 'ashutosh',
//             email: 'sdfdsjfh2@ndsfdf.com',
//             phone_number: '9899999999'
//         }
//     }
//     const paymentObject = new window.Razorpay(options)
//     paymentObject.open()
// }

// async function fundedRazorpay4() {
//     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

//     if (!res) {
//         alert('Razorpay SDK failed to load. Are you online?')
//         return
//     }
//     const options = {
//         key: 'rzp_live_w1xIJqGefVChFR',
//         currency: 'INR',
//         amount:'2036000',
//         order_id: '',
//         name: 'SIGNO',
//         description: '',
//         image: { logo },
//         handler: function (response) {
//             if (response.razorpay_payment_id) {
//                 alert("Successfully Payment Done");
//             }
//             else {
//                 alert("Payment Failed");
//             }
//             alert(response.razorpay_order_id)
//             alert(response.razorpay_signature)
//         },
//         prefill: {
//             name: 'ashutosh',
//             email: 'sdfdsjfh2@ndsfdf.com',
//             phone_number: '9899999999'
//         }
//     }
//     const paymentObject = new window.Razorpay(options)
//     paymentObject.open()
// }

// async function fundedRazorpay5() {
//     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

//     if (!res) {
//         alert('Razorpay SDK failed to load. Are you online?')
//         return
//     }
//     const options = {
//         key: 'rzp_live_w1xIJqGefVChFR',
//         currency: 'INR',
//         amount:'5900000',
//         order_id: '',
//         name: 'SIGNO',
//         description: '',
//         image: { logo },
//         handler: function (response) {
//             if (response.razorpay_payment_id) {
//                 alert("Successfully Payment Done");
//             }
//             else {
//                 alert("Payment Failed");
//             }
//             alert(response.razorpay_order_id)
//             alert(response.razorpay_signature)
//         },
//         prefill: {
//             name: 'ashutosh',
//             email: 'sdfdsjfh2@ndsfdf.com',
//             phone_number: '9899999999'
//         }
//     }
//     const paymentObject = new window.Razorpay(options)
//     paymentObject.open()
// }

// async function fundedRazorpay6() {
//     const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

//     if (!res) {
//         alert('Razorpay SDK failed to load. Are you online?')
//         return
//     }
//     const options = {
//         key: 'rzp_live_w1xIJqGefVChFR',
//         currency: 'INR',
//         amount:'11800000',
//         order_id: '',
//         name: 'SIGNO',
//         description: '',
//         image: { logo },
//         handler: function (response) {
//             if (response.razorpay_payment_id) {
//                 alert("Successfully Payment Done");
//             }
//             else {
//                 alert("Payment Failed");
//             }
//             alert(response.razorpay_order_id)
//             alert(response.razorpay_signature)
//         },
//         prefill: {
//             name: 'ashutosh',
//             email: 'sdfdsjfh2@ndsfdf.com',
//             phone_number: '9899999999'
//         }
//     }
//     const paymentObject = new window.Razorpay(options)
//     paymentObject.open()
// }

class TopUp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      r1: "2360",
    };
    this.onCheckChange = this.onCheckChange.bind(this);
  }

  onCheckChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  render() {
    const rate = this.state.r1;
    let button;
    if (rate === "2360")
      button = (
        <button
          type="submit"
          onClick={() => {
            generateOrderID(this.state.r1)
          }}
          className="submit btn btn-primary"
          style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
        >
          Proceed
        </button>
      );
    if (rate === "5900")
      button = (
        <button
          type="submit"
          onClick={() => {
            generateOrderID(this.state.r1)
          }}
          className="submit btn btn-primary"
          style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
        >
          Proceed
        </button>
      );
    if (rate === "10180")
      button = (
        <button
          type="submit"
          onClick={() => {
            generateOrderID(this.state.r1)
          }}
          className="submit btn btn-primary"
          style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
        >
          Proceed
        </button>
      );
    if (rate === "20360")
      button = (
        <button
          type="submit"
          onClick={() => {
            generateOrderID(this.state.r1)
          }}
          className="submit btn btn-primary"
          style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
        >
          Proceed
        </button>
      );
    if (rate === "59000")
      button = (
        <button
          type="submit"
          onClick={() => {
            generateOrderID(this.state.r1)
          }}
          className="submit btn btn-primary"
          style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
        >
          Proceed
        </button>
      );
    else if (rate === "118000")
      button = (
        <button
          type="submit"
          onClick={() => {
            // fundedRazorpay(this.state.r1);
            generateOrderID(this.state.r1)
           
          }}
          className="submit btn btn-primary"
          style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
        >
          Proceed
        </button>
      );

    return (
      <div>
        <form id="ratess">
          <table className="table table-stripped">
            <tbody>
              <tr>
                <td>
                  <div>
                    <label
                      className="choice-radio"
                      style={{ display: "inline" }}
                    >
                      <input
                        type="radio"
                        name="r1"
                        value="2360"
                        checked={this.state.r1 === "2360"}
                        onChange={this.onCheckChange}
                      />
                      Rs 2,000 (4 Credit = 4 Applicant)
                    </label>
                  </div>
                </td>
                <td />
                <td>
                  <div>
                    <label
                      className="choice-radio"
                      style={{ display: "inline" }}
                    >
                      <input
                        type="radio"
                        value="5900"
                        name="r1"
                        checked={this.state.r1 === "5900"}
                        onChange={this.onCheckChange}
                      />
                      Rs 5,000 (15 Credit = 15 Applicant)
                    </label>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div>
                    <label
                      className="choice-radio"
                      style={{ display: "inline" }}
                    >
                      <input
                        type="radio"
                        value="10180"
                        name="r1"
                        checked={this.state.r1 === "10180"}
                        onChange={this.onCheckChange}
                      />
                      Rs 10,000 (30 Credit = 30 Applicant)
                    </label>
                  </div>
                </td>
                <td />
                <td>
                  <div>
                    <label
                      className="choice-radio"
                      style={{ display: "inline" }}
                    >
                      <input
                        type="radio"
                        value="20360"
                        name="r1"
                        checked={this.state.r1 === "20360"}
                        onChange={this.onCheckChange}
                      />
                      Rs 20,000 (60 Credit = 60 Applicant)
                    </label>
                  </div>
                </td>
              </tr>

              <tr>
                <td>
                  <div>
                    <label
                      className="choice-radio"
                      style={{ display: "inline" }}
                    >
                      <input
                        type="radio"
                        value="59000"
                        name="r1"
                        checked={this.state.r1 === "59000"}
                        onChange={this.onCheckChange}
                      />
                      Rs 50,000 (150 Credit = 150 Applicant)
                    </label>
                  </div>
                </td>
                <td />
                <td>
                  <div>
                    <label
                      className="choice-radio"
                      style={{ display: "inline" }}
                    >
                      <input
                        type="radio"
                        value="118000"
                        name="r1"
                        checked={this.state.r1 === "118000"}
                        onChange={this.onCheckChange}
                      />
                      Rs 1,00,000 (300 Credit = 300 Applicant)
                    </label>
                  </div>
                </td>
              </tr>

              {/*                     <tr> */}
              {/*                         <td><input type="checkbox" /> Rs50</td> */}
              {/*                         <td></td> */}
              {/*                         <td><input type="checkbox" /> Rs50</td> */}
              {/*                     </tr> */}
              {/*  */}
              {/*                     <tr> */}
              {/*                         <td><input type="checkbox" /> Rs50</td> */}
              {/*                         <td></td> */}
              {/*                         <td><input type="checkbox" /> Rs50</td> */}
              {/*                     </tr> */}
              {/*  */}
              {/*  */}
              {/*                     <tr> */}
              {/*                         <td><input type="checkbox" /> Rs50</td> */}
              {/*                         <td></td> */}
              {/*                         <td><input type="checkbox" /> Rs50</td> */}
              {/*                     </tr> */}
            </tbody>
          </table>
        </form>

        <div className="header">
          <div className="second-top-bar">
            <p>
              <b>Payment Summary</b>{" "}
            </p>

            <div class="row">
              <div class="col-lg-auto">GST (18%)</div>
              <div class="col-lg-auto">:</div>
              <div class="col-lg-auto">
                Total amount : {this.state.r1.toString()}
              </div>
            </div>

            {/*                     <div class="row"> */}
            {/*                         <div class="col-lg-auto"> */}
            {/*                             State GST */}
            {/*                         </div> */}
            {/*                         <div class="col-lg-auto"> */}
            {/*                             : */}
            {/*                          </div> */}
            {/*                         <div class="col-lg-auto"> */}
            {/*                             Rs. 00.00 */}
            {/*                         </div> */}
            {/*                     </div> */}
            {/*  */}
            {/*                     <div class="row"> */}
            {/*                         <div class="col-lg-auto"> */}
            {/*                             Integrated GST */}
            {/*                         </div> */}
            {/*                         <div class="col-lg-auto"> */}
            {/*                             : */}
            {/*                         </div> */}
            {/*                         <div class="col-lg-auto"> */}
            {/*                             Rs. 90.00 */}
            {/*                         </div> */}
            {/*                     </div> */}
          </div>
        </div>

        {/*             <div className="header" > */}
        {/*                 <div className="second-top-bar"> */}
        {/*                     <br></br> */}
        {/*                     <br></br> */}
        {/*                     <div class="row" > */}
        {/*                         <div class="col-lg-auto" > */}
        {/*                             <p><b>Deducy TDS at?</b>    </p> */}
        {/*  */}
        {/*                             <form> */}
        {/*                                 <label class="choice-radio" style={{ display: "inline" }}> */}
        {/*                                     <input type="radio" name="optradio" />None */}
        {/*                             </label> */}
        {/*                                 <br></br> */}
        {/*                                 <label class="choice-radio" style={{ display: "inline" }}> */}
        {/*                                     <input type="radio" name="optradio" />1% */}
        {/*                             </label> */}
        {/*                                 <br></br> */}
        {/*                                 <label class="choice-radio" style={{ display: "inline" }}> */}
        {/*                                     <input type="radio" name="optradio" />1.5% */}
        {/*                             </label> */}
        {/*                             </form> */}
        {/*                         </div> */}
        {/*                     </div> */}
        {/*                 </div> */}
        {/*             </div> */}
        {button}
      </div>
    );
  }
}

export default TopUp;

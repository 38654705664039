import React, { useState } from "react";
import "./ManageTeam.css";

import { addmanager,listmanager } from "../../Utils/auth";


function ManageForm(props) {

    const [member, setMember] = useState({
        name: "",
        phone: "",
        confirmPhone: "",
        email: "",
        role: ""
    })

    function add_manager() {
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id; 
        const params2 = { client_id: client_id, phone_number: member.confirmPhone, name: member.name, role: member.role, email: member.email };
        addmanager(params2)
            .then((data) => {
                console.log(data); 
            });
    }



    function handleChange(event) {
        const { name, value } = event.target;

        setMember(prevMember => {
            return {
                ...prevMember,
                [name]: value
            }
        })

    }

    function submitMember(event) {

        var ele1 = document.getElementById("role_type");
        var strUser1 = ele1.options[ele1.selectedIndex].value;
        member.role = strUser1;
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id; 
        const params2 = { client_id: client_id};
        listmanager(params2)
            .then((data) => {
                console.log(data);
                localStorage.setItem("member", JSON.stringify(data.data));

            });

        props.onAdd(member);


        setMember({
            name: "",
            phone: "",
            confirmPhone: "",
            email: "",
            role: ""
        })
        event.preventDefault();
    }








    return (


        <div className="team-column col-lg-4" style={{ paddingTop: "0px", marginTop: "0px" }}>
            <div className="manage-team-container container">
                <h1 className="heading">Manage Team Info</h1>
                <br></br>
                <form>

                    <div className="form-group">
                        <label htmlFor="Name" className="tag">Name</label>
                        <br></br>
                        <input type="text" name="name" value={member.name} onChange={handleChange} className="form-control" id="Name" placeholder="Enter name" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="PhoneNo" className="tag">Phone Number</label>
                        <br></br>
                        <input type="number" name="phone" value={member.phone} onChange={handleChange} className="form-control" id="PhonrNo" placeholder="Enter phone no" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="PhoneNumberConfirm" className="tag">Confirm Phone Number</label>
                        <br></br>
                        <input type="number" name="confirmPhone" value={member.confirmPhone} onChange={handleChange} className="form-control" id="ConfirmPhone" placeholder="Confirm phone no" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="Email" className="tag">Email (optional)</label>
                        <br></br>
                        <input type="text" name="email" value={member.email} onChange={handleChange} className="form-control" id="Email" placeholder="Enter email" />
                    </div>

                    <div className="btn-group">
                        <select
                            id="role_type"
                            style={{ width: "100%", borderRadius: "10px", height: "40px" }}
                        >
                            <option
                                name="role"
                                className="dropdown-item"
                                onChange={handleChange}
                            >
                                &nbsp; Role
              </option>
                            <option
                                name="role"
                                className="dropdown-item"
                                value={"Admin"}
                                style={{ color: "white" }}
                                onChange={handleChange}
                            >
                                &nbsp; Admin
              </option>
                            <option
                                name="role"
                                className="dropdown-item"
                                value={"Manager"}
                                style={{ color: "white" }}
                                onChange={handleChange}
                            >
                                &nbsp; Manager
              </option>

                        </select>
                    </div>
                    <br></br><br></br>

                    <div className="col">
                        <button type="submit" className="submit btn btn-primary" onClick={add_manager(), submitMember} style={{ width: "100%", borderRadius: "100px", textAlign: "center" }}>Submit</button>
                    </div>
                </form>
            </div>
        </div>

    );
}

export default ManageForm;
import React, { Component } from 'react'

import { Redirect,Link } from "react-router-dom";
import {getAllJobs,getJobReport,spocCall } from "../Utils/auth";
// import {spocCall} from "../../Utils/GraphQL";
import graphic from "../Utils/images/graphic1.png";
import { NotificationManager} from 'react-notifications';

import "./JobsDashboard.css";
import PostJob from "../Pages/postJob/PostJob";
import PopUp from "../Utils/UI/PopUp/PopUp";

import ActivateStatus from "../Utils/popup/ActivateStatus";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

 class JobsDashboard extends Component {
    state = {
        job_id: "",
        jobs: [],
        searchJobs: [],
        offset: 0,
        data: [],
        perPage: 40,
        currentPage: 0,
        filter: "none",
        redirect:false,
        logout:false
      };
    
      componentDidUpdate(prevProps) {
        if((this.props.filter1 !== prevProps.filter1) || (this.props.filter2 !== prevProps.filter2) || (this.props.filter3 !== prevProps.filter3)){
          this.filterData();
        }
        if (prevProps.searchQuery !== this.props.searchQuery) {
          var sQ = this.props.searchQuery.toLowerCase().trim();
    
          var updateJobs = [];
          
          this.state.jobs.forEach((element) => {
            if (
              element.job_title.toLowerCase().indexOf(sQ) !== -1 ||
              element.address.toLowerCase().indexOf(sQ) !== -1
            ) {
              updateJobs.push(element);
            }
            else if(sQ===""){
              updateJobs.push(element);
            }
          });
          this.setState({ searchJobs: updateJobs });
        }
      }
    
    
    
    
      
    
    
      componentDidMount() {
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const username = JSON.parse(localStorage.getItem("username"));
        const params = { job_id: this.state.job_id,client_id:client_id,username:username };
        
    
        
    
        getAllJobs(params)
          .then((data) => {
            console.log(data);
            if (data.error) {
              this.setState({ error: data.error });
              return;
            }
            localStorage.setItem("jobs", JSON.stringify(data.data));
            localStorage.setItem("jobs_meta", JSON.stringify(data));
            localStorage.setItem("is_profile_complete", (data.is_profile_complete));
            this.receivedData(this.state.perPage);
            this.props.parentCallback(data.data.length, data.total_interested);
            if (data.data.length===0)
              NotificationManager.info('Try Posting a Job');
    
            if (!data.is_profile_complete){
              window.location="/edituserinfo"
            }
          })
          .catch((err) => {
            console.log("Error in getting all the users", err);
            if (typeof window !== "undefined") {
                localStorage.removeItem("jwt");
                localStorage.removeItem("profiles");
                localStorage.removeItem("username");
                localStorage.removeItem("jobs");
              }
              NotificationManager.error("Please Logout")
              window.location.reload(false);
          });
      }
    
      receivedData(valuePerPage) {
        const data = JSON.parse(localStorage.getItem("jobs"));
        const setData = data.splice(this.state.offset * valuePerPage, valuePerPage);
        
        this.setState({
          pageCount: Math.ceil(data.length / valuePerPage),
          searchJobs: setData,
          jobs:setData,
        });
      }
    
      filterData = () => {
        const {filter1,filter2} = this.props;
        console.log(filter1, filter2);
        const data = JSON.parse(localStorage.getItem("jobs"));
        var updateUsers = [];
        data.forEach((element) => {
          
            if (element.is_active === filter2) {
            
                updateUsers.push(element);
              
            } else if (filter2==null){
              updateUsers.push(element);
              
            }
    
          
        });
    
        console.log("FILTER", updateUsers);
        this.setState({ searchJobs: updateUsers });
      };
    
      transfer = (id) => {
        console.log("This is my info", id);
      };
    
    
      handleApplication = (job_id) => {
        // this.setState({redirect:true,job_id:job_id})
        const link = `/applicants/`+job_id
        window.open(link, "_blank")
      }
      handleInterview = (job_id) => {
        // this.setState({redirect:true,job_id:job_id})
        const link = `/interview/`+job_id
        window.open(link, "_blank")
      }
    
      handelReport =  (job_id) => {
    
        const params = {
         
          job_id:job_id,
          
        };
    
        getJobReport(params)
          .then((data)=>{
            const endPoint = data.url;
            console.log("EXCEL SHEET:",endPoint);
            window.open(endPoint);
          })
          .catch(err=>{
            console.log("ERROR",err);
        })
        }
        handlePopup = (job_id) => {
            return(<PostJob />)
    }
    
      
        handelRefresh = () => {
            NotificationManager.info("Refreshing .....");
        }
    
        addJobID = (e,id)=>{
         
          if(e.target.checked){
          localStorage.setItem('jobid',id)}
        }
    
    
      render() {
        if (this.state.logout){
          return <Redirect to="/"/>
        }
        if(this.state.redirect){
          const link = `/applicants/${this.state.job_id}`
          return <Link to={link} target="_blank "/>
        }
         
          var file = this.state.searchJobs.map((ele, i) => {
                         
                        
              return (
    
                  <tr key={i} onClick={this.props.jobId.bind(this, ele.id)}>
                    <th scope="row" style={{lineHeight:"50px",textAlign: "left" }}>
                       <input
                            onChange={(e)=>{this.addJobID(e,ele.job_id)}}
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                            style={{marginLeft:"0px",width:"50px"}}
                          />
                    </th>
                      {/*<tr key={i} onClick={this.props.jobId.bind(this, ele.id)}>*/}
                      <td style={{lineHeight:"50px"}} >
                         {/* <input
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                          
                          /> */}
                          {ele.job_title}
                      </td>
                      <td style={{lineHeight:"50px"}}>
                          {ele.address}
                      </td>
    
                      <td style={{lineHeight:"50px"}} >
                          {ele.application_age}
                      </td>
                      <td >
                          {ele.is_active ? (
                              <i className="fa fa-check" style={{ color: "green" }}></i>
                          ) : (<>
                                  {/*--------- Popup Code ---------*/}
                                  <Popup
                                      contentStyle={{ background: "none", border: "none" }}
                                      trigger={
                                          <i className="fa fa-times" style={{ color: "red" }}></i>
                                      } modal
                                      nested >
    
                                      {close => (
                                          <div>
                                              <div style={{ paddingRight: "370px" }}>
                                                  <a href="# " className="close pull-right" onClick={close}  >
                                                      &times;
    </a>
                                              </div>
                                              <div>
                                                  <ActivateStatus />
                                              </div>
                                          </div>
                                      )}
    
                                  </Popup>
    
                                  {/*--------- Popup Code ---------*/}
                                  {/* <i className="fa fa-times" style={{ color: "red" }}></i>*/}
                                  <br /><small>
                                    {ele.status}
                                  </small>
                          </>
                              )}
                      </td>
                      <td style={{lineHeight:"50px"}} >
                          <button className="btn text-lowercase" onClick={() => this.handleApplication(ele.job_id + "X")}>
                              <small>{ele.matching_profile}</small>
                          </button>|
                <button className="btn text-lowercase" onClick={() => this.handleInterview(ele.job_id)} disabled={ele.total_interviewed === '0 interview'}>
                              <small>{ele.total_interviewed}</small>
                          </button>|
                <button className="btn text-lowercase" onClick={() => this.handleApplication(ele.job_id)} disabled={ele.shortlisted.includes('No')}>
                              <small >{ele.shortlisted}</small>
                          </button>
    
                      </td>
                      <td style={{lineHeight:"50px"}} >
                          <button className="btn btn-sm" style={{ backgroundColor: "#ffcf0d", borderRadius: "10px" }} onClick={() => this.handelReport(ele.job_id)} disabled={ele.shortlisted.includes('No')}>
                              <small>Download Report</small>
                          </button>
    
                      </td>
                  </tr>
          );
        });
   
        return (
            <div  className="app-table"
            style={{ overflow: "scroll", height: "800px", }}>

                <div className="table-responsive">
  <table className="table">
  <thead>
    <tr>
    <th scope="col"></th>
      <th scope="col">Role</th>
      <th scope="col">City</th>
      <th scope="col">Age</th>
      <th scope="col">Active</th>
      <th scope="col" className="text-center">Aplication</th>
    </tr>
  </thead>
  <tbody className="main-table">{file}</tbody>
  </table>
  
</div>
<p className="aligncenter">
        <img
          
          src={graphic}
          alt=""
          style={{ width: "40%", align: "center"}}
        />
        </p>
            </div>
        )
    }
}
export default JobsDashboard

import React ,{useEffect, useState}from "react";
import logo from "../dummy/backLogo.png";
import "./Navbar.css";
import NavItems from "./NavItems/NavItems";
import { withRouter } from "react-router-dom";
import "./NavItems/NavItems.css";
import HelpPopup from "../popup/HelpPopup";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import dateFormat from 'dateformat';

import { NotificationManager } from 'react-notifications';
import { clientDetails } from "../../Utils/auth";
import RefreshIcon from '@material-ui/icons/Refresh';
import { render } from "@testing-library/react";

const Navbar = (props) => {
    var [balance,setBalance] = useState(0);

    var [balance_validity,setValidity]=useState(0);
    

    var [balanceValidity,setBalanceValidity] = useState('');


    useEffect(() => {
        const username =  JSON.parse(localStorage.getItem("jwt")).client_id;
        clientDetails(username)
            .then((data) => {
                if (data){
                    console.log(data.data.client[0]);
                  
                    if (data.data.client[0]){
                        localStorage.setItem("balance",data.data.client[0].balance);

                        localStorage.setItem("balance_validity",data.data.client[0].balance_validity);
                        setBalance(data.data.client[0].balance);
                        setValidity(data.data.client[0].balance_validity);
                        if (data.data.client[0].balance <= 0)
                        NotificationManager.warning("Please do Recharge");


                       
                    }
                }
                
                
          });
  }, []);


    const handelRefresh = () => {
        NotificationManager.info("Refreshing .....")

    
        

    }
    console.log(balance)
    return (
       
        <div className="Navbar_Items">
           
            <ul className="Navbar_Items">
                <img
                    link="/"
                    src={logo}
                    alt=""
                    style={{ width: "60px", marginRight: "50px" }}
                />
                <NavItems link="/">Jobs</NavItems>
                <NavItems link="/postjob">Post Job</NavItems>



                <div className="dropdown show NavigationItem">
                    <a
                        className="dropdown-toggle NavigationItem"
                        href="/privacyPoliciy"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ color: "black", textDecorationStyle: "none" }}
                    >
                        User
        </a>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" rel="noopener noreferrer" href="/edituserinfo">
                            Edit Profile
          </a>
                        <a className="dropdown-item" rel="noopener noreferrer" href="/companyinfo">
                            Company Info
          </a>
                        <a className="dropdown-item" rel="noopener noreferrer" href="/manageteam">
                            Manage Team
          </a>

          {/*     <a className="dropdown-item" rel="noopener noreferrer" href="/kyc"> */}
          {/*             KYC */}
          {/* </a> */}



                    </div>
                </div>



                <div className="dropdown show NavigationItem">
                    <a
                        className="dropdown-toggle NavigationItem"
                        href="/privacyPoliciy"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ color: "black", textDecorationStyle: "none" }}
                    >
                        Payment
        </a>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" rel="noopener noreferrer" href="/makepayment">
                            Make Payment
          </a>
                        <a className="dropdown-item" rel="noopener noreferrer" href="/paymenthistory">
                            Payment History
          </a>
                        <a className="dropdown-item" rel="noopener noreferrer" href="/billplan">
                            Your Plan
          </a>

          {/*         <a className="dropdown-item" rel="noopener noreferrer" href="/purchasedetails"> */}
          {/*             Purchase Details */}
          {/* </a> */}
                  
              </div>
          </div>





                <NavItems link="/pricing">Pricing</NavItems>



                <div className="dropdown show NavigationItem">
                    <a
                        className="dropdown-toggle NavigationItem"
                        href="/privacyPoliciy"
                        role="button"
                        id="dropdownMenuLink"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{ color: "black", textDecorationStyle: "none" }}
                    >
                        Policy
        </a>

                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                        <a className="dropdown-item" rel="noopener noreferrer" href="https://sites.google.com/view/signo-privacy-policy">
                            Privacy Policy
          </a>
                        <a className="dropdown-item" rel="noopener noreferrer" href="https://sites.google.com/view/signo-terms-and-conditions/
">
                            Terms and Conditions
          </a>
                    </div>
                </div>









                <a  href="/" style={{  textDecoration:"none" }}  aria-haspopup="true"
                        aria-expanded="false">
                <li
                    className="NavigationItem"
                    style={{ color: "black", marginBottom: "15px", marginLeft: "10px" }}
                    onClick={() => {
                        if (typeof window !== "undefined") {
                            localStorage.removeItem("jwt");
                            localStorage.removeItem("profiles");
                            localStorage.removeItem("username");
                            localStorage.removeItem("jobs");
                        }
                        props.history.push("/");
                    }}
                >
                    Log Out
      </li>
         </a>

            </ul>
{/*  */}
{/*             <ul className="Navbar_Items"> */}
{/*                 <li > */}
{/*                     <table style={{textAlign: "center" }}> */}
{/*                         <tbody > */}
{/*  */}
{/*  */}
{/*  */}
{/*                             <tr> */}
{/*                                 <td><b>Live Candidate Registration</b></td> */}
{/*                                 <td> */}
{/*  */}
{/*  */}
{/*  */}
{/*                                 </td> */}
{/*                             </tr> */}
{/*                             <tr > */}
{/*                                 <td style={{color:"maroon"}}><b>233</b></td> */}
{/*                             </tr> */}
{/*  */}
{/*                         </tbody> */}
{/*                     </table> */}
{/*  */}
{/*                 </li> */}
{/*                 </ul> */}

            

            <NavItems link="/map">Map</NavItems>

            {/*Popup*/}
            <NavItems link="# ">
                <Popup
                    contentStyle={{ background: "none", border: "none" }}
                    trigger={<button type="button" className="check-button"
                    >
                        Help
              </button>}
                    modal

                    nested>

                    {close => (
                        <div>
                            
                                <a href="# " className="close pull-right" onClick={close} >
                                    &times;
</a>
                   
                            <div>
                                <HelpPopup />
                            </div>
                        </div>
                    )}


                </Popup>
            </NavItems>

            {/*Popup*/}

            <li >
                <table style={{ width: "120px" }}>
                    <tbody >



                        <tr>
                            <td>Credits: {balance}   </td>
                            <td>



                            </td>
                        </tr>
                        <tr >

                            <td colSpan="2">Validity:{dateFormat(balance_validity, "dS mmm, yy")}</td>

                        </tr>

                    </tbody>
                </table>

            </li>
            <button
                type="submit"
                className="submit btn btn-primary"
                style={{ margin: "0px", paddingTop: "5px", borderRadius: "100px", textAlign: "center" }}
            >
                <RefreshIcon onClick={handelRefresh} style={{ margin: "0px", paddingTop: "7px", borderRadius: "100px", textAlign: "center" }} />
            </button>
        </div>
      
    );
                    
};

export default withRouter(Navbar);

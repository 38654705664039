import React ,{useEffect}from "react";
import { Route, withRouter } from "react-router-dom";
import DummyPostJob from './TrialsInProject/PostJob'
import Navbar from "./Utils/Navbar/Navbar";
import Home from "./Pages/applicants/Home";
import SignIn from "./Pages/signin/SignIn";
import Register from "./Pages/register/Register";
import Forgot from "./Pages/forgot/Forgot";
import Interview from "./Pages/interview/Interview";
import { isAuthenticated } from "./Utils/auth";
import joblisting from './Pages/joblisting/JobListing';
import PostJob from "./Pages/postJob/PostJob";
import EditUserInfo from "./Pages/editUserInfo/EditUserInfo";
import Pricing from "./Pages/pricing/Pricing";
import CompanyInfo from "./Pages/companyInfo/CompanyInfo";
import {NotificationContainer} from 'react-notifications';
// import AlternateLandingPage from './TrialsInProject/AlternateLandingPage'
// import Contact from "./Pages/contact/Contact";
import ButtonModal from './TrialsInProject/ButtonModal'
import LandingPage2 from './TrialsInProject/LandingPage2'
import {isMobile} from 'react-device-detect';
// import SmsPopup from "./Utils/popup/SmsPopup";
import MakePayment from "./Pages/makePayment/MakePayment";
import PaymentHistory from "./Pages/payment/PaymentHistory";
import BillPlan from "./Pages/payment/BillPlan";
import PurchaseDetails from "./Pages/payment/PurchaseDetails";
import KYC from "./Pages/payment/KYC";
import ManageTeam from "./Pages/manageTeam/ManageTeam";

import Map from "./Pages/map/Map";

import FreshChat from 'react-freshchat'



const MainRouter = (props) => {


  useEffect(() => {
    const initialValue = document.body.style.zoom;

    // Change zoom level on mount
    document.body.style.zoom = "80%";
    
    return () => {
      // Restore default value
      document.body.style.zoom = initialValue;
  
    };
  }, []);

  if (isMobile) {
    window.location="https://play.google.com/store/apps/details?id=in.daas.signo"
  }
  return (
    <div>
      {isAuthenticated() ? (
        <>

          <Navbar></Navbar>
          <Route path="/applicants/:jobId" exact component={Home}></Route>
          <Route path="/interview/:jobId" exact component={Interview}></Route>
          <Route path="/postjob" exact component={DummyPostJob}></Route>
          <Route path="/postjob/:edit" exact component={PostJob}></Route>
          <Route path="/" exact component={joblisting}></Route>
          <Route path="/edituserinfo" exact component={EditUserInfo}></Route>
          <Route path="/companyinfo" exact component={CompanyInfo}></Route>
                  <Route path="/pricing" exact component={Pricing}></Route>
                  <Route path="/makepayment" exact component={MakePayment}></Route>
                  <Route path="/paymenthistory" exact component={PaymentHistory}></Route>
                  <Route path="/billplan" exact component={BillPlan}></Route>
                  <Route path="/purchasedetails" exact component={PurchaseDetails}></Route>
                  <Route path="/kyc" exact component={KYC}></Route>
                  <Route path="/manageteam" exact component={ManageTeam}></Route>
                  <Route path="/map" exact component={Map}></Route>

                  
        </>
      ) : (
      <>
        <Route path="/" exact component={LandingPage2}></Route>
        {/* <Route path="/" exact component={AlternateLandingPage}></Route> */}
        <Route path="/register" exact component={Register}></Route>
        <Route path="/forgot" exact component={Forgot}></Route>
        <Route path="/loginIn" exact component={SignIn}></Route>
        </>
        
      )}
      <NotificationContainer/>
      <FreshChat
        token="73a563c0-81f6-4717-b2ff-27d4869a3d34"
        host="https://wchat.in.freshchat.com"
       
        onInit={widget => {
          // Use `widget` instead of `window.fcWidget`
            widget.user.setProperties({
           
              token: "73a563c0-81f6-4717-b2ff-27d4869a3d34",
              host: "https://wchat.in.freshchat.com",

              first_name: localStorage.getItem("username"),
              email: localStorage.getItem("email"),
              phone: localStorage.getItem("phone_number"),
              // last_name: user.lastName,
              // phone: user.phoneNumber,
            })
         
        }}
      />
    </div>
    

  );
};

export default withRouter(MainRouter);

import React, { Component } from "react";
import { editUserProfile,getUserProfile } from "../../Utils/auth";
import "./EditUserInfo.css";
import { NotificationManager} from 'react-notifications';

class EditUserInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyName: "",
            email: "",
            firstName: "",
            lastName: "",
            address: "",
            city: "",
            postalCode: "",
            phone: "",
            success: false,
            error: "",
        };
    }

    changeHandler = (e) => {
        this.setState({ success: false });
        this.setState({ [e.target.name]: e.target.value });
    };

    submitHandler = (e) => {
        e.preventDefault();

        var client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const user = {
            company_name: this.state.companyName,
            email: this.state.email,
            first_name: this.state.firstName,
            last_name: this.state.lastName,
            
            address: this.state.address,
            city: this.state.city,
            postal_code: this.state.postalCode,
            phone: this.state.phone,
            client_id: client_id,
        };

        console.log(user);
        editUserProfile(user)
            .then((response) => {
                console.log(response);
                if (!response.status) {
                    this.setState({
                        success: true,
                        companyName: "",
                        email: "",
                        firstName: "",
                        lastName: "",
                        address: "",
                        city: "",
                        postalCode: "",
                        phone: "",
                        error: "",
                    });
                }
                else{
                NotificationManager.info("Saved Successfully")
                window.location ="/"
            }
            })
            .catch((err) => {
                console.log("ERROR ", err);
            });
    };

    componentWillMount(){
        var client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const user = {
      
            client_id: client_id,
        };

        console.log(user);
        getUserProfile(user)
            .then((response) => {
                console.log(response);
                if (response.status) {
                    this.setState({
                       
                        companyName: response.company_name,
                        email: response.email,
                        firstName: response.first_name,
                        lastName: response.last_name,
                        address: response.address,
                        city: response.city,
                        postalCode: response.postal_code,
                        phone: response.phone_number,
                       
                    });
                    if (response.email==="" || response.email===null){
                        NotificationManager.warning("Profile Incomplete")
                    }
                }
            })
            .catch((err) => {
                console.log("ERROR ", err);
            });
    }


    render() {
        const {
            companyName,
            email,
            firstName,
            lastName,
            address,
            city,
            postalCode,
            phone,
        } = this.state;

        return (
            <div className=" form-container container">
                <h1 className="heading">Edit Profile</h1>
                <br></br>
                    {this.state.success && (
                        <div class="card">
                            <div class="card-body">Profile Edited Successfully...</div>
                        </div>
                    )}

                    <form onSubmit={this.submitHandler}>

                        <div className="row">
                            <div className="col">
                                <div className="form-outline mt-4">
                                    <label htmlFor="Company" className="tag">Company name</label>
                                {/* <br /> */}
                                <input type="text" name="companyName" defaultValue={companyName} onChange={this.changeHandler} className="form-control" id="Company" placeholder="Enter company name" />
                                </div>
                            </div>
                            
                        </div>


                        <div className="row">
                            <div className="col">
                                <div className="form-outline mt-4">
                                    <label htmlFor="First Name" className="tag">First Name</label>
                                    
                                <input type="text" name="firstName" defaultValue={firstName} onChange={this.changeHandler} className="form-control" id="First-name" placeholder="Enter first name" />
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-outline mt-4">
                                    <label htmlFor="Last Name" className="tag">Last Name</label>
                                    
                                <input type="text" name="lastName" defaultValue={lastName} onChange={this.changeHandler} className="form-control" id="First-name" placeholder="Enter last name"/>
                                </div>
                            </div>
                        </div>

                        {/* <div className="form-group">
                            <label htmlFor="Address" className="tag">Address</label>
                            <br></br>
                        <input type="text" name="address" defaultValue={address} onChange={this.changeHandler} className="form-control" id="Address" placeholder="Enter address"/>
                        </div> */}
                        <div className="row">
                             <div className="col">
                              <div className="form-outline mt-4">
                                    <label htmlFor="Email" className="tag">Email </label>
                                    {/* <br></br> */}
                                <input type="email" name="email" defaultValue={email} onChange={this.changeHandler} className="form-control" id="Email" aria-describedby="emailHelp" placeholder="Enter email" />
                                    <small id="emailHelp" className="form-text text-muted"></small>
                                </div>
                             </div>
                             <div className="col">
 <div className="form-outline mt-4">
                                    <label htmlFor="Phone Number" className="tag">Phone Number</label>
                                    <br></br>
                                <input type="number" name="phone" defaultValue={phone} onChange={this.changeHandler} className="form-control" id="Phone-no" placeholder="Enter phone no."/>
                                </div>
                             </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <div className="form-outline mt-4">
                                    <label htmlFor="City" className="tag">City</label>
                                  
                                <input type="text" name="city" defaultValue={city} onChange={this.changeHandler} className="form-control" id="City" placeholder="Enter city"/>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-outline mt-4">
                                    <label htmlFor="Postal Code" className="tag">Postal Code</label>
                                   
                                <input type="text" name="postalCode" defaultValue={postalCode} onChange={this.changeHandler} className="form-control" id="Postal-code" placeholder="Enter postal code"/>
                                </div>
                            </div>
                            
                        </div>

                        <div className="row">
                            <div className="col">
                            <div className="form-outline mt-4">
                            <label htmlFor="Address" className="tag">Address</label>
                            <br></br>
                        {/* <input type="text" name="address" defaultValue={address} onChange={this.changeHandler} className="form-control" id="Address" placeholder="Enter address"/> */}
                      <textarea
                        name="address"
                         defaultValue={address} 
                         onChange={this.changeHandler}
                          className="form-control" 
                          id="Address" 
                          placeholder="Enter address"
                          rows="7"
                          cols="5"
                      >

                      </textarea>
                        </div>
                                {/* <div className="form-group">
                                    <label htmlFor="Phone Number" className="tag">Phone Number</label>
                                    <br></br>
                                <input type="number" name="phone" defaultValue={phone} onChange={this.changeHandler} className="form-control" id="Phone-no" placeholder="Enter phone no."/>
                                </div> */}
                            </div>
                            {/* <div className="col">

                            </div>
                            <div className="col">

                            </div> */}
                        </div>

                        <div className="row text-center mt-4">
                            {/* <div className="col">

                            </div>
                            <div className="col">

                            </div> */}
                            <div className="col">
                            <div className="mx-auto">
                           <button
                                type="submit"
                                style={{ width: "250px", borderRadius: "100px" }}
                                className="submit btn btn-primary p-3"
                            >
                                Submit
                            </button>
                            </div>
                        </div>
                        </div>




                    </form>
                </div>
    );

	}
}

export default EditUserInfo;



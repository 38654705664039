import React, { Component } from "react";
import avatar from "../../../Utils/dummy/avatar.png";
import "./Profile.css";
import { NotificationManager } from 'react-notifications';
// import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import { getApplicationDetails, shortList , connectSpocToDriver,sendWhatsappMsg, unlockDriver,smsshorlistedcandidate} from "../../../Utils/auth";


import { SmsPopup } from "../../../Utils/popup/SmsPopup";



import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import DisplayNoPopup from "../../../Utils/popup/DisplayNoPopup";
import ShortListPopup from "../../../Utils/popup/ShortListPopup";
import CallPopup from "../../../Utils/popup/CallPopup";



class Profile extends Component {
   

    state = {
        name: "",
        id: "",
        location: "",
        phoneNum: "",
        aadhardCard: "",
        resume: "",
        videoCV: "",
        bank: "",
        dl: "",
        dl_number: "",
        aadhar_number: "",
        pan_number: "",
        dob: "",
        pan: "",
        is_kyc_done: "",
        othersDoc: "",
        has3verifiedEmployee: "",
        drugAlchol: "",
        indiangoliosCargo: 0,
        radioWarrios: 0,
        swiftEnterpris: 0,
        cdlTraining: 0,
        coronialDrivingCompany: 0,
        cous: 0,
        criminal: 0,
        viewerIsOpen: false,
        display: "none",
        currImg: "https://admin.signo.in/media/newdoc_0ONfWnv.jpg",
    };

    handelSMS = () => {
        NotificationManager.info("Request sent");
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const sms_body = document.getElementById("toCount").value;
        const params2 = "job_id: \""+this.props.jobId+"\",client_id:\""+client_id+"\",sms_body:\""+sms_body+"\"";
        smsshorlistedcandidate(params2)
        .then((data) => {
            console.log(data);
          });
    }
    handelShortList = () => {
        NotificationManager.info("Shortlisted");
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const params2 = "job_id: \""+this.props.jobId+"\",client_id:\""+client_id+"\",selected:\""+this.state.phoneNum+"\"";
        shortList(params2)
        .then((data) => {
            console.log(data);
          });
     
    }

    handelUnlock = () => {
        NotificationManager.info("Porfile moved to MY SHORTLISTED");
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const params2 = "job_id: \""+this.props.jobId.replace("X","")+"\",client_id:\""+client_id+"\",selected:\""+this.state.id+"\"";
        unlockDriver(params2)
        .then((data) => {
            console.log(data);
          });
     
    }

    handelSpocCall= (number) => {
        NotificationManager.info("Call Scheduled");
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const jobs = { client_id: client_id,spoc: number, driver: this.state.phoneNum};
        connectSpocToDriver(jobs)
        .then((data) => {
            console.log(data);
          });
     
    }

        handleWhatsapp= () => {
        NotificationManager.info("Whatsapp MSG Sent!");
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const jobs = { client_id: client_id, driver: this.state.phoneNum, job_id: this.props.jobId};
        sendWhatsappMsg(jobs)
        .then((data) => {
            console.log(data);
          });
     
    }




    componentDidUpdate(prevProps) {
        if (prevProps.userId !== this.props.userId) {
            this.setState({ display: "block" })
            // console.log(this.props.userId);
            const jobs = { id: this.props.userId,jobId: this.props.jobId};
            getApplicationDetails(jobs)
                .then((data) => {
                    console.log(data);
                    this.setState({
                        id: data.id,
                        name: data.name,
                        phoneNum: data.phone_number,
                        location: data.address,
                        aadhardCard: data.aadhar_card,
                        resume: data.resume,
                        bank: data.bank,
                        dl: data.driver_license,
                        pan: data.pan,
                        videoCV: data.videoCV,
                        is_verified: data.is_verified,
                        is_kyc_done: data.is_kyc_done,
                        aadhar_number: data.aadhar_number,
                        dl_number: data.dl_number,
                        pan_number: data.pan_number,
                    });
                })
                .catch((err) => {
                    console.log("Error in getting all the users", err);
                });
          
        }

       
    }

    render() {
       
        return (
            <div className="profile-card" style={{ display: this.state.display }}>
                <div
                    style={{
                        textAlign: "center",
                        width: "90%",
                        height: "100px",
                        margin: "30px 0px",
                    }}
                >
                    <img
                        src={avatar}
                        style={{ width: "100px", marginTop: "-10px" }}
                        alt="..."
                        className="rounded-circle"
                    />

                    <h5>
                        <b style={{ color: "#231B1B" }}>{this.state.name}</b>
                    </h5>
                    {this.state.is_verified ? (
                        <button type="button" className="profile-button">
                            Verified
                        </button>
                    ) : (
                            <button
                                type="button"
                                className="profile-button"
                                style={{ backgroundColor: '#FECE0B', color: '#231B1B' }}
                            >
                                Non-Verified
                            </button>
                        )}
                </div>
                <br></br>
                <br></br>
                <table className="table table-borderles">
                    <thead>
                        <tr style={{ textAlign: "center" }}>
                            <td colSpan="2">
                                <Popup
                                    contentStyle={{ background: "none", border: "none" }}
                                    trigger={<button type="button" className="btn btn-dark"
                                    >

                                        <i className="fa fa-comments" aria-hidden="true" ></i> SMS
              </button>} modal
                                    nested>

                                    {close => (
                                        <div>
                                            <div>
                                                <a href="# " className="close pull-right" onClick={close} >
                                                    &times;
</a>
                                            </div>
                                            <div>
                                                <SmsPopup
                                                    handelSMS={this.handelSMS}/>
                                            </div>
                                        </div>
                                    )}

                                </Popup>









                                {/*<button type="button" className="btn btn-dark" disabled>
                                    <i className="fa fa-sort" aria-hidden="true"></i> Shortlist
              </button>*/}

                                <Popup
                                    contentStyle={{ background: "none", border: "none" }}
                                    trigger={<button type="button" className="btn btn-dark"
                                    >

                                        <i class="fa fa-list"></i> Shortlist
                                    </button>} modal
                                    nested>
                                    {close => (
                                        <div>
                                            <div style={{ paddingRight: "300px" }}>
                                                <a href="# " className="close pull-right" onClick={close} >
                                                    &times;
</a>
                                            </div>
                                            <div>
                                                <ShortListPopup 
                                                    handelShortList={this.handelShortList}

                                                />
                                            </div>
                                        </div>
                                    )}

                                </Popup>





                                <button type="button" className="btn btn-dark" onClick={this.handleWhatsapp}>
                                    <i className="fa fa-share-alt-square" aria-hidden="true"></i> Whatsapp
              </button>
                            </td>
                        </tr>
                    </thead>
                </table>
                <table className="table">
                    <tbody>
                        <tr>
                            <td style={{ textAlign: "center" }}>
                                <button type="button" className="btn btn-dark" onClick={() => {
                                    window.open(
                                        `https://vid.signo.in/join/${this.state.phoneNum}`,
                                        "_blank" // <- This is what makes it open in a new window.
                                    );
                                }}>
                                    <i className="fa fa-video-camera" aria-hidden="true"></i> Video Call
              </button>
                            </td>
                            <td style={{ textAlign: "center" }}>
                                {/*<button type="button" className="btn btn-dark" disabled >
                                    <i className="fa fa-phone" aria-hidden="true" ></i> Call
              </button>*/}

                                {/*Call*/}
                                <Popup
                                    contentStyle={{ background: "none", border: "none" }}
                                    trigger={<button type="button" className="btn btn-dark"
                                    >

                                        <i class="fa fa-phone-square"></i> Call
                                    </button>} modal
                                    nested>
                                    {close => (
                                        <div>
                                            <div style={{ paddingRight: "300px" }}>
                                                <a href="# " className="close pull-right" onClick={close} >
                                                    &times;
</a>
                                            </div>
                                            <div>
   
                                                <CallPopup
                                                    companyPhoneNo={"9999999999"}
                                                    handelSpocCall={this.handelSpocCall}
                                                />
    
                                            </div>
                                        </div>
                                    )}

                                </Popup>
                                {/*Call*/}
                            </td>
                        </tr>
                        <tr >
                            <td colSpan="2">
                                <b>Personal Infromation</b>
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "#292b2c" }}>Location</td>
                            <td style={{ textAlign: "center" }}>{this.state.location}</td>
                        </tr>
                        <tr>

                            <td style={{ color: "#292b2c" }}>Phone Number</td>


                            <td style={{ textAlign: "center" }}>

                                <Popup
                                    contentStyle={{ background: "none", border: "none" }}
                                    trigger={<button type="button" className="btn btn-dark"
                                    >

                                        {this.state.phoneNum}
                                    </button>} modal
                                    nested>
                                    {close => (
                                        <div>
                                            <div style={{ paddingRight: "300px" }}>
                                                <a href="# " className="close pull-right" onClick={close} >
                                                    &times;
</a>
                                            </div>
                                            <div>
                                                <DisplayNoPopup  handelUnlock={this.handelUnlock}/>
                                            </div>
                                        </div>
                                    )}

                                </Popup>


                            </td>

                        </tr>

                        {/* <tr>
              <td colSpan="2">
                <b>Application Review Highlights</b>
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ color: "#5cb85c" }}>
                <i className="fa fa-check"></i> Has 3+ years of verified
                employement
              </td>
            </tr>
            <tr>
              <td colSpan="2" style={{ color: "#5cb85c" }}>
                <i className="fa fa-check"></i> No drug or alcohol voilations
              </td>
            </tr> */}
                        <tr>
                            <td colSpan="2">
                                <b>Verifications</b>
                            </td>
                        </tr>
                        <tr>
                            <td>Resume</td>
                            <td style={{ color: "#5cb85c", textAlign: "center" }}>
                                {this.state.resume !== "None" ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(
                                                `https://admin.signo.in/media/${this.state.resume}`,
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                        className="btn btn-info"
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-eye"></i>
                                        </button>
                                    )}
                            </td>
                        </tr>
                        <tr>
                            <td>Video CV</td>
                            <td style={{ color: "#5cb85c", textAlign: "center" }}>
                                {this.state.videoCV !== "" ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(
                                                `https://admin.signo.in/media/${this.state.videoCV}`,
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                        className="btn btn-info"
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-eye"></i>
                                        </button>
                                    )}
                            </td>
                        </tr>
                        <tr>
                            <td>Aadhar Card</td>
                            <td style={{ color: "#5cb85c", textAlign: "center" }}>
                                {this.state.aadhardCard && this.state.aadhardCard !== "None" ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(
                                                `https://admin.signo.in/media/${this.state.aadhardCard}`,
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                        className="btn btn-info"
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-eye"></i><br />
                                            {this.state.aadhar_number}
                                        </button>

                                    )}

                            </td>
                        </tr>
                        <tr>
                            <td>DL Card</td>
                            <td style={{ color: "#5cb85c", textAlign: "center" }}>
                                {this.state.dl !== "None" ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(
                                                `https://admin.signo.in/media/${this.state.dl}`,
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                        className="btn btn-info"
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-eye"></i><br />
                                            {this.state.dl_number}
                                        </button>
                                    )}
                            </td>
                        </tr>
                        <tr>
                            <td>PAN Card</td>
                            <td style={{ color: "#0275d8", textAlign: "center" }}>
                                {this.state.pan !== "None" ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(
                                                `https://admin.signo.in/media/${this.state.pan}`,
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                        className="btn btn-info"
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-eye"></i><br />
                                            {this.state.pan}
                                        </button>
                                    )}
                            </td>
                        </tr>
                        <tr>
                            <td>Bank Details</td>
                            <td style={{ color: "#5cb85c", textAlign: "center" }}>
                                {this.state.bank !== "None" ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(
                                                `https://admin.signo.in/media/${this.state.bank}`,
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                        className="btn btn-info"
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-eye"></i>
                                        </button>
                                    )}
                            </td>
                        </tr>
                        <tr>
                            <td>Others Documents</td>

                            <td style={{ color: "#5cb85c", textAlign: "center" }}>
                                {this.state.othersDoc !== "" ? (
                                    <button
                                        type="button"
                                        onClick={() => {
                                            window.open(
                                                `https://admin.signo.in/media/${this.state.bank}`,
                                                "_blank" // <- This is what makes it open in a new window.
                                            );
                                        }}
                                        className="btn btn-info"
                                    >
                                        <i className="fa fa-eye"></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-eye"></i>
                                        </button>
                                    )}
                            </td>
                        </tr>
                        <tr>
                            <td style={{ color: "#292b2c" }}>KYC</td>
                            <td style={{ textAlign: "center" }}>
                                {this.state.is_kyc_done ? (
                                    <button type="button" className="btn btn-info" disabled>
                                        <i className="fa fa-check" style={{ color: "green" }}></i>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <i className="fa fa-times" style={{ color: "red" }}></i>
                                        </button>
                                    )}
                            </td>
                        </tr>
                        {/* <tr> */}
                        {/*   <td style={{ color: "#292b2c" }}>App Installed</td> */}
                        {/*   <td style={{ textAlign: "center" }}> */}
                        {/*     {this.state.is_app_installed ? ( */}
                        {/*       <button type="button" className="btn btn-info" disabled> */}
                        {/*         <i className="fa fa-check" style={{ color: "green" }}></i> */}
                        {/*       </button> */}
                        {/*     ) : ( */}
                        {/*       <button type="button" className="btn btn-info" disabled> */}
                        {/*         <i className="fa fa-times" style={{ color: "red" }}></i> */}
                        {/*       </button> */}
                        {/*     )} */}
                        {/*   </td> */}
                        {/* </tr> */}
                        <tr>
                            <td style={{ color: "#292b2c" }}>Rating</td>
                            <td style={{ textAlign: "center" }}>
                                {this.state.rating ? (
                                    <button type="button" className="btn btn-info" disabled>
                                        <span style={{ color: "green" }}>{this.state.rating}</span>
                                    </button>
                                ) : (
                                        <button type="button" className="btn btn-info" disabled>
                                            <span style={{ color: "green" }}>4</span>
                                        </button>
                                    )}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Profile;

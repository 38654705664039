
import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';

export class DisplayNoPopup extends Component {



    handelContact = (job_id) => {




        //   addToast("content", {
        //    appearance: 'info',
        //    autoDismiss: true,
        //  })
        // 
        NotificationManager.info("Request Sent");
    }

    render() {
        return (
            <div className="popup"  >

                {/* <button type="button" class="btn btn-primary" data-target="#contactpopup" data-toggle="modal">SMS</button>*/}

                <div className="modal" id="contactpopup" tabIndex="-1"  >
                    <div className="modal-dialog "  >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" style={{ color: "black" }}>Unlock Contact Number</h5>
                                {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>*/}
                            </div>



                            <div className="popup-row modal-body">

                                <div className=" row">

                                    <div className="contact-popup-column">
                                        <div className="card" >
                                            <div className="card-header">
                                                <p>To unlock the phone number of the candidate press unlock button</p>
                                                <button
                                                    type="submit"
                                                    className="submit btn btn-primary pull-right"
                                                    style={{ width: "100px", borderRadius: "100px", textAlign: "centre" }}
                                                    onClick= { () =>
                                                            this.props.handelUnlock()
                                                          }
                                                //onClick={() => this.handelContact("as")} 
                                                >Unlock</button>
                                            </div>

                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>






        </div>
        );
    }
}
export default DisplayNoPopup;


























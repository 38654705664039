import React from "react";
import "./Pricing.scoped.css";
import logo from '../../Utils/dummy/logo.jpg';
// import App from "../../Utils/razorpay/payment";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import HelpPopup from "../../Utils/popup/HelpPopup";

function loadScript(src) {
	return new Promise((resolve) => {
		const script = document.createElement('script')
		script.src = src
		script.onload = () => {
			resolve(true)
		}
		script.onerror = () => {
			resolve(false)
		}
		document.body.appendChild(script)
	})
}


async function fundedRazorpay() {
	const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

	if (!res) {
		alert('Razorpay SDK failed to load. Are you online?')
		return
	}
	const options = {
		key:  'rzp_live_w1xIJqGefVChFR',
		currency: 'INR',
		amount: '2950000',
		order_id: '',
		name: 'SIGNO',
		description: '',
		image: {logo},
		handler: function (response) {
			if (response.razorpay_payment_id)
			{
			alert("Successfully Payment Done");
			}
			else
			{
			alert ("Payment Failed");
			}
			alert(response.razorpay_order_id)
			alert(response.razorpay_signature)
		},
		prefill: {
			name:'ashutosh',
			email: 'sdfdsjfh2@ndsfdf.com',
			phone_number: '9899999999'
		}
	}
	const paymentObject = new window.Razorpay(options)
	paymentObject.open()
}


function Pricing() {
	return (
		<div class="pricing-page">
			<section class="pricing-content content">
				<div class="pricing-container container">
					<div class="pricing-row row-fluid clearfix">

						<div class="pricing-wrapper comparison-table clearfix style-3">
							<div class="col-md-3 pricing-col list-feature">
								<div class="pricing-card">
									<div class="pricing-header">
										<h5>Choose Your Plan</h5>
										<p>Compare Package Feature</p>
									</div>
									<div class="pricing-feature">
										<li>
											<p>Applicants</p>
										</li>
										<li>
											<p>Location wise Active Jobs</p>
										</li>
										<li>
											<p>Job Boost</p>
										</li>
										<li>
											<p>Supervisor Calling</p>
										</li>
										<li>
											<p>Multiple user access from admin panel</p>
										</li>
										<li>
											<p>Share Offer Letter from Direct Portal</p>
										</li>
										<li>
											<p>Mobile Number Tracking - SIM Based location</p>
										</li>
										<li>
											<p>Send SMS to candidate on Whatsapp/ Normal message from Portal</p>
										</li>
										<li>
											<p>Dedicated Support ( 10:00 AM to 6:00 PM)</p>
										</li >
										<li>
											<p>Extra Candidate  (+30%)</p>
										</li>
										<li>
											<p>Excel download</p>
										</li>
										<li>
											<p>Broadcast your job on Facebook, Twitter, Linkedin </p>
										</li>
									</div>
								</div>
							</div>
							<div class="col-md-3 pricing-col person">
								<div class="pricing-card">
									<div class="pricing-header">
										<h5>Trial</h5>
										<div class="price-box">
											<div class="price">FREE
						  				{/* 		<div class="currency">₹</div> */}
												{/* <div class="plan">/ Mo</div> */}
											</div>
											<br/>
											
						 						 
											
										</div>
										* For 14 Days
									</div>
									<div class="pricing-feature">

										<li >
											<p>
												<span>LIMITED</span>
											</p>
										</li>
										<li >
											<p>
												<span>upto 3 location</span>
											</p>
										</li>
										<li>
											<p>
												<span>5/Month</span>
											</p>
										</li>
										<li>
											<p>
												<span>5</span>
											</p>
										</li>
										<li>
											<p>
												<span>3</span>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-times unavailable"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-times unavailable"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-times unavailable"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-times unavailable"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
									</div>
									<div class="pricing-footer">
									<a href="/postjob" class="btn btn-act rounded btn-line">
											<span>Post Job</span>
											<i class="fa fa-arrow-right"></i>
										</a>
									</div>
								</div>
							</div>
							<div class="col-md-3 pricing-col current unlim">
								<div class="pricing-card">
									<div class="pricing-header">
										<h5>SILVER</h5>

										<div class="price-box">
											<div class="price">25,000
						  						<div class="currency">₹</div>
												<div class="plan">/ Mo</div>
											</div>
											<br/>
											
						 						 
											
										</div>
										₹ 2,40,000 / Yr (20% Off)
									</div>
									
									<div class="pricing-feature">
										<li >
											<p>
												<span>UNLIMITED</span>
											</p>
										</li>
										<li>
											<p>
												<span>upto 5 location</span>
											</p>
										</li>
										<li>
											<p>
												<span>10/Month</span>
											</p>
										</li>
										<li>
											<p>
												<span>10</span>
											</p>
										</li>
										<li>
											<p>
												<span>10</span>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-times unavailable"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
									</div>
									<div class="pricing-footer">
										<br/>
									<button type="button" class="btn btn-act rounded btn-line" outline onClick={fundedRazorpay}>
									<span>Order Now </span>
									<i class="fa fa-arrow-right"></i>
								    </button>
									</div>
								</div>
							</div>
							<div class="col-md-3 pricing-col business">
								<div class="pricing-card">
									<div class="pricing-header">
										<h5>GOLD</h5>
										{/* <button class="ribbon"> */}
										{/* 	<i class="fa fa-star"></i> */}
										{/* 	<span>Feature</span> */}
										{/* </button> */}
										<div class="price-box">
											<div class="price">Custom
						  {/* <div class="currency">$</div> */}
								{/* 				<div class="plan">/ Yr</div> */}
											</div>
										</div>
									</div>
									<div class="pricing-feature">
										<li >
											<p>
												<span>UNLIMITED</span>
											</p>
										</li>
										<li>
											<p>
												<span>PAN India</span>
											</p>
										</li>
										<li>
											<p>
												<span>Unlimited</span>
											</p>
										</li>
										<li>
											<p>
												<span>Unlimited</span>
											</p>
										</li>
										<li>
											<p>
												<span>Unlimited</span>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
										<li>
											<p>
												<i class="fa fa-check"></i>
											</p>
										</li>
									</div>
									<div class="pricing-footer">

										<Popup
											contentStyle={{ background: "none", border: "none" }}
											trigger={<a href="tel:+917380713485" class="btn btn-act rounded btn-line">
												<span>Order Now</span>
												<i class="fa fa-arrow-right"></i>
											</a>} modal
											nested>
											{close => (
												<div>
													<div style={{ paddingRight: "300px" }}>
														<a href="# " className="close pull-right" onClick={close} >
															&times;
</a>
													</div>
													<div>
														<HelpPopup />
													</div>
												</div>
											)}

										</Popup>
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</section>

		</div>


	);
}

export default Pricing;
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { login, authenticate } from "../../Utils/auth";
import logo from "../../Utils/dummy/backLogo.png";
import "./SignIn.css";

class Signin extends Component {
  state = {
    username: "",
    password: "",
    error: "",
    open: false,
  };

  handleChange = (name) => (event) => {
    this.setState({ error: "" });
    this.setState({ [name]: event.target.value });
  };

  clickSubmit = (event) => {
    event.preventDefault();
    const { username, password } = this.state;
    const user = { username, password };

    login(user).then((data) => {
      if (data.status !== "success") {
        this.setState({ error: data.status });
      } else {
        authenticate(data, () => {
          this.setState({
            error: "",
            userName: "",
            password: "",
            open: true,
          });
          if (typeof window != undefined) {
            localStorage.setItem(
              "username",
              JSON.stringify(this.state.username)
            );
            localStorage.setItem(
              "name",
             data.name
            );
            localStorage.setItem(
              "phone_number",
              data.phone_number
            );
            localStorage.setItem(
              "email",
              data.email
            );
          }
        });
      }
    });
  };

  componentDidMount() {
    document.title = "Signo Client Login";
  }

  render() {
    return (
      <div>
        
        <div className="my-login-page">
          <div className="h-100">
                    <div className="singin-cont container h-100">
                        <div className="singin-row  row justify-content-md-center h-100">
                <div className="card-wrapper">
                  <div
                    className="brand"
                    style={{ boxShadow: "0 4px 8px rgba(0,0,0,.05)" }}
                  >
                    <img src={logo} alt="logo" style ={{"opacity":1}}></img>
                  </div>
                  <div className="card fat">
                    <div className="card-body">
                      <h4
                        className="card-title"
                        style={{ textAlign: "center" }}
                      >
                        Login
                      </h4>
                      <div
                        className="alert alert-danger"
                        style={{ display: this.state.error ? "" : "none" }}
                      >
                        <b style={{ textTransform: "capitalize" }}>
                          {this.state.error}
                        </b>
                      </div>
                      {this.state.open && (
                        <div
                          className="alert alert-info"
                          style={{ display: this.state.open ? "" : "none" }}
                        >
                          Successfully Login.
                          <Redirect to="/"></Redirect>
                        </div>
                      )}

                      <form className="my-login-validation">
                        <div className="form-group">
                          <label htmlFor="email">User Name/Phone Number</label>
                          <input
                            id="username"
                            type="text"
                            className="form-control"
                            name="user name"
                            required
                            placeholder="9876543210"
                            onChange={this.handleChange("username")}
                            
                          ></input>
                        </div>

                        <div className="form-group">
                          
                            <a href="/forgot" className="float-right">
                              Forgot Password?
                            </a>
                         
                          <label htmlFor="password">Password</label>
                          <input
                            id="password"
                            type="password"
                            className="form-control"
                            name="password"
                            required
                            data-eye
                            password="123456"
                            onChange={this.handleChange("password")}
                            value={this.state.password}
                          ></input>
                        </div>

                        <div className="form-group">
                          <div className="custom-checkbox custom-control">
                            <input
                              type="checkbox"
                              name="remember"
                              id="remember"
                              className="custom-control-input"
                            />
                            <label
                              htmlFor="remember"
                              className="custom-control-label"
                            >
                              Remember Me
                            </label>
                          </div>
                        </div>

                        <div className="form-group m-0">
                          <button
                            type="submit"
                            className="form-button button-login"
                            onClick={this.clickSubmit}
                          >
                            Login
                          </button>
                        </div>
                        <div
                          className="mt-4 text-center"
                          style={{ marginTop: "20px" }}
                        >
                                                Don't have an account?
                          <a href="/register" className="option"> Create One</a>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div className="footer" style={{ marginTop: "20px",color:"black" }}>
                    <b>Copyright &copy; 2020 &mdash; Signo</b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Signin;

import React, { Component } from "react";

import ReactPaginate from "react-paginate";
import { getAllUser,excelSheet ,shortList} from "../../../Utils/auth";
import { NotificationManager} from 'react-notifications';
import avatar from "../../../Utils/dummy/avatar.png";
import "./Applicants.css";

import { SmsPopup } from "../../../Utils/popup/SmsPopup";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import ShortListPopup from "../../../Utils/popup/ShortListPopup";


class Applicants extends Component {
  state = {
    job_id: "JOB010063",
    users: [],
    searchUsers: [],
    offset: 0,
    data: [],
    perPage: 20,
    currentPage: 0,
    filter: "none",
    allChecked: false,
    showInterested: true,
  };

  componentDidUpdate(prevProps) {

    if((this.props.filter1 !== prevProps.filter1) || (this.props.filter2 !== prevProps.filter2) || (this.props.filter3 !== prevProps.filter3)){
      this.filterData();
    }

    if (prevProps.searchQuery !== this.props.searchQuery) {
      var sQ = this.props.searchQuery.toLowerCase().trim();

      var updateUsers = [];
      this.state.users.forEach((element) => {
        if (
          element.name.toLowerCase().indexOf(sQ) !== -1 ||
          element.address.toLowerCase().indexOf(sQ) !== -1 ||
          element.phone_number.indexOf(sQ) !== -1
        ) {
          updateUsers.push(element);
        }
      });
      this.setState({ searchUsers: updateUsers });
    }
    if(prevProps.jobId !== this.props.jobId){
      this.setState({job_id:this.props.jobId});

      this.callAllApplicants(this.props.jobId);
      
    }

    
  }

  callAllApplicants = (jobId) =>{
    const username = JSON.parse(localStorage.getItem("username"));

    if(String(jobId).includes("X")){
      NotificationManager.info("Showing Only Max 100 Profile");
      this.setState({showInterested :false});
    }
    const jobs = { job_id: jobId, username: username };
    getAllUser(jobs)
      .then((data) => {
        console.log(data);
        if (data.message === 'job_id invalid') {
          this.setState({ error: data.error });
          this.props.parentCallback(0);
          return;
        }
        localStorage.setItem("profiles", JSON.stringify(data.data));
        localStorage.setItem("title", (data.title));
        this.props.parentCallback(data.data.length,data.title);
        this.receivedData(this.state.perPage);
      })
      .catch((err) => {
        console.log("Error in getting all the users", err);
      });
  }
  receivedData(valuePerPage) {
    const data = JSON.parse(localStorage.getItem("profiles"));
    const o_data = JSON.parse(localStorage.getItem("profiles"));
    const pageCount=Math.ceil(data.length / valuePerPage);
    const setData = data.splice(this.state.offset * valuePerPage, valuePerPage);
    console.log("DATA:", setData);
  
    this.setState({
      pageCount: pageCount,
      searchUsers: setData,
      users: o_data,
    });
    if (localStorage.getItem("profiles")) {
      const applicants = JSON.parse(localStorage.getItem("profiles")).length;
      this.setState({ totalApplications: applicants });
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState(
      {
        offset: selectedPage,
      },
      () => {
        this.receivedData(this.state.perPage);
      }
    );
  };

  filterData = () => {
    const {filter1,filter2,filter3} = this.props;
    console.log(filter1, filter2, filter3);
    const data = JSON.parse(localStorage.getItem("profiles"));
    var updateUsers = [];
    data.forEach((element) => {
      // if (element.rating >= filter1) {
      //   if (!filter2 && element.is_kyc_done === filter2) {
      //     if (filter3 && element.application_age <= parseInt(filter3))
      //       updateUsers.push(element);
      //     else if (!filter3) updateUsers.push(element);
      //   } else if (!filter2) {
      //     if (filter3 && element.application_age <= parseInt(filter3))
      //       updateUsers.push(element);
      //     else if (!filter3) updateUsers.push(element);
      //   }
      // }
      if (filter2!==null && element.is_kyc_done===filter2){
        updateUsers.push(element);
      }
      else if (filter2==null){
        updateUsers.push(element);
      }

    });

    console.log("FILTER", updateUsers);
    this.setState({ searchUsers: updateUsers });
  };

  handlePerPage = (value) => {
    console.log(value);
    this.setState({ perPage: value });
    this.receivedData(value);
  };

  transfer = (id) => {
    console.log("This is my info", id);
  };

  handleChange = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
   console.log(itemName);
    this.setState(prevState => {
      let { searchUsers, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        searchUsers = searchUsers.map(item => ({ ...item, isChecked: checked }));
      } else {
        searchUsers = searchUsers.map(item =>
          String(item.id) === itemName ? { ...item, isChecked: checked } : item
          
        );
        allChecked = searchUsers.every(item => item.isChecked);
      }

     
      return { searchUsers, allChecked };
    });
  };

    handelSMS = () => {

        


    NotificationManager.info("Request Sent");
  }
  handelShortList =() =>{
    const client_id =  JSON.parse(localStorage.getItem("jwt")).client_id;
    let searchUsers = this.state.searchUsers;

    searchUsers =searchUsers.filter(item => item.isChecked)
    searchUsers = searchUsers.map(e => e.phone_number).join(",")


    NotificationManager.info("Shortlisted");
  
    const params2 = "job_id: \""+this.state.job_id+"\",client_id:\""+client_id+"\",selected:\""+searchUsers+"\"";
    shortList(params2)
    .then((data) => {
        console.log(data);
      });
  }

  handleExcelSheet = () => {
    
    const client_id =  JSON.parse(localStorage.getItem("jwt")).client_id;
    const username = JSON.parse(localStorage.getItem("username"));

   
    let searchUsers = this.state.searchUsers;

    searchUsers =searchUsers.filter(item => item.isChecked)
    searchUsers = searchUsers.map(e => e.id).join(",")

    const params = {
      client_id:client_id,
      job_id:this.state.job_id,
      username:username,
      selected: searchUsers,

    };
    excelSheet(params)
      .then((url)=>{
        const endPoint = "https://admin.signo.in/media/" + url.status + ".xlsx";
        console.log("EXCEL SHEET:",endPoint);
        window.open(endPoint);
      })
      .catch(err=>{
        console.log("ERROR",err);
    })
  }


  render() {
    var file = this.state.searchUsers.map((ele, i) => {
      return (
        <tr key={i} onClick={this.props.userId.bind(this, ele.id)}>
          <td className="ele-name">
            <input
              type="checkbox"
              aria-label="Checkbox for following text input"
              onChange={this.handleChange}
              name={ele.id}
              value={ele.id }
              checked={ele.isChecked }
            />
            <img src={avatar} alt="..." style={{ width: "50px" }} />
            {ele.name}
            
          </td>
          <td className="ele-type" style={{ textAlign: "center" }}>
            {ele.is_HMV ? <button>HMV</button> : <button>LMV</button>}
          </td>
          <td className="ele-city" style={{ textAlign: "center" }}>
            {ele.address}
          </td>
          <td className="ele-progress" style={{ textAlign: "center" }}>
            <div className="progress">
              <div
                className="progress-bar"
                style={{ width: ele.application_progress +'%' }}
              ></div>
            </div>
            {ele.is_kyc_done ? (
                    <i className="fa fa-check" style={{ color: "green" }}></i>
                ) : (
                  <i></i>
             )}
          </td>
          <td className="ele-days" style={{ textAlign: "center" }}>
            {ele.application_age} D
          </td>
        </tr>
      );
    });
    return (
      <div
        className="app-table"
       
      >
        <div className="row">
          <div className="col-6" style={{ marginTop: "16px" }}>
             {/* <i className="fa fa-file-excel-o" */}
             {/*    style={{ color: "green" }} */}
             {/*    onClick={this.handleExcelSheet} */}
             {/*    title="Download all" */}
             {/*  ></i> */}
              <input
                type="checkbox"
                name="checkAll"
                checked={this.state.allChecked}
                onChange={this.handleChange}
                style={{"marginRight":"10px"}}
              />

                    {/*Popup*/}
                    <Popup
                        contentStyle={{ background: "none", border: "none" }}
                        trigger={<button type="button" className="check-button"
                        >

                            SMS
              </button>}
                        modal

                        nested>

                        {close => (
                            <div>
                                <div>
                                    <a href="# " className="close pull-right" onClick={close} >
                                        &times;
</a>
                                </div>
                                <div>
                                    <SmsPopup
                                        dummySMS={"Dummy sms text"}/>
                                </div>
                            </div>
                        )}


                    </Popup>

                    {/*Popup*/}

              { this.state.showInterested ?(
                <i>


              <button className="check-button" onClick={this.handleExcelSheet} >Download</button>
              </i>
              ):
              (<i></i>)
                    }

                    {/*Popup*/}
                    <Popup
                        contentStyle={{ background: "none", border: "none" }}
                        trigger={<button type="button" className="check-button"
                        >

                            Shortlist
              </button>}
                        modal

                        nested>

                        {close => (
                            <div>
                                <div style={{ paddingRight: "310px" }}>
                                    <a href="# " className="close pull-right" onClick={close} >
                                        &times;
</a>
                                </div>
                                <div>
                                    <ShortListPopup 
                                    handelShortList={this.handelShortList}/>
                                </div>
                            </div>
                        )}


                    </Popup>

                    {/*Popup*/}
                </div>

              


          <div className="col-4" >
            
              <ReactPaginate
                className="bmd-drawer-f-r"
                float="right"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
           
          </div>

          <div className="col-2">
            <div className="dropdown" style={{ marginTop: "10px" }}>
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Per Page : {this.state.perPage}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="dropdown-item"
                  onClick={() => this.handlePerPage(20)}
                >
                  20
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => this.handlePerPage(40)}
                >
                  40
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => this.handlePerPage(100)}
                >
                  100
                </button>
              </div>
            </div>
          </div>
          


        </div>

        <table className="table tableBodyScroll">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Name</th>

              <th style={{ textAlign: "center" }}>Type</th>
              <th style={{ textAlign: "center" }}>City</th>
              <th style={{ textAlign: "center" }}>Application Progress</th>
              <th style={{ textAlign: "center" }}>Application Age</th>
              
            </tr>
          </thead>
          <tbody className="main-table">{file}</tbody>
        </table>
      </div>
    );
  }
}

export default Applicants;

import React from 'react';

import './Button.css';

const button = (props) => (
    <div className='button' onClick={()=>{if(props.title === "Job Details"){alert("h1")}}}>
        <p className='btn_count' style={{fontSize: '20px'}}>{props.count}</p>
        <p className='btn_title'>{props.title}</p>
    </div>
);

export default button;
import React, { Component } from "react";
import ReactAudioPlayer from 'react-audio-player';

import "./JobDetails.css";
// import ReactDOM from 'react-dom';
import { NotificationManager } from 'react-notifications';

import { SmsPopup } from "../../../Utils/popup/SmsPopup";
import TimePopup from "../../../Utils/popup/TimePopup";
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
// import CallPopup from "../../../Utils/popup/CallPopup";
// import Profile from "../../applicants/Profile/Profile";

import {schedulespoccall} from '../../../Utils/auth';
import {smscandidate} from '../../../Utils/auth';

function NewlineText(props) {
  const text = props.text;
  const newText = text.split('\n').map(str => <div>{str}</div>);
  
  return newText;
}

class JobDetails extends Component {
  state = {
    companyName:"",
    job_id:"",
    applicants:0,
    address:"",
    completed:0,
    jobDes:"",
    title:"",
    total_interviewed:"",
    matching_profile:"",
    shortlisted:"",
    type:0,
    personValue:"",
    botDemo:"",
    botOtherDemo:"",
    other_language:"",
    phoneNum: "",
    req:"",
    salary:0,
    timestamp:"",
    dlValue:"",
    viewerIsOpen: false,
    is_active: false,
    display:"block",
    currImg: "https://admin.signo.in/media/newdoc_0ONfWnv.jpg",
  };

 
  // addToast = useToasts();

  handelSMS = () => {
    NotificationManager.info("Request sent");
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const sms_body = document.getElementById("toCount").value;
        const params2 = "job_id: \""+this.props.jobId+"\",client_id:\""+client_id+"\",selected:\""+this.state.phoneNum+"\",sms_body:\""+sms_body+"\"";
        smscandidate(params2)
        .then((data) => {
            console.log(data);
          });
  }
  

  handelShare =() =>{
    var input = document.body.appendChild(document.createElement("textarea"));
    input.value = this.state.jobDes+""+this.state.phoneNum;
    input.focus();
    input.select();
    document.execCommand('copy');
    input.parentNode.removeChild(input);
    NotificationManager.info("JD Copied to Clipboard");
  }

 handelEdit=()=>{
    const link = `/postjob/edit`;
    localStorage.setItem("edit_jd",this.state.job_id)
    window.open(link, "_blank")
 }

  handelDelete=()=>{
    
 }

  componentDidUpdate(prevProps) {
    if (prevProps.jobId !== this.props.jobId) {
      this.setState({display:"block"})
      const profiles = JSON.parse(localStorage.getItem("jobs"));
      profiles.forEach((job) => {
        if (job.id === this.props.jobId) {
          this.setState({
            companyName:job.company_name,
            applicants:job.applicants,
            address:job.address,
            completed:job.completed,
            shortlisted:job.shortlisted,
            job_id:job.id,
            is_active:job.is_active,

            total_interviewed:job.total_interviewed,
            matching_profile:job.matching_profile,
            jobDes:job.job_description,
            title:job.job_title,
            type:job.person_type,
            botDemo:job.bot_demo,
            phoneNum:job.phone_number,
            req:job.requirment,
            salary:job.salary,
            botOtherDemo:job.other_bot_demo,
            other_language:job.other_language,
            dlType:job.dl_type,
            timestamp:job.application_age
          });

          if(this.state.person_type === 0){
            this.setState({personValue:"Any"});
          }
          else if(this.state.person_type === 1){
            this.setState({personValue:"Army"});
          }
          else{
            this.setState({personValue:"Civilian"});
          }
          if(job.dl_type === "0"){
            this.setState({dlValue:"Any"});
          }
          else if(job.dl_type=== "1"){
            this.setState({dlValue:"HVM"});
          }
          else{
            this.setState({dlValue:"LMV"});
          }
        }
      });
    }

    localStorage.setItem("spoc",this.state.phoneNum);
    // const element = ReactDOM.findDOMNode(this);
    const audio_0 = document.getElementById('audio_0');
    const audio_1 = document.getElementById('audio_1');

    audio_0.load();
    if (this.state.other_language!=="" && audio_1!==null){
    audio_1.load();
    }
  }
 

  render() {
    
    return (

      <div className="profile-card" style={{display:this.state.display, textAlign: "center"}}>
      
        <div
          style={{
            textAlign: "center",
            width: "90%",
            margin: "30px 0px",
          }}
        >
          <h5>
            <b style={{ color: "#231B1B" ,textAlign: "center"}}>{this.state.companyName}</b>
          </h5>
        </div>
        <table className="table table-borderles">
          <thead>
            <tr>
              <td>
                <button type="button" className="btn btn-dark" onClick={()=>this.handelEdit()}  disabled={this.state.is_active}>
                <i className="fa fa-edit" aria-hidden="true"></i> Edit
                </button>
             
                <button type="button" className="btn btn-dark" onClick={()=>this.handelDelete()}  disabled>
                <i className="fa fa-trash" aria-hidden="true"></i> Delete
                </button>
              </td>
            </tr>
          <tr style={{ textAlign: "center" }}>
            <td>


                            <Popup
                                contentStyle={{ background: "none", border: "none" }}
                                trigger={<button type="button" className="btn btn-dark"
                                >

                                    <i className="fa fa-comments" aria-hidden="true" ></i> SMS To Shortlisted Applicants
              </button>} modal
                                nested >


                                {close => (
                                    <div>
                                        <div>
                                            <a href= "# " className="close pull-right" onClick={close} >
                                                &times;
</a>
                                        </div>
                                        <div>
                                            <SmsPopup
                                                handelSMS={this.handelSMS}/>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                           



                            <Popup
                                contentStyle={{ background: "none", border: "none" }}
                                trigger={<button type="button" className="btn btn-dark"
                                >

                                    <i className="fa fa-calendar" aria-hidden="true"></i> Schedule SPOC Call
              </button>} modal
                                nested >

                                {close => (
                                    <div>
                                        
                                            <a href="# " className="close pull-right" onClick={close}  >
                                                &times;
</a>
                                        
                                        <div>
                                            <TimePopup />
                                        </div>
                                    </div>
                                )}

                            </Popup>

                            









              <button type="button" className="btn btn-dark" onClick={()=>this.handelShare()}  >
                <i className="fa fa-share-alt-square" aria-hidden="true"></i> Share
              </button>
            </td>
          </tr>
          </thead>
        </table>
        Bot Briefing & Q/A:
       
        <div>Hindi:</div>

        <div style={{textAlign:"left",zoom:"120%"}}>
        {/* <audio   id="audio_0" controls disabled={!this.state.botDemo} ><source  src={this.state.botDemo}   type="audio/wav" />
     
        </audio>  */}


      


       <ReactAudioPlayer id="audio_0" controls disabled={! this.state.botDemo}><source src={this.state.botDemo} type="audio/mp3"/></ReactAudioPlayer>


        { this.state.other_language!==null && this.state.other_language!=="--" ? (
          <>
           <div>{this.state.other_language}:</div>
        <audio id="audio_1"  controls disabled={!this.state.botOtherDemo}><source src={this.state.botOtherDemo} type="audio/wav"/></audio>
        </>
          ):(
          <>
            </>
          )}
          </div>
        <table className="table">
          <tbody>
          <tr>
              <td style={{ color: "#292b2c" }}>Requirment</td>
              <td style={{ textAlign: "center" }}>{this.state.req}</td>
            </tr>
            <tr>
              <td style={{ color: "#292b2c" }}>Salary</td>
              <td style={{ textAlign: "center" }}>{this.state.salary}</td>
            </tr>

            <tr>
              <td style={{ color: "#292b2c" }}>City</td>
              <td style={{ textAlign: "center" }}>{this.state.address}</td>
            </tr>


            <tr>
              <td style={{ color: "#292b2c" }}>SPOC Number</td>
              <td style={{ textAlign: "center" }}>{this.state.phoneNum}</td>
                        {/*<CallPopup
                            phone={this.state.phoneNum}
                        />*/}
                        {/*<Profile
                            phone={this.state.phoneNum}
                        />*/}
                        
            </tr>

            <tr>
              <td style={{ color: "#292b2c" }}>Person Type</td>
              <td style={{ textAlign: "center" }}>{this.state.personValue}</td>
            </tr>


            <tr>
              <td style={{ color: "#292b2c" }}>DL Type</td>
              <td style={{ textAlign: "center" }}>{this.state.dlValue}</td>
            </tr>

          </tbody>
        </table>
        Job Description: <NewlineText text={this.state.jobDes}/>
      </div>
    );
  }
}

export default JobDetails;

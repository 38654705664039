import React, { Component } from 'react';

class PurchaseDetails extends Component {




    render() {

        return (
                <div className="post-container container">

                    <div className="header">
                    <div className="second-top-bar">
                        <h1 className="heading" style={{ color: "black" }}>Purchase Details</h1>
                            
                            <br></br>
                        </div>
                    </div>

                    <div className="header">
                        <div className="second-top-bar">
                            <form>
                                <div class="form-group" >
                                    <p><input type="checkbox" />  Include purchase order(PO) details in the invoice    </p>
                                    
                                    <table className="table table-stripped">
                                        <tbody style={{ textAlign: "left" }}>
                                            <tr>
                                                <td><input type="text" class=" form-control" id="code" placeholder="Enter PO Number" /></td>
                                                <td></td>
                                                <td><input type="text" class=" form-control" id="code" placeholder="Enter PO Date" /></td>
                                                <td></td>
                                                <td><input type="text" class=" form-control" id="code" placeholder="Enter PO Expire Date" /></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    
                                </div>

                                <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>Save</button>

                            </form>
                        </div>
                    </div>

                <br></br>
                    <div className="header">
                        <div className="second-top-bar">
                            <p><b>Usage Summaries</b>    </p>
                            <table className="table table-stripped">
                                <tbody style={{ textAlign: "left" }}>
                                    <tr>
                                        <td><b>BILL PERIOD</b></td>
                                        <td></td>
                                        <td><b>AMOUNT</b></td>
                                        <td></td>
                                        <td><b>VIEW SUMMARY</b></td>
                                    </tr>

                                    {/*{reportlist.map(reportlistItem => (
                                <tr key={reportlistItem.key}>
                                    <td><b>{reportlistItem.report}</b></td>
                                    <td></td>
                                    <td><a href={reportlistItem.link} style={{ color: "black" }}>Download</a></td>
                                </tr>
                            ))}  */}

                                    <tr>
                                        <td>--</td>
                                        <td></td>
                                        <td>--</td>
                                        <td></td>
                                        <td><a href="# " style={{ color: "black" }}><i class="fa fa-download"></i></a></td>

                                    </tr>

{/*                                     <tr> */}
{/*                                         <td>01-10-2020 to 31-10-2020</td> */}
{/*                                         <td></td> */}
{/*                                         <td>5863.47</td> */}
{/*                                         <td></td> */}
{/*                                         <td><a href="# " style={{ color: "black"}}><i class="fa fa-download"></i></a></td> */}
{/*  */}
{/*                                     </tr> */}
{/*  */}
{/*                                     <tr> */}
{/*                                         <td>01-10-2020 to 31-10-2020</td> */}
{/*                                         <td></td> */}
{/*                                         <td>5863.47</td> */}
{/*                                         <td></td> */}
{/*                                         <td><a href="# " style={{ color: "black" }}><i class="fa fa-download"></i></a></td> */}
{/*  */}
{/*                                     </tr> */}
{/*                                     <tr> */}
{/*                                         <td>01-10-2020 to 31-10-2020</td> */}
{/*                                         <td></td> */}
{/*                                         <td>5863.47</td> */}
{/*                                         <td></td> */}
{/*                                         <td><a href="# " style={{ color: "black" }}><i class="fa fa-download"></i></a></td> */}
{/*  */}
{/*                                     </tr> */}
{/*                                     <tr> */}
{/*                                         <td>01-10-2020 to 31-10-2020</td> */}
{/*                                         <td></td> */}
{/*                                         <td>5863.47</td> */}
{/*                                         <td></td> */}
{/*                                         <td><a href="# " style={{ color: "black" }}><i class="fa fa-download"></i></a></td> */}
{/*  */}
{/*                                     </tr> */}
{/*                                     <tr> */}
{/*                                         <td>01-10-2020 to 31-10-2020</td> */}
{/*                                         <td></td> */}
{/*                                         <td>5863.47</td> */}
{/*                                         <td></td> */}
{/*                                         <td><a href="# " style={{ color: "black" }}><i class="fa fa-download"></i></a></td> */}
{/*  */}
{/*                                     </tr> */}



                                </tbody>
                            </table>

                        </div>
                    </div>

                    <p>Note: Usage reports before Oct 2020 are not available here.</p>
            </div>

        );

    }
}

export default PurchaseDetails;

import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import CallIcon from '@material-ui/icons/Call';


export class CallPopup extends Component {
    constructor(props) {
        super(props);
    }

    state ={
        "spoc":null,
    };

    handleChange = (name) => (event) => {
        this.setState({ error: "" });
        this.setState({ [name]: event.target.value });
      };


    componentDidMount() {
        this.setState({
            spoc :localStorage.getItem("spoc"),
        })
        console.log("spoc",this.state.spoc);
    }
    handelContact = (job_id) => {





        //   addToast("content", {
        //    appearance: 'info',
        //    autoDismiss: true,
        //  })
        // 
        NotificationManager.info("Request Sent");
    }
    render() {
    return (

        <div className="popup">


            <div className="modal" id="contactpopup" tabIndex="-1"  >
                <div className="modal-dialog "  >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ color: "black" }}>Please enter your SPOC phone number.</h5>
                            {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>*/}
                        </div>



                        <div className="popup-row modal-body">


                                <form>
                                    <div className="form-group">
                                        <label >SPOC phone Number</label>
                                        <br></br>
                                        <input type="number" className="form-control" value={this.state.spoc}  onChange={this.handleChange("spoc")} id="InputContactNo" placeholder="Enter SPOC Phone Number" />
                                    </div>                                         </form>


                            <button
                                type="submit"
                                className="submit btn btn-primary pull-right"
                                style={{ width: "100px", borderRadius: "100px", textAlign: "centre" }}
                                onClick= { () =>
                                        this.props.handelSpocCall(this.state.spoc)
                                      }
                            ><CallIcon /></button>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}
}

export default CallPopup;
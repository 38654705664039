
import React from 'react';
function HelpPopup() {
    return (
        <div className="popup"  >

            {/* <button type="button" class="btn btn-primary" data-target="#contactpopup" data-toggle="modal">SMS</button>*/}

            <div className="modal" id="contactpopup" tabIndex="-1"  >
                <div className="modal-dialog "  >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ color: "black",margin:"auto",marginBottom:"20px" }}>Help Support</h5>
                            {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>*/}
                        </div>

                        <div className="popup-row modal-body">
                            <h6>For any support contact below:</h6>
                        </div>

                        <div className="popup-row modal-body">
                            <h6>
                            <p>Support phone number: +91 7905604688</p>
                            <p>Support email id: info@signo.in</p>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>






        </div>
    );
}
export default HelpPopup;
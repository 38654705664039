import React, { Component } from 'react'
import './PostJob.css'
import { createJob, editJob } from "../Utils/auth";

import { NotificationManager } from "react-notifications";
// import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns"; // choose your lib

// import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
// import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

 class PostJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: "",
      jobDescription: "",
      supervisor: "",
      jobTitle: "",
      requirements: "",
      spockNo: "",
      radius: "",
      averageSalary: "",
      city: "",
      contactNo: "",
      sk_type: "",
      dl_type: "",
      cl_type: "",
      lg_type: "",
      question1: "",
      question_m: "",
      success: false,
      error: "",
      job_id: "",
      skillData:[]
    };
  }

  componentDidMount() {
    fetch(`${process.env.REACT_APP_API_URL}/api/skills/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization:`Bearer ${JSON.parse(localStorage.getItem('jwt')).new_token}`
      },
      redirect: "follow",
      body: JSON.stringify({
        "client_id":JSON.parse(localStorage.getItem('jwt')).client_id
      }),
    }).then((response) => {
     
      return response.json();
    }).then((response)=>{
          console.log(response.data)
          this.setState({
            ...this.state,
            skillData:response.data
          })
    })
  };
  

  changeHandler = (e) => {
    
    this.setState({ success: false });
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDropDown = () => {
    console.log(document.getElementById("personType"));
  };
  componentWillMount() {
    var is_profile_complete = localStorage.getItem("is_profile_complete");
    console.log(is_profile_complete);
    if (is_profile_complete === "false") {
      window.location = "/edituserinfo";
    }
    // window.location="/edituserinfo"

    if (this.props.match.params.edit) {
      let job_id = localStorage.getItem("edit_jd");
      this.setState({
        job_id: job_id,
      });
      const user = {
        job_id: job_id,
      };
      editJob(user).then((response) => {
        console.log(response);
        if (response.status) {
          this.setState({
            companyName: response.company_name,
            requirements: response.requirment,
            radius: response.radius,
            city: response.address,
            averageSalary: response.salary,
            spockNo: response.spockNo,
            jobTitle: response.jobTitle,
            jobDescription: response.jobDescription,
            question1: response.question1,
            question_m: response.question_m,
          });
        }
      });
    }
  }
  submitHandler = (e) => {
    e.preventDefault();
    var skill = document.getElementById("sk_type");
    var lang = document.getElementById("lg_type");
    var skill = skill.options[skill.selectedIndex].value;

    var lang = lang.selectedIndex;
    var client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
    const user = {
      company_name: this.state.companyName,
      skill: skill,
      requirment: this.state.requirements,
      radius: this.state.radius,
      address: this.state.city,
      salary: this.state.averageSalary,
      phone_number: this.state.contactNo,
      lang: lang,
      spockNo: this.state.spockNo,
      jobTitle: this.state.jobTitle,
      jobDescription: this.state.jobDescription,
      question1: this.state.question1,
      question_m: this.state.question_m,
      client_id: client_id,
      job_id: this.state.job_id,
    };
    console.log(user);
    createJob(user)
      .then((response) => {
        console.log(response);

        if (!response.status) {
          this.setState({
            success: true,
            companyName: "",
            jobDescription: "",
            supervisor: "",
            jobTitle: "",
            requirements: 0,
            radius: 0,
            averageSalary: 0,
            city: "",
            contactNo: "",
            spockNo: "",
            sk_type: "",
            dl_type: "",
            cl_type: "",
            lg_type: "",
            error: "",
          });
          NotificationManager.warning(response.msg);
        } else {
          NotificationManager.success("Job Posted");
          window.location = "/";
        }
      })
      .catch((err) => {
        console.log("ERROR ", err);
        NotificationManager.warning("Something went wrong !");
      });
  };

    render() {
      const {
        companyName,
        requirements,
        jobDescription,
        spockNo,
        jobTitle,
        radius,
        averageSalary,
        city,
        question1,
        question_m,
      } = this.state;
  
        return (
            <>
            <div className="container bg-white  mt-3">
          
                <div className="row">
                    
                    <p className=" text-dark mx-auto h1 ">Post Job</p></div><br />
                    {this.state.success && (
          <div class="card">
            <div class="card-body">Job Posted Successfully...</div>
          </div>
        )}
                <form onSubmit={this.submitHandler}> 
             <div class="row">
  <div class="col-md-10">
   
    <div class="form-outline">
    {/* <label class="form-label" for="form8Example1">Enter Company Name</label> */}
    <label htmlFor="InputCompanyName" className="tag">
                  Company Name
                </label>
                <br />
      {/* <input type="text" id="form8Example1" class="form-control" placeholder="Enter Company Name" /> */}
      <input
                  name="companyName"
                  value={companyName}
                  type="text"
                  className="form-control"
                  id="InputCompanyName"
                  placeholder="Enter company name"
                  onChange={this.changeHandler}
                  required
                />
    </div>
  </div>
  <div className="col-sm-2">
  <div class="form-outline">
  {/* <label class="form-label" for="form8Example1">Total Requirement</label> */}
  <label htmlFor="InputCompanyName" className="tag">
                  Total Requirement
                </label>
                <br />
      {/* <input type="number"  id="form8Example1" class="form-control" placeholder="10"/> */}
      <input
                  name="requirements"
                  value={requirements}
                  type="number"
                  className="form-control"
                  id="InputRequirement"
                  placeholder="10"
                  // style={{ width: "30%" }}
                  onChange={this.changeHandler}
                  required
                />
    </div>
  </div>
  </div>
         
  



<div class="row mt-4">
  <div class="col">
    
    <div class="form-outline">
    {/* <label class="form-label" for="form8Example3">City</label> */}
    <label htmlFor="InputCompanyName" className="tag">
                  City
                </label>
                <br />
      {/* <input type="text" id="form8Example3" class="form-control" placeholder="Enter City" /> */}
      <input
                  name="city"
                  value={city}
                  type="text"
                  className="form-control"
                  id="City"
                  placeholder="Enter city"
                  onChange={this.changeHandler}
                  required
                />
    </div>
  </div>
  <div class="col">
  
    <div class="form-outline">
    <label htmlFor="InputCompanyName" className="tag">
                  Radius
                </label>
                <br />
      {/* <input type="number" id="form8Example4" class="form-control" placeholder="Enter Radius(KM)" /> */}
      <input
                  name="radius"
                  value={radius}
                  type="number"
                  className="form-control"
                  id="Radius"
                  placeholder="Enter radius (KM)"
                  onChange={this.changeHandler}
                  required
                />
    </div>
  </div>
  <div class="col">
   
    <div class="form-outline">
    <label htmlFor="InputCompanyName" className="tag">
                  Salary
                </label>
                <br />
      {/* <input type="email" id="form8Example5" placeholder="Enter Salary" class="form-control" /> */}
      <input
                  name="averageSalary"
                  value={averageSalary}
                  className="form-control"
                  id="AverageSalary"
                  placeholder="Enter salary"
                  onChange={this.changeHandler}
                  required
                />
    </div>
  </div>
</div>
<div className="row mt-4">
<div class="col-md-6">
  
  <div class="form-outline">
     
  <label htmlFor="InputCompanyName" className="tag">
                Supervisior Mobile Number
              </label>
              <br />
    {/* <input type="Number" id="form8Example4" class="form-control" placeholder="Enter Mobile Number" /> */}
    <input
                  name="spockNo"
                  value={spockNo}
                  type="number"
                  className="form-control"
                  id="InputSpockNo"
                  placeholder="Enter SPOC Mobile No"
                  onChange={this.changeHandler}
                  required
                />
  </div>
</div>

  
    <div className="col">
    <br />
    <div className="btn-group" >
  
       <select 
                      id="sk_type"
                      onChange={this.changeHandler}
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        height: "40px",
                        
                      
                      }}
                      required="true"
                    >
                      <option selected disabled value="" style={{textAlign:"center"}}>
                       
                        &nbsp; Choose a Skill
                      </option>

                      {/* <option name="skill_type" onChange={this.changeHandler}>
                        &nbsp; {this.state.skillData}
                      </option>
                      <option
                        name="skill_type"
                        value={0}
                        onChange={this.changeHandler}
                      >
                        &nbsp; 4 Wheeler Driver
                      </option>
                      <option
                        name="skill_type"
                        value={1}
                        onChange={this.changeHandler}
                      >
                        &nbsp; 2 Wheeler Driver
                      </option>

                      <option
                        name="skill_type"
                        value={2}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Security Guards/Custodian
                      </option>

                      <option
                        name="skill_type"
                        value={3}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Packer/ WareHouse Manager
                      </option> */}
                      {this.state.skillData && this.state.skillData.map(function(skilldata,index){
                                           return <option
                                           name="skill_type"
                                           value={index}
                                           style={{textAlign:"center"}}
                                          //  onChange={this.changeHandler}
                                         >
                                           {skilldata}
                                         </option> 
                      })}
                    </select>
                   
                  </div>
    </div>
  
    <div className="col">
     
            
               <br />
                  <div className="btn-group">
                    <select
                      id="lg_type"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        height: "40px",
                      }}
                    >
                      <option
                        name="language_type"
                        disabled
                        selected
                        onChange={this.changeHandler}
                      >
                        &nbsp;Select Other Language
                      </option>
                      <option
                        name="language_type"
                        value={0}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Bengali
                      </option>
                      <option
                        name="language_type"
                        value={1}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Gujarati
                      </option>
                      <option
                        name="language_type"
                        value={2}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Kannada
                      </option>
                      <option
                        name="language_type"
                        value={3}
                        onChange={this.changeHandler}
                      >
                        &nbsp; marathi
                      </option>
                      <option
                        name="language_type"
                        value={4}
                        onChange={this.changeHandler}
                      >
                        &nbsp; odia
                      </option>
                      <option
                        name="language_type"
                        value={5}
                        onChange={this.changeHandler}
                      >
                        &nbsp; punjabi
                      </option>
                      <option
                        name="language_type"
                        value={6}
                        onChange={this.changeHandler}
                      >
                        &nbsp; tamil
                      </option>
                      <option
                        name="language_type"
                        value={7}
                        onChange={this.changeHandler}
                      >
                        &nbsp; telugu
                      </option>
                    </select>
                  </div>
              
              
              </div>
  
</div>
<div class="row mt-4">
  <div class="col">
    
    <div class="form-outline">
    <label htmlFor="InputCompanyName" className="tag">
                  Job Title
                </label>
                <br />
      {/* <input type="text" id="form8Example3" class="form-control" placeholder="Open Body Truck Driver" /> */}
      <input
                  name="jobTitle"
                  value={jobTitle}
                  type="text"
                  className="form-control"
                  id="InputJobTitle"
                  placeholder="Open Body Truck Driver"
                  onChange={this.changeHandler}
                  required
                />
    </div>
  </div>
  
  
</div>

<div className="row mt-4">
            <div className="col">
              <div className="form-outline">
                <label htmlFor="JobDescription" className="tag">
                  Job Description
                </label>
              
                
                 <textarea
                  type="text"
                  name="jobDescription"
                  defaultValue={jobDescription}
                  onChange={this.changeHandler}
                  className="jb form-control"
                  id="brand"
                  placeholder="Enter Job Description (eg. Address, Salary Structure)"
                  rows="7"
                  cols="20"
                />
              </div>
            </div>
          </div>
         
          <div className="row mt-4">
            <div className="col">
              <lable className="form-outline tag">
                Job-Related Questions (Optional)
              </lable>
              <p className="form-outline tag">
                <i>
                  Based on the questions provided by you candidate will br
                  filtered & shortlisted
                </i>
              </p>

              <div className="form-outline">
                <label htmlFor="InputSupervisor" className="tag">
                  Question #1 (*This question will be asked to candidate)
                </label>
              
                <input
                  name="question1"
                  defaultValue={question1}
                  type="text"
                  className="form-control"
                  id="question1"
                  placeholder="eg. Do you have a Bike?"
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-outline mt-4">
                <label htmlFor="InputSupervisor" className="tag">
                  Question # (*optional add Multiple Question in Newline)
                </label>
               
                <textarea
                  type="text"
                  name="question_m"
                  defaultValue={question_m}
                  onChange={this.changeHandler}
                  className="form-control"
                  id="question_m"
                  placeholder="eg. Is your age less than 28?"
                  rows="7"
                  cols="5"
                />
              </div>
           </div>
           </div>
           <div className="row text-center  my-4">
              
               <div className="col-sm-12">
               {/* <button
            type="submit"
            className="btn btn-lg px-5 submit"
            style={{ color: "white" }}
          >
            Submit
          </button> */}
           <button
                                type="submit"
                                style={{ width: "250px", borderRadius: "100px",padding:"20px" }}
                                className="submit btn btn-primary"
                            >
                                Submit
                            </button>
               </div>
           </div>
           </form>
       </div>
</>
            
        )
    }
}
export default PostJob

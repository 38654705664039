import React, { Component } from 'react';
import "./MakePayment.css";
import ExtendValidity from './ExtendValidity';
import Upgrade from './Upgrade';
import TopUp from './TopUp';


class OnlinePay extends Component {

    constructor(props) {
        super(props)
        this.state = { show1: true, show2: false, show3: false };

        this.toggleDiv1 = this.toggleDiv1.bind(this)
        this.toggleDiv2 = this.toggleDiv2.bind(this)
        this.toggleDiv3 = this.toggleDiv3.bind(this)
    }

    toggleDiv1 = () => {
        this.setState({
            show1: !this.state.show1,
            show2: false,
            show3: false
        });
    }

    toggleDiv2 = () => {
        this.setState({
            show1: false,
            show2: !this.state.show2,
            show3: false
        });
    }

    toggleDiv3 = () => {
        // this.setState({
        //     show1: false,
        //     show2: false,
        //     show3: !this.state.show3
        // });
        window.location ="/pricing"
    }
    

    render() {
        return (
            <div >
                <button
                    type="submit"
                    className="togglebtn submit btn btn-primary"
                    style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
                    onClick={this.toggleDiv1}
                >
                    Top-up for extra credits
                </button>
               

                <button
                    type="submit"
                    className="togglebtn submit btn btn-primary"
                    style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
                    onClick={this.toggleDiv2}
                >
                    Extend validity
                </button>

                <button
                    type="submit"
                    className="togglebtn submit btn btn-primary"
                    style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}
                    onClick={this.toggleDiv3}
                >
                    Upgrade
                </button>
                {this.state.show1 && <TopUp /> }
                {this.state.show2 && <ExtendValidity />}
                {this.state.show3 && <Upgrade />}
                <div>

                </div>


                {/*<TopUp />*/}

                {/*<ExtendValidity />*/}

                {/*<Upgrade />*/}

            </div>
        );
    }
}

export default OnlinePay;
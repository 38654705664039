import React, { useState } from "react";
import "./ManageTeam.css";

import MemberTree from './MemberTree';
import MemberForm from './MemberForm';
import {listmanager} from "../../Utils/auth";

function ManageTeam() {

    const [members, setMembers] = useState([]);


    function addMember(newMember) {
        setMembers(prevMembers => {
            return [...prevMembers, newMember]
        })
    }


    function deleteMember(id) {
        setMembers(prevMembers => {
            return prevMembers.filter((memberItem, index) => {
                return index !== id;
            })
        })
    }
    function list_manager() {
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id; 
        const params2 = { client_id: client_id};
        listmanager(params2)
            .then((data) => {
                console.log(data);
                localStorage.setItem("addmembers", JSON.stringify(data.data));
                const lists = JSON.parse(localStorage.getItem("addmembers"));
               this.setState({ members: lists});

            });

    }


    return (
        <section id="team">

            <div className="row">



                
                <div className="team-column col-lg-8">
                    <div className="card">
                        <div className="card-header">
                            <h2 className="bold-text">Add Team</h2>
                        </div>



                        <div className="member-tree tf-tree">
                            <ul>
                                <li>
                                    <span className="tf-nc">Company</span>
                                    <ul>
                                        <li>
                                            <span className="tf-nc">Admin</span>
                                            <ul>
                                                
                                                    {members.map((memberItem, index) => {
                                                        return <MemberTree
                                                            key={index}
                                                            id={index}
                                                            name={memberItem.name}
                                                            phone={memberItem.phone}
                                                            confirmPhone={memberItem.confirmPhone}
                                                            email={memberItem.email}
                                                            role={memberItem.role}
                                                            onDelete={deleteMember}
                                                        />
                                                    })}

                                                <li><span className="tf-nc">
                                                    <p style={{ color: "gray" }}><u>ADD MEMBERS</u></p>
                                                    
                                                </span></li> 
                                                

                                            </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>



                    </div>
                </div>



                



















                

                <MemberForm
                    onAdd={addMember}
                />

            </div>
        </section>
    );
}

export default ManageTeam;
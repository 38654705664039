import React, { Component } from 'react';
import { NotificationManager } from 'react-notifications';
import {smscandidate} from "../auth";

export class SmsPopup extends Component {
    state={
    phoneNum:""
};

    componentDidMount() {
        document.getElementById('toCount').addEventListener('input', function () {
            var text = this.value,
               count = text.length;
            document.getElementById('wordCount').textContent = count;

        });
    }

 handleChange = (name) => (event) => {
    this.setState({ error: "" });
    this.setState({ [name]: event.target.value });
  };


    handelSMS = () => {
        NotificationManager.info("Request sent");
       
    }

    render() {

        return (

            <div className="popup">


                {/*<button type="button" class="btn btn-primary" data-target="#popup" data-toggle="modal">SMS</button>*/}

                <div className="modal" id="popup" tabIndex="-1" >
                    <div className="modal-dialog modal-lg"  >
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" style={{ color: "black" }}>Send SMS</h5>
                                {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                    <span aria-hidden="true">&times;</span>
                                </button>*/}
                            </div>



                            <div className="modal-body">

                                <div className="row">

                                    <div className="popup-column col-lg-8">
                                        <div className="card">
                                            {/* <div className="card-header"> */}
                                            {/*     <p><SmsOutlinedIcon /> SMS</p> */}
                                            {/* </div> */}

                                            <div className="card-body">
                                                <div class="form-group">
                                                    <label className="tag" ><p>You can send only 1 sms per candidate</p></label>
                                                    <p>The number of charecters entered is <span id="wordCount"></span></p>
                                                    <textarea id="toCount" name="sms" class="sms form-control" maxLength="160"  placeholder={this.props.dummySMS} rows="10" cols="30"></textarea>

                                                    <br></br>
                                                    <p>160 charecters only</p>
                                                    {/*<p>{this.state.count}</p>
                                                     maxlength="160"*/}
                                                    <br></br>
                                                    <button type="submit"
                                                        className="submit btn btn-primary pull-right"
                                                        style={{ width: "100px", borderRadius: "100px", textAlign: "centre" }}
                                                        
                                                        onClick= { () =>
                                                            this.props.handelSMS()
                                                          }
                                                    >Send SMS</button>

                                                </div>

                                                <h6><b>NOTICE</b></h6>
                                                <p>Misuse or fraud use of these platform will result in deactivation or further action as per company policy. <a href="https://sites.google.com/view/signo-terms-and-conditions/">Terms</a></p>
                                            </div>





                                        </div>
                                    </div>





                                    <div className="popup-column col-lg-4">
                                        <div className="card">
                                            <div className="card-header">
                                                <h6 className="bold-text">REMEMBER TO INCLUDE</h6>
                                            </div>
                                            <div className="card-body">
                                                <h6><b>How should the candidate reply to you?</b></h6>
                                                <p>Insert your phone no. and your email address for the candidate to contact you back.</p>
                                                <br></br>
                                                <br></br>
                                                <br></br>
                                                <br></br>

                                                <h6><b>Which Job are you sending the candidate?</b></h6>
                                                <p>Insert a link to the job and explain all the details about the job. Eg: Role, company, Job location, salary, etc.</p>
                                            </div>
                                        </div>
                                    </div>


                                </div>



                            </div>





                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default SmsPopup;

import React from 'react';
import "./MakePayment.css";


function Discount() {
    return (
        <div>
            <form>
                <div class="form-group" >
                    <input type="text" class="codetext form-control" id="code" placeholder="Enter Discount/Coupon Code"  / >
                </div>
                    
                <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>Redeem</button>
                
            </form>

        </div>
    );
}
export default Discount;
import React, { Component } from 'react';
import "./MakePayment.css";

import OnlinePay from './OnlinePay';
import Discount from './Discount';

class MakePayment extends Component {

    constructor(props) {
        super(props)
        this.state = { show1: true, show2: true  };
     
      
        // this.toggleDiv1 = this.toggleDiv1.bind(this)
        // this.toggleDiv2 = this.toggleDiv2.bind(this)
    }
    toggler = (event)=>{
       if(event.target.id === "discount") {
          
           this.setState({
                    show1: !this.state.show1,
                    show2: false
                });
           console.log(event.target.id)
       } 
          else {
        
          this.setState({
                    show1: false,
                    show2: !this.state.show2
                });
      }
    }

    // toggleDiv1 = () => {
    //     this.setState({
    //         show1: !this.state.show1,
    //         show2: false
    //     });
    // }

    // toggleDiv2 = () => {
    //     this.setState({
    //         show1: false,
    //         show2: !this.state.show2
    //     });
    // }
   

    render() {
        return (


            <div className="post-container container">
                <div className="header">
                    <div className="second-top-bar">
                        <h1 className="heading" style={{ color: "black" }}>Make Payment</h1>
                        <br></br>
                    </div>
                </div>

                <div className="header">
                    <div className="second-top-bar">
                        <table className="table table-stripped">
                            <tbody>
                                <tr>
                                    <td><b>A/C balance</b></td>
                                    <td></td>
                                    <td><b>Valid till</b></td>
                                </tr>


                                <tr>
                                    <td><b>Credit: {localStorage.getItem("balance")}</b></td>
                                    <td></td>
                                    <td>{localStorage.getItem("balanceValidity")}</td>
                                </tr>


                            </tbody>
                        </table>
                        <p>Once your validity has expired, the remaining credits shall be consumed unless you have extended your validity by choosing the 'Extended Validity' option below.<a href="# ">Know more</a></p>
                    </div>
                </div>

                <div className="header">
                    <div className="second-top-bar">
                        {/* <a href="# " className="pay-option" onClick={this.toggleDiv1}>Discount/ Coupon code</a> */}
                        <a href="# " id="discount"  className="pay-option" onClick={this.toggler}>Discount/ Coupon code</a>
                        <br></br>
                        {this.state.show1 && <Discount />}
                        <div>


                        </div>
                    </div>
                    <br></br>
                    <div className="second-top-bar">
                        {/* <a href="# " className="pay-option" onClick={this.toggleDiv2}>Online Payment</a> */}
                        <a href="# " id="online"  className="pay-option" onClick={this.toggler}>Online Payment</a>
                        <br></br>
                        <br></br>
                        <div>
                            {/* <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>Top-up for extra credits</button>
                            <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>Extend validity</button>
                            <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>Upgrade</button>
                            */}
                            {this.state.show2 && <OnlinePay />}



                            {/*<TopUp />*/}

                            {/*<ExtendValidity />*/}

                            {/*<Upgrade />*/}

                        </div>
                    </div>
                </div>
            

            </div>


        );
    }
}



export default MakePayment;
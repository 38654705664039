import React, { Component } from "react";
import "./LandingPage.css";
import SignoIcon from '../Utils/dummy/backLogo.png'
import DHLLogo from '../Utils/images/DHLLogo.png'
import BluedartLogo from '../Utils/images/BLRLogicticlogo.png'
import GlassWingLogo from '../Utils/images/Gigforce.png'
import UpscrtcLogo from '../Utils/images/multiplie.jpg'
import SafeExpressLogo from '../Utils/images/Millionmindlogo.png'
import GooglePlayStoreLogo from '../Utils/images/GooglePlayStoreLogo.png'

import {Link} from 'react-router-dom'

class LandingPage extends Component {
  render() {
    return (
        <>
      <div className="container-fluid" >
        <div className="row">
          <div className="col-sm-9 mt-4 ml-5  "> <img className="img1" src={SignoIcon} alt="logo" style ={{"opacity":1}}  width="100px" height="100px" /></div>
          <div className="col-md-1 ">
      <Link to="/LoginIn">    <button
              style={{ width: "250px", borderRadius: "100px", padding: "15px",fontSize:"20px" }}
              className="submit btn mt-5 "
            >
            Employer Login
            </button></Link>
          </div>
        </div>
          {/* second row */}
          <div className="row" style={{height:"700px"}} >
            <div className="col-sm-6 seconddiv  pl-5">
              <p className=" pstyle " style={{fontFamily:"'Montserrat', sans-serif",fontSize:"67px"}}>Hire Candidate In 60 Seconds</p>
              <p className="h4 pstyle2" style={{fontFamily: "'Montserrat', sans-serif"}}>Leveraging artificial intelligence (AI) and natural language processing (NLP), 
Signo’s conversational AI Bot allows us to connect exact Job to the right Person within 60 Sec.</p>
                <button
              style={{ width: "250px", borderRadius: "100px", padding: "15px",fontSize:"20px" }}
              className="submit btn mt-5 "
            >
            Download SIGNO App
            </button>
            </div>
         
                     <div className="col-sm-5 ml-5  mobileimage" >
                       
                     </div>
                   
          </div>
               
          
           </div>
           {/* Third Section */}
           <div className="container-fluid  col2div" >
           <div className="row " 
           >
             
              
                <div className="col-sm-6">

                </div>
         {/* <div className="col-sm-1"></div> */}
           <div className=" thirdC col-sm-6">
             <p className="pstyle  mt-5" style={{fontFamily:"'Montserrat', sans-serif",fontSize:"45px"}}>Find the perefect candidate in 60 seconds</p>
             <p className="h4 pstyle2" style={{fontFamily: "'Montserrat', sans-serif"}}>With over 50+ Candidates Categories. You can find the perfect 
                Candidate Yourself</p><br /><br />
                <p className="h3  font-weight-bold"  style={{color:"#ffcf0d"}}>See All Candidates Categories &nbsp;&nbsp;<i style={{color:"black"}} className="fa fa-arrow-right" aria-hidden="true"></i></p>
             </div>
             </div>

             <div className="container-fluid  " style={{marginTop:"60px",height:"800px"}}>
               <div className="row">

               <div className="  col-sm-6" style={{marginTop:"300px"}}>
             <p className="pstyle display-2 mt-5" style={{fontFamily:"'Montserrat', sans-serif",fontSize:"45px"}}>Search Candidates by Location</p>
             <p className="h4 pstyle2" style={{fontFamily: "'Montserrat', sans-serif"}}>With over 10 Candidates Location. You can find the perfect 
                Candidate Yourself</p><br /><br />
                <p className="h3 font-weight-bold" style={{color:"#ffcf0d"}}>See All Candidates Location&nbsp;&nbsp;<i style={{color:"black"}} className="fa fa-arrow-right" aria-hidden="true"></i></p>
             </div>
             
             <div className="col-sm-5 s1" style={{marginTop:"200px",height:"600px"}}></div>
             

         

               
             </div>
             
             </div>
          
            
            
          
      </div>
      {/* <div className="container-fluid text-center" style={{marginTop:"150px",}} >
        <div className="row ">
        <p className="display-2 mx-auto font-weight-bold" style={{fontFamily:"'Montserrat', sans-serif",fontSize:"55px"}}>Companies That Hire Through SIGNO</p>
        </div>
        <div className="row" style={{marginTop:"70px",}}>
          <div className="col"></div>
          
          <div className="col">
          <img className="img1 mx-2" src={DHLLogo} alt="logo" style ={{"opacity":4}}  width="100px" height="100px" />
          </div>
          <div className="col">
          <img className="img1 " src={BluedartLogo} alt="logo" style ={{"opacity":4}}  width="200px" height="100px" />
          </div>
          <div className="col">
          <img className="img1 " src={GlassWingLogo} alt="logo" style ={{"opacity":4}}  width="200px" height="100px" />
          </div>
          <div className="col">
          <img className="img1 " src={SafeExpressLogo} alt="logo" style ={{"opacity":4}}  width="200px" height="100px" />
          </div>
          <div className="col">
          <img className="img1 " src={UpscrtcLogo} alt="logo" style ={{"opacity":4}}  width="200px" height="100px" />
          </div>
          <div className="col"></div>
        </div>
      
        <br /><br /><br /><br /><br /><br />
        <p className="font-weight-bolder" style={{color:"black"}}>________________________________________________________</p>
        <br /><br /><br /><br /><br /><br />
      </div> */}
      <div className="container-fluid pt-4 mt-5" style={{height:"400px",backgroundColor: "rgba(0, 0, 0, 0.2)"}}>
     
         <div className="row">
          
     
           <div className="col-sm-3" >
           
           <img className="img1 ml-3" src={SignoIcon} alt="logo" style ={{"opacity":1}}  width="60px" height="60px" />&nbsp;&nbsp; 
            <p className=" display-3 font-weight-bold" style={{display:"inline",fontFamily:"'Montserrat', sans-serif",fontSize:"40px"}}>SIGNO</p>
           </div>
           <div className="col-sm-3">
             <p className="h4 " style={{fontFamily:"'Montserrat', sans-serif"}}>Company</p>
           </div>
           <div className="col-sm-3">
             <p className="h4" style={{fontFamily:"'Montserrat', sans-serif"}}>Social</p>
           </div>
           <div className="col-sm-3">
             <p className="h4" style={{fontFamily:"'Montserrat', sans-serif"}}>Contact Us</p>
           </div>

         </div>

         <div className="row">
           <div className="col-sm-3">
          
          <img className=" mt-3" src={GooglePlayStoreLogo} alt="logo" style ={{"opacity":1}}  width="250px" height="70px" />
        
           </div>

           <div className="col-sm-3">
           <p>© 2020 by Signo. All Rights Reserved.</p>
<p>Signodrive Logistics Pvt Ltd. is an Indian corporation and conducts business throughout India. Signodrive Logistics Pvt Ltd is the holder registered trademark for the name Signo within India. Signo 
is not affiliated with any trucking company of the same name located in India.</p>
           </div>
          
           <div className="col-sm-3">

             <div><a href="https://www.facebook.com/Signodrive" target="_blank">
             <i className="fa fa-2x fa-facebook-square" aria-hidden="true"></i>
           &nbsp;&nbsp;&nbsp;&nbsp;
           <label className="text-muted h4 ">Facebook</label></a>
           </div>

           <div><a href="https://www.linkedin.com/company/signo-drive/" target="_blank">
           <i class="fa fa-2x fa-linkedin" aria-hidden="true"></i>
           &nbsp;&nbsp;&nbsp;&nbsp;
           <label className="text-muted h4 ">LinkedIn</label></a>
           </div>
         
         <div>
           <a href="https://www.youtube.com/channel/UCuvhtBVIStc0BxlAvsCif2g" target="_blank">
           <i className="fa fa-2x fa-youtube-play" aria-hidden="true"></i>
           &nbsp;&nbsp;&nbsp;&nbsp;
           <label className="text-muted h4 ">Youtube</label></a>
           </div>
             </div>
         
           

           <div className="col-sm-3">
           
        <h5 class="text-uppercase ml-0 p-0" style={{fontFamily:"'Montserrat', sans-serif"}}>Head Office</h5>

        <ul style={{fontFamily:"'Montserrat', sans-serif"}}>
          <li>
          B-61, Sector-67, Noida, Uttar Pradesh
          </li>
          <li>
          201014
          </li>
          <li>
          info@signo.in
          </li>
          <li>
          Tel: +91 7428523569
          </li>
         
        </ul>
      
                <br />
         
       </div>    
        </div>
        
          
            
        
        
          
           
         </div> 
        
  
      
      </>
    );
  }
}
export default LandingPage;

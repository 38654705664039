import React, { Component } from "react";

import ReactPaginate from "react-paginate";
import { getJobBotReportApi,excelSheet } from "../../../Utils/auth";
import ButtonModal from '../../../TrialsInProject/ButtonModal'
import avatar from "../../../Utils/dummy/avatar.png";
import "./InterviewList.css";
import { SmsPopup } from "../../../Utils/popup/SmsPopup";
import Popup from 'reactjs-popup';

class InterviewList extends Component {
  constructor(props) {
    super(props)
  
    this.state = {
      job_id: "JOB010063",
      users: [],
      searchUsers: [],
      offset: 0,
      data: [],
      perPage: 80,
      currentPage: 0,
      filter: "none",
      allChecked: false,
      questions:[]
    }
  }
  
  // state = {
  //   job_id: "JOB010063",
  //   users: [],
  //   searchUsers: [],
  //   offset: 0,
  //   data: [],
  //   perPage: 80,
  //   currentPage: 0,
  //   filter: "none",
  //   allChecked: false,
  // };

  componentDidUpdate(prevProps) {
    
    // alert(prevProps.currentDate)
  //  alert(this.props.filteredDate)
       const {filteredDate} = this.props
      
     
      if(localStorage.getItem('selectedRangeDate')==="true"){
        
       
        const completeData = JSON.parse(localStorage.getItem("interviews"));
       
      let filteredData =  completeData.filter(function(dataDate){
                         
                               var currData = new Date()
                               var year = currData.getFullYear()-1
                                 if(dataDate.time_recorded.substr(3,3) === "Jan") {
                                  year = currData.getFullYear()
                                 }
                              
                              //  console.log(year)
                               var month= new Date(dataDate.time_recorded).getMonth() + 1
                              //  console.log(month)
                               var date = new Date(dataDate.time_recorded).getDate()
                                var date1 =month+"/"+date+"/"+year
                                var completeDate = new Date(date1)
                              //  console.log(completeDate)
                              //  console.log(dataDate.time_recorded)
                              //  console.log(Number(dataDate.time_recorded.substr(0,2)))
                                // console.log(dataDate.time_recorded)
                              // console.log(currentDate)
                              //  console.log(filteredDate)
                              // console.log(Number(dataDate.time_recorded.substr(0,2)) < currentDate)
                              //  console.log(currData.toLocaleDateString())
                              // console.log(completeDate < currData)
                              // console.log()
                              // console.log(filteredDate)
                              // console.log(completeDate)
                              // console.log(currData)
                              //  console.log(completeDate >= filteredDate)
                              //  console.log(completeDate <= currData)
                          return completeDate <= currData && completeDate >= filteredDate
                            
        })
        localStorage.setItem('selectedRangeDate',false)
        //  console.log(filteredData)
          this.setState({
            
            searchUsers:filteredData
          })
         
          
         return
        
        
      }
    if((this.props.filter1 !== prevProps.filter1) || (this.props.filter2 !== prevProps.filter2) || (this.props.filter3 !== prevProps.filter3)){
      this.filterData();
    }

    if (prevProps.searchQuery !== this.props.searchQuery) {
      var sQ = this.props.searchQuery.toLowerCase().trim();

      var updateUsers = [];
      this.state.users.forEach((element) => {
        if (
          // element.name.toLowerCase().indexOf(sQ) !== -1 ||
          // element.address.toLowerCase().indexOf(sQ) !== -1 ||
          element.phone_number.indexOf(sQ) !== -1
        ) {
          updateUsers.push(element);
        }
      });
      this.setState({ searchUsers: updateUsers });
    }
    if(prevProps.jobId !== this.props.jobId){
      this.setState({job_id:this.props.jobId});
      this.callAllApplicants(this.props.jobId);
    }
  }

  callAllApplicants = (jobId) =>{
    const username = JSON.parse(localStorage.getItem("username"));
    
    const jobs = { job_id: jobId, username: username };
    getJobBotReportApi(jobs)
      .then((data) => {
             
             
              // var quetion = data.data.map((q)=>{
              //            return q.questions
              // })
              // console.log(quetion)
              
       
        if (data.message === 'job_id invalid') {
          this.setState({ error: data.error });
          this.props.parentCallback(0);
          return;
        }

        localStorage.setItem("interviews", JSON.stringify(data.data));
        // localStorage.setItem("Questions", JSON.stringify(quetion));
        this.props.parentCallback(data.data.length,data.total_interested, data.total_missed);
        this.receivedData(this.state.perPage);
      })
      .catch((err) => {
        console.log("Error in getting all the users", err);
      });
  }
  receivedData(valuePerPage) {
    const data = JSON.parse(localStorage.getItem("interviews"));
    const o_data = JSON.parse(localStorage.getItem("interviews"));
    const pageCount=Math.ceil(data.length / valuePerPage);
    const setData = data.splice(this.state.offset * valuePerPage, valuePerPage);
    // console.log("DATA:", setData);
  
    this.setState({
      pageCount: pageCount,
      searchUsers: setData,
      users: o_data,
    });
    if (localStorage.getItem("interviews")) {
      const applicants = JSON.parse(localStorage.getItem("interviews")).length;
      this.setState({ totalApplications: applicants });
    }
  }

  handlePageClick = (e) => {
    const selectedPage = e.selected;
    this.setState(
      {
        offset: selectedPage,
      },
      () => {
        this.receivedData(this.state.perPage);
      }
    );
  };

  filterData = () => {
    const {filter1,filter2,filter3} = this.props;
    // console.log(filter1, filter2, filter3);
    const data = JSON.parse(localStorage.getItem("interviews"));
    var updateUsers = [];
    data.forEach((element) => {
      
        if (filter2 && element.o_status.includes("Interested") === filter2) {
          // if (filter3 && element.application_age <= parseInt(filter3))
            updateUsers.push(element);}
        else if (filter2!==null && element.o_status.includes("Interested") === filter2)    {
            updateUsers.push(element);
        }
        else if (filter2===null){
          updateUsers.push(element);
        }
    });

    // console.log("FILTER", updateUsers);
    this.setState({ searchUsers: updateUsers });
  };

  handlePerPage = (value) => {
    // console.log(value);
    this.setState({ perPage: value });
    this.receivedData(value);
  };

  transfer = (id) => {
    // console.log("This is my info", id);
  };

  handleChange = e => {
    let itemName = e.target.name;
    let checked = e.target.checked;
   console.log(itemName);
    this.setState(prevState => {
      let { searchUsers, allChecked } = prevState;
      if (itemName === "checkAll") {
        allChecked = checked;
        searchUsers = searchUsers.map(item => ({ ...item, isChecked: checked }));
      } else {
        searchUsers = searchUsers.map(item =>
          String(item.id) === itemName ? { ...item, isChecked: checked } : item
        );
        allChecked = searchUsers.every(item => item.isChecked);
      }

     
      return { searchUsers, allChecked };
    });
  };

  handleExcelSheet = () => {
    
    const client_id =  JSON.parse(localStorage.getItem("jwt")).client_id;
    const username = JSON.parse(localStorage.getItem("username"));

   
    let searchUsers = this.state.searchUsers;

    searchUsers =searchUsers.filter(item => item.isChecked)
    searchUsers = searchUsers.map(e => e.id).join(",")
    
    const params = {
      client_id:client_id,
      job_id:this.state.job_id,
      username:username,
      selected: searchUsers,

    };
    excelSheet(params)
      .then((url)=>{
        const endPoint = "https://admin.signo.in/media/" + url.status + ".xlsx";
        console.log("EXCEL SHEET:",endPoint);
        window.open(endPoint);
      })
      .catch(err=>{
        console.log("ERROR",err);
    })
  }


  render() {
    
    console.log(this.state.searchUsers)
    var file = this.state.searchUsers.map((ele, i) => {

      return (
        <tr key={i}  onClick={this.props.userId.bind(this, "+91"+ele.phone_number)}>
          <td className="ele-name" >
            <input
              type="checkbox"
              aria-label="Checkbox for following text input"
              onChange={this.handleChange}
              name={ele.id}
              value={ele.id }
              checked={ele.isChecked }
            />
            <img src={avatar} alt="..." style={{ width: "50px" }} />
            {ele.time_recorded}
            
          </td>
          <td className="ele-type" style={{ textAlign: "center" }}>
            {ele.phone_number }
          </td>
          <td className="ele-city" style={{ textAlign: "center" }}>
            {ele.o_status}
            
          </td>
          <td className="ele-progress" style={{ textAlign: "center" }}>
          {ele.o_status.includes("Interested") ? (<>
          {ele.call_recording ? ( <audio controls disabled={!ele.call_recording} src={ele.call_recording} ></audio>):(<>Missed Call</>)}
            </>
             ) : (<>
                  --</>
             )}
          
          </td>
          <td className="ele-days" style={{ textAlign: "center" }}>
            {ele.o_status.includes("Interested") ? (
                    <i className="fa fa-check" style={{ color: "green" }}></i>
                ) : (
                  <i className="fa fa-times" style={{ color: "red" }}></i>
             )}
          </td>
          <td className="ele-city" style={{ textAlign: "center" }}>
            {/* {console.log(ele.questions)} */}
            
          <Popup
          
                    contentStyle={{ background: "none", border: "none" }}
                    trigger={<button  type="button" className=" btn btn-dark btn-small"
                    >
                        Click To Know
              </button>}
                    modal

                    nested>

                    {close => (
                        <div>
                            
                                <a href="# " className="close pull-right" onClick={close} >
                                    &times;
</a>
                   
                            <div>
                                < ButtonModal dataQ={ele.questions}  />
                            </div>
                        </div>
                    )}


                </Popup>
            
          </td>
         

        </tr>
      );
    });
    return (
      <div
        className="app-table"
       
      >
        <div className="row">
          <div className="col-6" style={{ marginTop: "16px" }}>
             {/* <i className="fa fa-file-excel-o" */}
             {/*    style={{ color: "green" }} */}
             {/*    onClick={this.handleExcelSheet} */}
             {/*    title="Download all" */}
             {/*  ></i> */}
              <input
                type="checkbox"
                name="checkAll"
                checked={this.state.allChecked}
                onChange={this.handleChange}
                style={{"marginRight":"10px"}}
              />
                      <Popup
                        contentStyle={{ background: "none", border: "none" }}
                        trigger={<button type="button" className="check-button"
                        >

                            SMS
              </button>}
                        modal

                        nested>

                        {close => (
                            <div>
                                <div>
                                    <a href="# " className="close pull-right" onClick={close} >
                                        &times;
</a>
                                </div>
                                <div>
                                    <SmsPopup
                                        dummySMS={"Dummy sms text"}/>
                                </div>
                            </div>
                        )}


                    </Popup>
          </div>

          <div className="col-4" >
            
              <ReactPaginate
                className="bmd-drawer-f-r"
                float="right"
                previousLabel={"Prev"}
                nextLabel={"Next"}
                breakLabel={"..."}
                breakClassName={"break-me"}
                pageCount={this.state.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                onPageChange={this.handlePageClick}
                containerClassName={"pagination"}
                subContainerClassName={"pages pagination"}
                activeClassName={"active"}
              />
           
          </div>

          <div className="col-2">
            <div className="dropdown" style={{ marginTop: "10px" }}>
              <button
                className="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Per Page : {this.state.perPage}
              </button>
              <div
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton"
              >
                <button
                  className="dropdown-item"
                  onClick={() => this.handlePerPage(40)}
                >
                  40
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => this.handlePerPage(80)}
                >
                  80
                </button>
                <button
                  className="dropdown-item"
                  onClick={() => this.handlePerPage(200)}
                >
                  100
                </button>
              </div>
            </div>
          </div>
          


        </div>

        <table className="table tableBodyScroll">
          <thead>
            <tr>
              <th style={{ textAlign: "center" }}>Time</th>

              <th style={{ textAlign: "center" }}>Phone Number</th>
              <th style={{ textAlign: "center" }}>Status</th>
              <th style={{ textAlign: "center" }}>Spoc Call recording</th>
              <th style={{ textAlign: "center" }}>Remark</th>
              <th style={{ textAlign: "center" }}>Bot Questions</th>
              
               
              
            </tr>
          </thead>
          <tbody className="main-table">{file}</tbody>
        </table>
      </div>
    );
  }
}

export default InterviewList;

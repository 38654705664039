
import React from "react";
import "./ManageTeam.css";

function ManageTree(props) {

    function handleClick() {
        props.onDelete(props.id);
    }
    
    return (


        <li><span className="tf-nc">
            <p>{props.name}</p>
            <p style={{ color: "gray" }}>{props.role}</p>
            <br></br>
            <button onClick={handleClick} type="button" className="button btn btn-primary btn-sm">Delete</button>
        </span></li>   
                        
    );
}

export default ManageTree;
import React, { Component } from "react";
import { regClient } from "../../Utils/auth";
import logo from "../../Utils/dummy/backLogo.png";

import Helmet from "react-helmet"

import firebase from "firebase/app"
import * as firebaseui from 'firebaseui'
import $ from "jquery";
// import firebase from "firebase";
import { NotificationManager} from 'react-notifications';
import "./Register.css";

class Register extends Component {
  state = {
    username: "",
    name: "",
    password: "",
    checkbox: false,
    cpassword: "",
    error: "",
    open: false,
  };

  handleChange = (name) => (event) => {
    this.setState({ error: "" });
    this.setState({ [name]: event.target.value });
  };

  changeHandler = (e) => {
    this.setState({ success: false });
    this.setState({ [e.target.name]: e.target.value });
  };

  clickSubmit = (event) => {
   
    const {  name, password , cpassword} = this.state;
    const username = document.getElementById("phone_number_").value
    const user = { username, password ,name};
 	console.log(username);
    if (!this.state.checkbox){
    	NotificationManager.warning("Please accept Terms")
    }
    else if (cpassword!==password){
    	NotificationManager.warning("Password Not Matched")
    }
    else{
	    regClient(user).then((data) => {
            console.log(data);
	      if (!data.status) {
	        NotificationManager.warning(data.msg)
	      }
	      else{
	      	NotificationManager.info("success");
	      	window.location = "/";
	      }
	    });
	}
  };


  isPasswordMatch =() =>{
   
	}


handleCheck =() =>{
    this.setState({
        checkbox:!this.state.checkbox
    })
}

  componentDidMount() {
    document.title = "Signo Client Login";

	var config = {
	  apiKey: "AIzaSyCkX3ofruDNQ6_4Gyw3ZIOyiLvvXzmgDD0",
	  authDomain: "localhost",
	  projectId: "signo",
	  messagingSenderId: "242588682017"
	};
	firebase.initializeApp(config)
 

 
    document.getElementById('register').style.display='none'

	const uiConfig = {
      'callbacks': {
            // Called when the user has been successfully signed in.
            'signInSuccess': function(user, credential, redirectUrl) {
               	document.getElementById('register').style.display = 'block';
			    document.getElementById('user-signed-out').style.display = 'none';
			    document.getElementById("phone_number_").value = user.phoneNumber;
			   
			    document.getElementById("uid").value=user.uid
                // Do not redirect.
                return false;
            }
        }, //This URL is used to return to that page when we got success response for phone authentication.
		// signInSuccessUrl: '/register',
       'signInOptions': [
            // The Provider you need for your app. We need the Phone Auth

            {
                provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
                recaptchaParameters: {
                    type: 'image', // another option is 'audio'
                    size: 'invisible', // other options are 'normal' or 'compact'
                    badge: 'bottomleft' // 'bottomright' or 'inline' applies to invisible.
                },
                defaultCountry: 'IN',
            }
        ],
      'tosUrl': 'https://www.google.com'
    };
	var ui = new firebaseui.auth.AuthUI(firebase.auth());
    ui.start("#firebaseui-container", uiConfig);






$(function() {

    $("input[type='password'][data-eye]").each(function(i) {
        var $this = $(this),
            id = 'eye-password-' + i;

        $this.wrap($("<div/>", {
            style: 'position:relative',
            id: id
        }));

        $this.css({
            paddingRight: 60
        });
        $this.after($("<div/>", {
            html: 'Show',
            class: 'btn btn-primary btn-sm my-login-btn',
            id: 'passeye-toggle-'+i,
        }).css({
                position: 'absolute',
                right: 10,
                top: ($this.outerHeight() / 2) - 12,
                padding: '2px 7px',
                fontSize: 12,
                cursor: 'pointer',
        }));

        $this.after($("<input/>", {
            type: 'hidden',
            id: 'passeye-' + i
        }));

        var invalid_feedback = $this.parent().parent().find('.invalid-feedback');

        if(invalid_feedback.length) {
            $this.after(invalid_feedback.clone());
        }

        $this.on("keyup paste", function() {
            $("#passeye-"+i).val($(this).val());
        });
        $("#passeye-toggle-"+i).on("click", function() {
            if($this.hasClass("show")) {
                $this.attr('type', 'password');
                $this.removeClass("show");
                $(this).removeClass("btn-outline-primary");
            }else{
                $this.attr('type', 'text');
                $this.val($("#passeye-"+i).val());              
                $this.addClass("show");
                $(this).addClass("btn-outline-primary");
            }
        });
    });

    $(".my-login-validation").submit(function() {
        var form = $(this);
        if (form[0].checkValidity() === false) {
          // event.preventDefault();
          // event.stopPropagation();
        }
        form.addClass('was-validated');
    });
});

  }

  render() {
    return (
      <div>

<Helmet>
	<script src="https://www.gstatic.com/firebasejs/ui/4.5.0/firebase-ui-auth.js"></script>
    <link type="text/css" rel="stylesheet" href="https://www.gstatic.com/firebasejs/ui/4.5.0/firebase-ui-auth.css" />
    <script src="https://www.gstatic.com/firebasejs/7.13.1/firebase-app.js"></script>
    <script src="https://www.gstatic.com/firebasejs/7.13.1/firebase-analytics.js"></script>

    <script src="https://code.jquery.com/jquery-3.3.1.slim.min.js" integrity="sha384-q8i/X+965DzO0rT7abK41JStQIAqVgRVzpbzo5smXKp4YfRvH+8abtTE1Pi6jizo" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/popper.js/1.14.7/umd/popper.min.js" integrity="sha384-UO2eT0CpHqdSJQ6hJty5KVphtPhzWj9WO1clHTMGa3JDZwrnQq4sF86dIHNDz0W1" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/js/bootstrap.min.js" integrity="sha384-JjSmVgyd0p3pXB1rRibZUAYoIIy6OrQ6VrjIEaFf/nJGzIxFDsf4x0xIM+B07jRM" crossorigin="anonymous"></script>
    <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.0/jquery.min.js" type="text/javascript" async></script>
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css" integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T" crossorigin="anonymous"/>
    

</Helmet>
<div className="my-login-page">
    <section className="h-100">
         <div className="reg-cont  h-100"> 
            <div className="row justify-content-md-center h-100">
                <div className="card-wrapper">
                    <div 
	                    className="brand"
	                    style={{ boxShadow: "0 4px 8px rgba(0,0,0,.05)" }}
	                  >
	                    <img src={logo} alt="logo" style ={{"opacity":1}}></img>
	                </div>
                    <div className="card fat">
                        <div className="card-body">
                            <h4 align="center" className="card-title">Register</h4>

                            
                            <div id="register">
                            <div className="my-login-validation" noValidate="">	
                              
                               {/* <input type="hidden"  id="phone_number" name="phone_number" value={this.state.username} onChange={this.changeHandler}/> */}
                               <input type="hidden" id="uid" name="uid" onChange={this.changeHandler}/>
                               <div className="form-group">
	                                <label htmlFor="phone_number_">Phone Number</label>
	                                <input id="phone_number_" type="text" className="form-control" name="phone_number_"  required autoFocus disabled readonly/>
	                                <div className="invalid-feedback">
	                                    What's your name?
	                                </div>
                                </div>

                                <div className="form-group">
	                                <label htmlFor="name">Name</label>
	                                <input id="name" type="text" className="form-control" value={this.state.name} name="name" onChange={this.changeHandler} required autoFocus/>
	                                <div className="invalid-feedback">
	                                    What's your name?
	                                </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Password
                                    </label>
                                    <input id="password" type="password" className="form-control" name="password" value={this.state.password} onChange={this.changeHandler} required data-eye/>
                                    <div className="invalid-feedback">
                                        Password is required
                                    </div>
                                </div>

                                 <div className="form-group">
                                    <label htmlFor="password">Confirm Password
                                    </label>
                                    <input id="cpassword" type="password" className="form-control" name="cpassword" value={this.state.cpassword}  onChange={this.changeHandler}  required data-eye/>
                                    <div className="invalid-feedback">
                                        Password is required
                                    </div>
                                    <div id="divCheckPassword" style={{color: "red"}}></div>
                                </div>

                                <div>
                                   <p> <input type="checkbox" id="cbTerms" name="cbTerms" defaultChecked={this.state.checkbox}   onChange={this.handleCheck} />
                                    <label htmlFor="cbTerms">I accept the <a href='https://sites.google.com/view/signo-privacy-policy'> Terms and Conditions</a>
                                    </label></p>
                             
                                </div>

                                <div className="form-group m-0">
                                    <button className="btn btn-primary btn-block" style={{"backgroundColor": "#ffcf0d","color":"black", "border": "0px"}}  onClick = {this.clickSubmit} >Submit</button>
                                </div>
                            </div>
                            </div>
                            <div id="user-signed-out" >
                                <div id="firebaseui-spa">
                                    <div id="firebaseui-container"></div>
                                </div>
                            </div>
                            <div className="mt-4 text-center">
                                    Already have an account? <a href="/">Login</a>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                      <b>
                      <div className="copyright" id="copyright">
                        &copy;	
                        <script>
                          document.getElementById('copyright').appendChild(document.createTextNode(new Date().getFullYear()))
                        </script>, Developed by
                        <a href="https://www.signo.in/" > Signo</a>
                       </div>
                        </b>
                    </div>
                </div>
            </div>
        </div>
    </section>

  
</div>
      </div>
    );
  }
}

export default Register;

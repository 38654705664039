import React, { Component } from "react";

import Button from "../../Utils/UI/Button/Button";
import Applicants from "./Applicants/Applicants";
import Profile from "./Profile/Profile";
import "../../Utils/UI/SearchBar/Search.css";

class Home extends Component {
  state = {
    id: "",
    jobId:"",
    searchQuery: "",
    totalApplications: 0,
    jobTitle: "",
    filter1:"",
    filter2:"",
    filter3:"",
  };


  callbackFunction = (totalApplications,jobTitle) => {
        this.setState({totalApplications: totalApplications,jobTitle:jobTitle})
  };

  userFunction = (id) => {
    this.setState({ id });
    let x = document.getElementById("collapseTwo");
    x.classList.add("show");
    
    let y = document.getElementById("collapseOne");
    y.classList.remove("show")
  };

  handleChange = (name) => (event) => {
    this.setState({ error: "" });
    this.setState({ [name]: event.target.value });
  };

  componentDidMount() {
    var jobId = this.props.match.params.jobId;
    // jobId= jobId.replace("M","");
    this.setState({jobId:jobId});

    document.title = "Signo Client Dashboard";
    if (localStorage.getItem("profiles")) {
      const applicants = JSON.parse(localStorage.getItem("profiles")).length;
      this.setState({ totalApplications: applicants });

    }
   
  }

  handleApplication = (job_id) => {
    // this.setState({redirect:true,job_id:job_id})
    const link = `/applicants/`+job_id
    window.open(link, "_blank")
  }

  componentDidUpdate(){
    // const t = JSON.parse(localStorage.getItem("profiles")).length;
    // if (this.state.totalApplications !== t)
    //   this.setState({ totalApplications: t });
  
  }


  filter = () => {
    let filter1 = 1;
    let filter2;
    if (document.getElementById("inlineRadio1").checked) {
      filter2 = true;
    } else if (document.getElementById("inlineRadio2").checked) {
      filter2 = false;
    } else {
      filter2 = null;
    }
    let filter3 = document.getElementById("filter3").value;
    if (!filter1) {
      filter1 = 1;
    }
    if (filter3 === "Registration Date") {
      filter3 = null;
    }
    this.setState({filter1,filter2,filter3});
  };

  render() {

    return (
      <div>
        <div className="search-container">
          <form link="/">
            <button type="submit">
              <i className="fa fa-search"></i>
            </button>
            <input
              type="text"
              placeholder="Search for any applicant driver"
              name="search"
              onChange={this.handleChange("searchQuery")}
            />
          </form>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-9">
              <div
                className="row"
                style={{ marginLeft: "20px", marginTop: "15px" }}
              >
                <Button count={this.state.totalApplications} title="Total Applicants"></Button>
                {/* <Button count={this.state.totalApplications} title="Unreviwed"></Button> */}
                <Button count={this.state.totalApplications} title="Under Review"></Button>
                <Button count={this.state.totalApplications} title="Awaiting VOC/KYC"></Button>
                <Button count={0} title="New"></Button>
              </div>
              <Applicants
                jobId = {this.state.jobId}
                userId={this.userFunction}
                searchQuery={this.state.searchQuery}
                filter1 = {this.state.filter1}
                filter2 = {this.state.filter2}
                filter3 = {this.state.filter3}
                parentCallback = {this.callbackFunction}
              />
            </div>
            <div className="col-3" style={{marginTop:"10px"}}>

              <div id="accordion">
                <div className="card">
                   <div className="card-header" id="headingOne">
                    <h5 className="mb-0 btn ">
                        Job Title- { this.state.jobTitle }
                    </h5>
                  </div>
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <button className="btn btn-link" data-toggle="collapse" data-target="#collapseOne" id="filterbtn" aria-expanded="true" aria-controls="collapseOne" style={{textDecoration:"none"}}>
                        FILTER
                      </button>
                    </h5>
                  </div>

                  <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">

                       
                  <div className="card-body">
                    {/* <div className="row" style={{ margin: "10px 0px" }}>
                      <div className="col-4">Rating Greater than</div>

                      <div className="col-8">
                        <input type="number" min="1" id="filter1" onChange={this.filter} />
                      </div>
                    </div> */}

                   <div className="row" style={{ margin: "0px" }}>
                      <div className="col-12 centre">
                        <button className="btn"onClick={()=>this.handleApplication(this.state.jobId+"M")}> My Shortlist</button>
                      </div>
                    </div>


                    <div className="row" style={{ margin: "10px 0px" }}>
                      <div className="col-4">Verified</div>

                      <div className="col-8">
                        <label className="radio-inline" style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio1"
                            value="yes"
                            onChange={this.filter}
                          />
                          YES&nbsp;
                        </label>
                        <label className="radio-inline" style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio2"
                            value="no"
                            onChange={this.filter}
                          />
                          NO&nbsp;
                        </label>
                        <label className="radio-inline" style={{fontSize:"12px"}}>
                          <input
                            type="radio"
                            name="inlineRadioOptions"
                            id="inlineRadio3"
                            value="all"
                            onChange={this.filter}
                          />
                          ALL
                        </label>
                      </div>
                    </div>
                    
                  

                    <div className="row" style={{ margin: "0px" }}>
                      <div className="col-12">
                        <select className="custom-select" id="filter3" 
                            onChange={this.filter} value="Applications age">
                          <option defaultValue>Applications age</option>
                          <option value="0">Today</option>
                          <option value="7">Last 7 days</option>
                          <option value="30">Last month</option>
                        </select>
                      </div>
                    </div>
                  </div> 


                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-header" id="headingTwo">
                    <h5 className="mb-0">
                      <button className="btn btn-link collapsed" id="profileBtn" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{textDecoration:"none"}}>
                        PROFILE
                      </button>
                    </h5>
                  </div>
                  <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                      <Profile userId={this.state.id} 
                        jobId ={this.state.jobId}/>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;

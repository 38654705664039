import React,{useEffect} from 'react';
import 'date-fns'
import DateFnsUtils from '@date-io/date-fns'
import "./SmsPopup";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers'
//import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
//import DatePicker from 'react-datepicker';
//import 'react-datepicker/dist/react-datepicker.css';
import { MuiThemeProvider } from "@material-ui/core/styles";
import { createMuiTheme } from '@material-ui/core'

import { NotificationManager } from 'react-notifications';

import {schedulespoccall} from "../auth";

export const customTheme = createMuiTheme({
    palette: {
        primary: {
            main: '#ffcf0d',
        },
    },
})



function TimePopup() { 



    const [selectedDate1, setSelectedDate1] = React.useState(new Date());


    const handleDateChange1 = (data) => {
        var hour = data.getHours()
        var minutes = data.getMinutes()
        var seconds = data.getSeconds()
        
        let time = hour.toString() +":"+minutes.toString() +":"+seconds.toString()
        localStorage.setItem('fromTime',time)
        // console.log(data.toLocaleTimeString(navigator.language, {
        //     hour: '2-digit',
        //     minute:'2-digit'
        //   }))
        setSelectedDate1(data)
        //console.log(data)
    }

    const [selectedDate2, setSelectedDate2] = React.useState(new Date());

    const handleDateChange2 = (data) => {
    //    alert(data.getHours())
    //    alert(data.getMinutes())
    //    alert(data.getSeconds())

        var hour = data.getHours()
        var minutes = data.getMinutes()
        var seconds = data.getSeconds()
        
        let time = hour.toString() +":"+minutes.toString() +":"+seconds.toString()
        localStorage.setItem('toTime',time)
        // alert(time)
        // alert(typeof(time))
        setSelectedDate2(data)
    }
         useEffect(()=>{
             console.log(selectedDate2)
            fetch(`${process.env.REACT_APP_API_URL}/api/get_jobs/`, {
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                  Authorization:`Bearer ${JSON.parse(localStorage.getItem('jwt')).new_token}`
                },
                redirect: "follow",
                body: JSON.stringify({
                  "client_id":JSON.parse(localStorage.getItem('jwt')).client_id
                }),
              }).then((response) => {
               
                return response.json();
              }).then((response)=>{
                    // console.log(response.data)
               let userData =     response.data.filter((data)=>{
                                    return data.job_id === localStorage.getItem('jobid')
                    })
                   var date = new Date()
                 var endtime =  userData[0].schedule_spoc_end_time
                 var starttime2 =  userData[0].schedule_spoc_start_time

                var endhour =   endtime.substr(0,2)
                var endmin =endtime.substr(3,2)
                var starthour =   starttime2.substr(0,2)
                var startmin =starttime2.substr(3,2)

                   var day = date.getDay()
              
                 var Month = date.getMonth()
                 var year = date.getFullYear()
                 var endDate = new Date(year,Month,day,endhour,endmin)
                 var startDate = new Date(year,Month,day,starthour,startmin)
                 console.log(userData)
                    setSelectedDate2(endDate)
                   setSelectedDate1(startDate)

              })
         },[])

    const handelDate = () => {
        
       
        const job_id = localStorage.getItem('jobid')
        const client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        console.log(client_id);
        // alert(job_id)
        // const params2 = "job_id: \""+job_id+"\",client_id:\""+client_id+"\",start_datetime:\""+selectedDate1+"\",end_datetime:\""+selectedDate2+"\"";
        const params2 = "job_id: \""+job_id+"\",client_id:\""+client_id+"\",start_datetime:\""+localStorage.getItem('fromTime')+"\",end_datetime:\""+localStorage.getItem('toTime')+"\"";
        schedulespoccall(params2)
        .then((data)=>{
        console.log(data)
        });

        
        //   addToast("content", {
        //    appearance: 'info',
        //    autoDismiss: true,
        //  })
        // 
        NotificationManager.info("Request Sent");
    }

    return (
        <div className="popup"  >

            {/* <button type="button" class="btn btn-primary" data-target="#contactpopup" data-toggle="modal">SMS</button>*/}

            <div className="modal" id="contactpopup" tabIndex="-1"  >
                <div className="modal-dialog modal-lg" >
                    <div className="modal-content"  >
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ color: "black" }}><AccessTimeIcon />   Schedule SPOC Call</h5>
                            {/*<button type="button" className="close" data-dismiss="modal" aria-label="Close" >
                                <span aria-hidden="true">&times;</span>
                            </button>*/}
                        </div>

                        <div className="popup-row modal-body" >


                            <h6>At this time Bot will conduct a conference call with the Candidate & SPOC?</h6>

                        </div>

                        <div className="popup-row modal-body">

{/* 
                            <div className="row">

                                <div className="popup-column col-md-6" >
                                    <div className="card" >
                                        <div className="card-header">
                                            <h6 className="bold-text">From </h6>
                                        </div>
                                        <div className="card-body" style={{ bgColor: "red" }}>
                                            <MuiThemeProvider theme={customTheme}>
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <form>

                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            variant='inline'
                                                            format='MM/dd/yy'
                                                            margin='normal'
                                                            id='date-from'
                                                            label="Date"
                                                            value={selectedDate1}
                                                            onChange={handleDateChange1}
                                                            KeyboardButtonProps={{ 'arial-lable': 'change date' }}
                                                        />
                                                    </form>
                                                </ MuiPickersUtilsProvider>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <form>
                                                        <KeyboardTimePicker

                                                            margin='normal'
                                                            id='time-to'
                                                            label="Time"
                                                            value={selectedDate1}
                                                            onChange={handleDateChange1}
                                                            KeyboardButtonProps={{ 'arial-lable': 'change date' }}
                                                        />
                                                    </form>
                                               
                                                </ MuiPickersUtilsProvider>
                                            </MuiThemeProvider>
                                        </div>
                                    </div>
                                </div>
                                <div className="popup-column col-md-6">
                                    <div className="card" >
                                        <div className="card-header">
                                            <h6 className="bold-text">To </h6>
                                        </div>
                                        <div className="card-body">
                                             <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <form>
                                                        <KeyboardDatePicker
                                                            disableToolbar
                                                            variant='inline'
                                                            format='MM/dd/yy'
                                                            margin='normal'
                                                            id='date-to'
                                                            label="Date"
                                                            value={selectedDate2}
                                                            onChange={handleDateChange2}
                                                            KeyboardButtonProps={{ 'arial-lable': 'change date' }}
                                                        />
                                                    </form>
                                          
                                                </ MuiPickersUtilsProvider>

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <form>
                                                        <KeyboardTimePicker

                                                            margin='normal'
                                                            id='time-to'
                                                            label="Time"
                                                            value={selectedDate2}
                                                            onChange={handleDateChange2}
                                                            KeyboardButtonProps={{ 'arial-lable': 'change date' }}
                                                        />
                                                    </form>
                                                </ MuiPickersUtilsProvider> */}

                                            {/* </MuiThemeProvider > */}
                                        {/* </div>
                                    </div>
                                </div>



                            </div> */}
                            
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-6 text-center"> 
                                    <h6 className="bold-text">From </h6>
                                    </div>
                                    <div className="col-sm-6 text-center">
                                    <h6 className="bold-text">To </h6>
                                    </div>
                                    </div>
                            <div className="row">
                               <div className="col-sm-6 text-center">
                                  
                                  <MuiThemeProvider theme={customTheme}>
                                                

                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <form>
                                                      <KeyboardTimePicker

                                                            margin='normal'
                                                            id='time-to'
                                                            label="Time"
                                                            value={selectedDate1}
                                                            onChange={handleDateChange1}
                                                            KeyboardButtonProps={{ 'arial-lable': 'change date' }}
                                                        />
                                                    </form>
                                               
                                                </ MuiPickersUtilsProvider>
                                            </MuiThemeProvider>
                               </div>
                               <div className="col-sm-6 text-center">
                               <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <form>
                                                        <KeyboardTimePicker

                                                            margin='normal'
                                                            id='time-to'
                                                            label="Time"
                                                            value={selectedDate2}
                                                            onChange={handleDateChange2}
                                                            KeyboardButtonProps={{ 'arial-lable': 'change date' }}
                                                        />
                                                    </form>
                                                </ MuiPickersUtilsProvider>
                               </div>
                            </div>
                            <br /><br />
                            <div className="row">
                                <div className="col-sm-7">
                            <button
                                type="submit"
                                className="submit btn btn-primary pull-right"
                                style={{ width: "100px", marginLeft: "20px", borderRadius: "100px", textAlign: "centre" }}
                                
                            onClick={handelDate}
                            >Schedule</button>
                            </div>
                            </div>
                            </div>
                            <br></br>

                            {/* <button
                                type="submit"
                                className="submit btn btn-primary pull-right"
                                style={{ width: "100px", marginLeft: "20px", borderRadius: "100px", textAlign: "centre" }}
                                
                            onClick={handelDate}
                            >Schedule</button> */}
                        </div>
                    </div>
                </div>
            </div>






        </div>
    );
}
export default TimePopup;


export const login = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/client_login/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(user),
  }).then((response) => {
    return response.json();
  });
};

export const regClient = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/reg_client_web/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(user),
  }).then((response) => {
    return response.json();
  });
};

export const forgotPasword = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/forgot_password/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    redirect: "follow",
    body: JSON.stringify(user),
  }).then((response) => {
    return response.json();
  });
};
export const isAuthenticated = () => {
  if (typeof window == "undefined") {
    return false;
  }
  if (localStorage.getItem("jwt")) {
    return JSON.parse(localStorage.getItem("jwt"));
  } else {
    return false;
  }
};

export const authenticate = (jwt, next) => {
  if (typeof window != undefined) {
    localStorage.setItem("jwt", JSON.stringify(jwt));
    next();
  }
};

export const getAllUser = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/job_applications_web_api/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuthenticated().new_token,
    },
    redirect: "follow",
    body: JSON.stringify(user),
  }).then((response) => {
    return response.json();
  });
};

export const getJobReport = (user) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/job_report/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuthenticated().new_token,
    },
    redirect: "follow",
    body: JSON.stringify(user),
  }).then((response) => {
    return response.json();
  });
};
export const getJobBotReportApi = (user) => {
  console.log(user)
  return fetch(`${process.env.REACT_APP_API_URL}/api/job_bot_report_api/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuthenticated().new_token,
    },
    redirect: "follow",
    body: JSON.stringify(user),
  }).then((response) => {
    return response.json();
  });
};

export const excelSheet = (params) => {
  return fetch(
    `${process.env.REACT_APP_API_URL}/api/export_job_applications/`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + isAuthenticated().new_token,
      },
      redirect: "follow",
      body: JSON.stringify(params),
    }
  ).then((response) => {
    return response.json();
  });
};

export const getAllJobs = (params) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/get_jobs/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuthenticated().new_token,
    },
    redirect: "follow",
    body: JSON.stringify(params),
  }).then((response) => {
    return response.json();
  })
  
};

export const getApplicationDetails = (params) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/application_details/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuthenticated().new_token,
    },
    redirect: "follow",
    body: JSON.stringify(params),
  }).then((response) => {
    return response.json();
  })
  
};

export const createJob = (params) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/client_post_job_web/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuthenticated().new_token,
    },
    redirect: "follow",
    body: JSON.stringify(params),
  }).then((response) => {
    return response.json();
  });
};
export const editJob = (params) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/client_edit_job_web/`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: "Bearer " + isAuthenticated().new_token,
    },
    redirect: "follow",
    body: JSON.stringify(params),
  }).then((response) => {
    return response.json();
  });
};


//EditUser

export const editUserProfile = (params) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/edit_profile/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated().new_token,
        },
        redirect: "follow",
        body: JSON.stringify(params),
    }).then((response) => {
        return response.json();
    });
};

export const getUserProfile = (params) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/get_profile/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated().new_token,
        },
        redirect: "follow",
        body: JSON.stringify(params),
    }).then((response) => {
        return response.json();
    });
};


export const connectSpocToDriver = (params) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/connect_spoc_to_driver/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated().new_token,
        },
        redirect: "follow",
        body: JSON.stringify(params),
    }).then((response) => {
        return response.json();
    });
};

export const sendWhatsappMsg= (params) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/send_whatsapp_msg/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated().new_token,
        },
        redirect: "follow",
        body: JSON.stringify(params),
    }).then((response) => {
        return response.json();
    });
};


//CompanyInfo


export const companyInfo = (params) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/company_profile/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated().new_token,
        },
        redirect: "follow",
        body: JSON.stringify(params),
    }).then((response) => {
        return response.json();
    });
};

export const getCompanyInfo = (params) => {
    return fetch(`${process.env.REACT_APP_API_URL}/api/get_company_profile/`, {
        method: "POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + isAuthenticated().new_token,
        },
        redirect: "follow",
        body: JSON.stringify(params),
    }).then((response) => {
        return response.json();
    });
};



export const shortList = (data) => {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + isAuthenticated().new_token);
  // data=((data).replace("{","").replace("}",""))
  console.log(data);
  var graphql = JSON.stringify({
    query: "mutation {\n  shortlist_profile("+(data)+") {\n    job {\n      id, shortlisted\n    }\n    action\n  }\n}\n",
    variables: {}
  })
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
  };

   return fetch(`${process.env.REACT_APP_API_URL}/graphql`, requestOptions)
    .then((response) => {
    return response.json();})
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

};

export const unlockDriver = (data) => {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + isAuthenticated().new_token);
  // data=((data).replace("{","").replace("}",""))
  var data_ = "mutation {\n  unlock_profile("+data+"\n  ) {\n    job {\n      id, shortlisted,\n    }\n    action, balance\n  }\n}\n"
  console.log(data_);
  var graphql = JSON.stringify({
  query: "mutation {\n  unlock_profile("+data+"\n  ) {\n    job {\n      id, shortlisted,\n    }\n    action, balance\n  }\n}\n",
    variables: {}
  })
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
  };

   return fetch(`${process.env.REACT_APP_API_URL}/graphql`, requestOptions)
    .then((response) => {
    return response.json();})
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

};

export const clientDetails = (data) => {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + isAuthenticated().new_token);
  var graphql = JSON.stringify({
    query: "query {\n client(client_id:\""+data+"\"){\n  id, name, phone_number, email, company {id, comp_name pan_no } current_package{id, description, name, amount, max_jobs, max_applications } balance balance_validity is_staff location{id phone_number place_name} created_at password\n }\n}\n",
    variables: {}
  })
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
  };

   return fetch(`${process.env.REACT_APP_API_URL}/graphql`, requestOptions)
    .then((response) => {
    return response.json();})
    .then(result => {return result})
    .catch(error => console.log('error', error));

};

export const schedulespoccall = (data) => {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + isAuthenticated().new_token);
  // data=((data).replace("{","").replace("}",""))
  var data_ = "mutation {\n  spoc_call(  "+data+"\n  ) {\n    job {\n      id\n    }\n    action\n  }\n}\n"
  console.log(data_);
  var graphql = JSON.stringify({
  query: "mutation {\n  spoc_call("+data+"\n  ) {\n    job {\n      id\n    }\n    action\n  }\n}\n",
    variables: {}
  })
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
  };

   return fetch(`${process.env.REACT_APP_API_URL}/graphql`, requestOptions)
    .then((response) => {
    return response.json();})
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

};

export const smscandidate = (data) => {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + isAuthenticated().new_token);
  // data=((data).replace("{","").replace("}",""))
  var data_ = "mutation {\n  sms_applicants(  "+data+"\n  ) {\n    job {\n      id,shortlisted,\n    }\n    action\n  }\n}\n"
  console.log(data_);
  var graphql = JSON.stringify({
  query: "mutation {\n  sms_applicants("+data+"\n  ) {\n    job {\n      id,shortlisted,\n    }\n    action\n  }\n}\n",
    variables: {}
  })
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
  };

   return fetch(`${process.env.REACT_APP_API_URL}/graphql`, requestOptions)
    .then((response) => {
    return response.json();})
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

};

export const smsshorlistedcandidate = (data) => {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + isAuthenticated().new_token);
  // data=((data).replace("{","").replace("}",""))
  var data_ = "mutation {\n  sms_shortlisted_applicants(  "+data+"\n  ) {\n    job {\n      id,shortlisted,\n    }\n    action\n  }\n}\n"
  console.log(data_);
  var graphql = JSON.stringify({
  query: "mutation {\n  sms_shortlisted_applicants("+data+"\n  ) {\n    job {\n      id,shortlisted,\n    }\n    action\n  }\n}\n",
    variables: {}
  })
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
  };

   return fetch(`${process.env.REACT_APP_API_URL}/graphql`, requestOptions)
    .then((response) => {
    return response.json();})
    .then(result => console.log(result))
    .catch(error => console.log('error', error));

};

export const paymenthistory = (data) => {


  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  // myHeaders.append("Authorization", "Bearer " + isAuthenticated().new_token);
  var graphql = JSON.stringify({
    query: "query {\n client(client_id:\""+data+"\"){\n  id, payment_id, request_id, amount, last_requests,new_requests, response, is_paid, timestamp, package{id, name, amount, max_jobs  }\n }\n}\n",
    variables: {}
  })
  var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: graphql,
    redirect: 'follow'
  };

   return fetch(`${process.env.REACT_APP_API_URL}/graphql`, requestOptions)
    .then((response) => {
    return response.json();})
    .then(result => {return result})
    .catch(error => console.log('error', error));

};

export const listpayment = (params) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/list_payment/`, {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + isAuthenticated().new_token,
      },
      redirect: "follow",
      body: JSON.stringify(params),
  }).then((response) => {
      return response.json();
  });
};

export const addmanager = (params) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/add_manager/`, {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + isAuthenticated().new_token,
      },
      redirect: "follow",
      body: JSON.stringify(params),
  }).then((response) => {
      return response.json();
  });
};
export const listmanager = (params) => {
  return fetch(`${process.env.REACT_APP_API_URL}/api/list_manager/`, {
      method: "POST",
      headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + isAuthenticated().new_token,
      },
      redirect: "follow",
      body: JSON.stringify(params),
  }).then((response) => {
      return response.json();
  });
};
import React, { Component } from 'react';


class KYC extends Component {




    render() {

        return (
                <div className="post-container container">

                    <div className="header">
                    <div className="second-top-bar">
                        <h1 className="heading" style={{ color: "black" }}>KYC Documents</h1>
                            <br></br>
                        </div>
                    </div>

                    {/* <div className="header"> */}
                    {/*     <div className="second-top-bar"> */}
                    {/*         <p><h5>Why all this fuss?    </h5></p> */}
                    {/*         <p>Telecome regulations in India obligate us to keep your KYC documents. <a href="# ">Learn More</a> about what documents you can submit. Make sure flash is installed and popups are not blocked.</p> */}
                    {/*         <br></br> */}
                    {/*     </div> */}
                    {/* </div> */}

                    <div className="header">
                        <div className="second-top-bar">
                            <p>Your Account's KYC Status: <b><h5>Completed</h5></b></p>
                        </div>
                    </div>

                    <div className="header">
                        <div className="second-top-bar">
                            <br></br>
                            <table className="table table-stripped">
                                <tbody style={{ textAlign: "left" }}>
                                    <tr>
                                        <td><b>DOCUMENT TYPE</b></td>
                                        <td></td>
                                        <td><b>FILE</b></td>
                                        <td></td>
                                        <td><b>STATUS</b></td>
                                        <td></td>
                                        <td><b>UPLOAD (Max 10MB)</b></td>
                                    </tr>

                                    {/*{reportlist.map(reportlistItem => (
                                <tr key={reportlistItem.key}>
                                    <td><b>{reportlistItem.report}</b></td>
                                    <td></td>
                                    <td><a href={reportlistItem.link} style={{ color: "black" }}>Download</a></td>
                                </tr>
                            ))}  */}

                                    <tr>
                                        <td><b>Company Pan Card:</b></td>
                                        <td></td>
                                        <td><a href="# ">PAN.jpeg</a></td>
                                        <td></td>
                                        <td>Not Verified</td>
                                        <td></td>
                                        <td><input type="file" id="panCardFile" name="filename2"/></td>


                                    </tr>

                                    <tr>
                                        <td><b>Certificate of incorporation:</b></td>
                                        <td></td>
                                        <td><a href="# ">INC.jpeg</a></td>
                                        <td></td>
                                        <td>Not Verified</td>
                                        <td></td>
                                        <td><input type="file" id="incFile" name="filename2" /></td>
                                    </tr>

                                    <tr>
                                        <td><b>Company Address Proof:</b></td>
                                        <td></td>
                                        <td><a href="# ">ADDR.jpeg</a></td>
                                        <td></td>
                                        <td>Not Verified</td>
                                        <td></td>
                                        <td><input type="file" id="addrFile" name="filename2" /></td>
                                    </tr>

                                    <tr>
                                        <td><b>Your Passport Size Photo:</b></td>
                                        <td></td>
                                        <td><a href="# ">PIC.jpeg</a></td>
                                        <td></td>
                                        <td>Not Verified</td>
                                        <td></td>
                                        <td><input type="file" id="picFile" name="filename2" /></td>

                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
            </div>

        );

    }
}

export default KYC;
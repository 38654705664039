import React, { Component } from "react";
import { companyInfo,getCompanyInfo } from "../../Utils/auth";
import "./CompanyInfo.css";
import { NotificationManager} from 'react-notifications';

class CompanyInfo extends Component {
    constructor(props) {
        super(props);

        this.state = {
            companyName: "",
            brandName: "",
            email: "",
            panCard: "",
            gstNumber: "",
            address1: "",
            address2: "",
            city: "",
            pinCode: "",
            success: false,
            error: "",
        };
    }

    changeHandler = (e) => {
        this.setState({ success: false });
        this.setState({ [e.target.name]: e.target.value });
    };

    submitHandler = (e) => {
        e.preventDefault();
        var client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const company = {
            company_name: this.state.companyName,
            brand_name: this.state.brandName,
            email: this.state.email,
            pan_card: this.state.panCard,
            gst_number: this.state.gstNumber,
            address1: this.state.address2,
            address2: this.state.address2,
            city: this.state.city,
            pinCode: this.state.pinCode,
            client_id: client_id,
        };
        console.log(company);
        companyInfo(company)
            .then((response) => {
                console.log(response);
                if (!response.success) {
                    this.setState({
                        success: true,
                        companyName: "",
                        brandName: "",
                        email: "",
                        panCard: "",
                        gstNumber: "",
                        address1: "",
                        address2: "",
                        city: "",
                        pinCode: "",
                        error: "",
                    });
                    NotificationManager.info("Saved Successfully");
                }
            })
            .catch((err) => {
                console.log("ERROR ", err);
            });
    };

    componentWillMount(){
        var client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
        const user = {
      
            client_id: client_id,
        };

        console.log(user);
        getCompanyInfo(user)
            .then((response) => {
                console.log(response);
                if (response.status) {
                    this.setState({
                       
                        companyName: response.company_name,
                        email: response.email,
                        brandName: response.brand_name,
                        // lastName: response.last_name,
                        panCard: response.pan_card,
                        gstNumber: response.gst_number,
                        address1: response.address1,
                        address2: response.address2,
                        city: response.city,
                        pinCode: response.pinCode,
                        // phone: response.phone_number,
                       
                    });
                    if (response.email==="" || response.email===null){
                        NotificationManager.warning("Profile Incomplete")
                    }
                }
            })
            .catch((err) => {
                console.log("ERROR ", err);
            });
    }
    render() {
        const {
            companyName,
            brandName,
            email,
            panCard,
            gstNumber,
            address1,
            address2,
            city,
            pinCode
        } = this.state;

        return (
            <div className=" form-container container">

                <h1 className="heading">Company Info</h1>
                <br></br>

                {this.state.success && (
                    <div class="card">
                        <div class="card-body">Company Information Successfully...</div>
                    </div>
                )}

                <form onSubmit={this.submitHandler}>

                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="CompanyLegalName" className="tag">Company Legal Name</label>
                                <br></br>
                                <input type="text" name="companyName" defaultValue={companyName} onChange={this.changeHandler} className="form-control" id="Company" placeholder="Enter company name" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="BrandName" className="tag">Brand Name</label>
                                <br></br>
                                <input type="text" name="brandName" defaultValue={brandName} onChange={this.changeHandler} className="form-control" id="brand" placeholder="Enter brand name" />
                            </div>
                        </div>

                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="BillingEmail" className="tag">Billing Email </label>
                                <br></br>
                                <input type="email" name="email" defaultValue={email} onChange={this.changeHandler} className="form-control" id="Email" aria-describedby="emailHelp" placeholder="Enter email" />
                                <small id="emailHelp" className="form-text text-muted"></small>
                            </div>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="PanCard" className="tag">Pan Card</label>
                                <br></br>
                                <input type="text" name="panCard" defaultValue={panCard} onChange={this.changeHandler} className="form-control" id="First-name" placeholder="Enter pan card no." />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="GSTNumber" className="tag">GST Number (if available)</label>
                                <br></br>
                                <input type="text" name="gstNumber" defaultValue={gstNumber} onChange={this.changeHandler} className="form-control" id="First-name" placeholder="Enter GST no." />
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <label htmlFor="Address1" className="tag">Address 1 (Full Billing Address)</label>
                        <br></br>
                        <input type="text" name="address1" defaultValue={address1} onChange={this.changeHandler} className="form-control" id="Address" placeholder="Enter address 1" />
                    </div>

                    <div className="form-group">
                        <label htmlFor="Address2" className="tag">Address 2 (optional)</label>
                        <br></br>
                        <input type="text" name="address2" defaultValue={address2} onChange={this.changeHandler} className="form-control" id="Address" placeholder="Enter address 2" />
                    </div>

                    <div className="row">
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="City" className="tag">City</label>
                                <br></br>
                                <input type="text" name="city" defaultValue={city} onChange={this.changeHandler} className="form-control" id="First-name" placeholder="Enter city" />
                            </div>
                        </div>
                        <div className="col">
                            <div className="form-group">
                                <label htmlFor="PostalCode" className="tag">Postal Code (Pin Code)</label>
                                <br></br>
                                <input type="text" name="pinCode" defaultValue={pinCode} onChange={this.changeHandler} className="form-control" id="First-name" placeholder="Enter postal code" />
                            </div>
                        </div>

                        
                    </div>

                    <div className="form-group">
                        <br></br>
                        <label htmlFor="PostalCode" className="tag">Attach PAN Card Details:  <input type="file" id="panFile" name="filename2" /></label>
                        
                    </div>

                    <div className="row">
                        <div className="col">
                            
                        </div>
                        <div className="col">
                            <br></br>
                            <br></br>
                        </div>
                        <div className="col">
                            <button type="submit" className="submit btn btn-primary" style={{ width: "100%", borderRadius: "100px", textAlign: "center" }}>Submit</button>
                        </div>
                    </div>
                </form>
            </div>

        );

    }
}

export default CompanyInfo;

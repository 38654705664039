
import React from 'react';
function ButtonPopup({dataQ}) {
   
     

    return (
        <div className="container-fluid">

          

            <div className="modal" id="contactpopup" tabIndex="-1"  >
                <div className="modal-dialog "  >
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" style={{ color: "black",margin:"auto",marginBottom:"20px" }}>Questions</h5>
                           
                        </div>
                        {
                            dataQ.length ?(
                        

                        <div className="popup-row modal-body h-50">
                           <table className="table">
                                
                                <tr>
                                    {
                                        dataQ.map((data)=>{
                                           return <th style={{fontSize:"15px"}}>{Object.keys(data)}</th>
                                        })
                                    }
                                </tr>
                                <tr>
                                 {dataQ.map((data,index)=>{
                                       return <td style={{fontSize:"15px"}}>{data[Object.keys(dataQ[index])]}</td>
                                 })}
                                </tr>
                                
                           </table>
                        </div>):
                        <div className="popup-row modal-body h-50">
                             <h4 className="text-center text-dark" >Candidate is not Interested</h4>
                            </div>
}
                    </div>
                </div>
            </div>






        </div>
    );
}
export default ButtonPopup;

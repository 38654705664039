import React,{useState,useRef} from 'react';
import "./MakePayment.css";

function ExtendValidity() {
  
    const [state,setState] = useState(0)
    const refRadio1 = useRef()
    const refRadio2 = useRef()
    const refRadio3 = useRef()
    const [TotalAMountAfterDeducy,setTotalAMountAfterDeducy] = useState(state)
    function changeState(e) {
        refRadio1.current.checked = false
        refRadio2.current.checked = false
        refRadio3.current.checked = false
        setState(e.target.value)
        setTotalAMountAfterDeducy(0)
       
        // refRadio.current.checked = "false"
     
    }
    function deducyAmount(e) {
         
        setTotalAMountAfterDeducy(Number(state) - Number(e.target.value))  
          
    }
    return (
        <div>
         
            <form>

                <table className="table table-stripped">
                    <tbody>
                        <tr>
                            <td>
                                <div >
                                    <label className="choice-radio" style={{ display: "inline" }}><input id="radio1" value="23598" type="radio" name="optradio" onChange={(e)=>changeState(e)} />Rs. 19,999.00 : 6 months validity + 0 credits</label>
                                </div>
                            </td>
                            <td></td>
                            <td>
                                <div>
                                    <label className="choice-radio" style={{ display: "inline" }}><input id="radio2" type="radio" name="optradio" value="58408" onChange={(e)=>changeState(e)}  />Rs. 49,499.00 : 12 months validity + 0 credits</label>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
            <div className="header">
                <div className="second-top-bar">
                    <p><b>Payment Summary</b>    </p>

                    <div class="row">
                        <div class="col-lg-auto">
                            GST (18%)
                        </div>
                        <div class="col-lg-auto">
                            :
                        </div>
                        <div class="col-lg-auto">
                            {/* Rs. 00.00 */}
                            Rs. {state}
                        </div>
                    </div>

                    {/*                     <div class="row"> */}
                    {/*                         <div class="col-lg-auto"> */}
                    {/*                             State GST */}
                    {/*                         </div> */}
                    {/*                         <div class="col-lg-auto"> */}
                    {/*                             : */}
                    {/*                          </div> */}
                    {/*                         <div class="col-lg-auto"> */}
                    {/*                             Rs. 00.00 */}
                    {/*                         </div> */}
                    {/*                     </div> */}
                    {/*  */}
                    {/*                     <div class="row"> */}
                    {/*                         <div class="col-lg-auto"> */}
                    {/*                             Integrated GST */}
                    {/*                         </div> */}
                    {/*                         <div class="col-lg-auto"> */}
                    {/*                             : */}
                    {/*                         </div> */}
                    {/*                         <div class="col-lg-auto"> */}
                    {/*                             Rs. 1079.82 */}
                    {/*                         </div> */}
                    {/*                     </div> */}

                </div>
            </div>



            <div className="header" >
                <div className="second-top-bar">
                    <br></br>
                    <br></br>
                    <div class="row" >
                        <div class="col-lg-auto" >
                            <p><b>Deducy TDS at?</b>    </p>
                        
                        <form>
                            <label class="choice-radio" style={{display: "inline"}}>
                                <input ref={refRadio1} type="radio" value="0"  name="optradio" onChange={deducyAmount} />None
                            </label>
                                <br></br>
                            <label class="choice-radio" style={{ display: "inline" }}>
                                <input ref={refRadio2} type="radio" value="0.01" name="optradio" onChange={deducyAmount} />1%
                            </label>
                                <br></br>
                            <label class="choice-radio" style={{ display: "inline" }}>
                                <input   ref={refRadio3} type="radio" value="0.015" name="optradio" onChange={deducyAmount} />1.5%
                            </label>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
     
            <br></br>
            {/* <p><b>Total Amount to be paid: Rs. 7078.82</b>    </p> */}
              <p><b>Total Amount to be paid: Rs. {TotalAMountAfterDeducy}</b>    </p>
            <br></br>
            <br></br>
            <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }} onClick={()=>{alert(TotalAMountAfterDeducy)}}>Proceed</button>

        </div>


    );
}
export default ExtendValidity;
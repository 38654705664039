import React from 'react';
import "./MakePayment.css";


function Upgrade() {
    return (
        <div className="paymentBody">
            <table className="table table-stripped">
                <tbody>
                    <tr>
                        <td><input type="checkbox" /> Rs. 19,999.00 : 6 months validity + 0 credits </td>
                        <td></td>
                        <td><input type="checkbox" /> Rs. 49,499.00 : 12 months validity + 0 credits </td>
                    </tr>
                </tbody>
            </table>

            <div className="header">
                <div className="second-top-bar">
                    <p><b>Payment Summary</b>    </p>
                    
                    <div class="row">
                        <div class="col-lg-auto">
                         GST (18%)
                        </div>
                        <div class="col-lg-auto">
                            :
                        </div>
                        <div class="col-lg-auto">
                            Rs. 00.00
                        </div>
                    </div>

{/*                     <div class="row"> */}
{/*                         <div class="col-lg-auto"> */}
{/*                             State GST */}
{/*                         </div> */}
{/*                         <div class="col-lg-auto"> */}
{/*                             : */}
{/*                          </div> */}
{/*                         <div class="col-lg-auto"> */}
{/*                             Rs. 00.00 */}
{/*                         </div> */}
{/*                     </div> */}
{/*  */}
{/*                     <div class="row"> */}
{/*                         <div class="col-lg-auto"> */}
{/*                             Integrated GST */}
{/*                         </div> */}
{/*                         <div class="col-lg-auto"> */}
{/*                             : */}
{/*                         </div> */}
{/*                         <div class="col-lg-auto"> */}
{/*                             Rs. 3599.82 */}
{/*                         </div> */}
{/*                     </div> */}

                </div>
            </div>

            <div className="header">
                <div className="second-top-bar">

                    <br></br>
                    <br></br>
                    <div class="row">
                        <div class="col-lg-auto">
                            <p><b>Deducy TDS at?</b>    </p>
                        </div>
                        <div class="col-lg-auto">
                            <td><input type="checkbox" checked /> None</td>
                        </div>
                        <div class="col-lg-auto">
                            <td><input type="checkbox" /> 1%</td>
                        </div>
                        <div class="col-lg-auto">
                            <td><input type="checkbox" /> 1.5%</td>
                        </div>
                    </div>



                </div>
            </div>
            <br></br>
            <br></br>
            <p><b>Total Amount to be paid: Rs. 23598.82</b>    </p>
            <br></br>
            <br></br>
            <button type="submit" className="submit btn btn-primary" style={{ margin: "4px", borderRadius: "100px", textAlign: "center" }}>Proceed</button>

        </div>


    );
}
export default Upgrade;
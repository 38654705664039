import React, { Component } from 'react';


class BillPlan extends Component {




    render() {

        return (

                <div className="post-container container">
                    

                    <div className="header">
                        <div className="second-top-bar">
                        <h1 className="heading" style={{ color: "black" }}>Your Plan</h1>
                            <br></br>

                           <h6 className="heading" >1 Credit = 1 Applicant</h6>
                        </div>
                    </div>


                  


                    <div className="header">

                        <table className="table table-stripped">
                            <tbody style={{ textAlign: "left" }}>
                                <tr>
                                    <td><b>ITEM</b></td>
                                    <td></td>
                                    <td><b>QUOTA</b></td>
                                    <td></td>
                                    <td><b>DESCRIPTION</b></td>
                                </tr>

                                {/*{reportlist.map(reportlistItem => (
                                <tr key={reportlistItem.key}>
                                    <td><b>{reportlistItem.report}</b></td>
                                    <td></td>
                                    <td><a href={reportlistItem.link} style={{ color: "black" }}>Download</a></td>
                                </tr>
                            ))}  */}

                                <tr>
                                    <td><b>Max applicant</b></td>
                                    <td></td>
                                    <td>300 Max</td>
                                    <td></td>
                                    <td>Your currently plan can shorlist max 300 Candidate, irrespective of job post ( 1 Credit will be deducted for every shorlisting)</td>

                                </tr>

                                <tr>
                                    <td><b>Max Job Post</b></td>
                                    <td></td>
                                    <td>5 Max</td>
                                    <td></td>
                                    <td>You can post max active 5 Job at time, No limit for inactive Jobs</td>

                                </tr>

                                <tr>
                                    <td><b>SMS/ Voice Call/ Video Call Charges</b></td>
                                    <td></td>
                                    <td>--</td>
                                    <td></td>
                                    <td>1 Successful attempt is allowed per Candidate for all medium</td>

                                </tr>
                               
                                <tr>
                                    <td><b>Other Features</b></td>
                                    <td></td>
                                    <td>--</td>
                                    <td></td>
                                    <td>Basic KYC, Video KYC & Video CV is Free of Cost</td>

                                </tr>
                              
                                <tr>
                                    <td><b>Validity</b></td>
                                    <td></td>
                                    <td>6 Months</td>
                                    <td></td>
                                    <td>Your Plan will be valid for 6 Months.</td>

                                </tr>



                            </tbody>
                        </table>

                    </div>
                </div>
        );

    }
}

export default BillPlan;
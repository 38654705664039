import React, { Component } from "react";
import { createJob, editJob } from "../../Utils/auth";
import "./PostJob.css";
import { NotificationManager } from "react-notifications";
import { TimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns"; // choose your lib

import DoneOutlineIcon from "@material-ui/icons/DoneOutline";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";

class PostJob extends Component {
  constructor(props) {
    super(props);

    this.state = {
      companyName: "",
      jobDescription: "",
      supervisor: "",
      jobTitle: "",
      requirements: "",
      spockNo: "",
      radius: "",
      averageSalary: "",
      city: "",
      contactNo: "",
      sk_type: "",
      dl_type: "",
      cl_type: "",
      lg_type: "",
      question1: "",
      question_m: "",
      success: false,
      error: "",
      job_id: "",
      skill:""
    };
  }

  changeHandler = (e) => {
    this.setState({ success: false });
    this.setState({ [e.target.name]: e.target.value });
  };

  handleDropDown = () => {
    console.log(document.getElementById("personType"));
  };
  componentWillMount() {
    var is_profile_complete = localStorage.getItem("is_profile_complete");
    console.log(is_profile_complete);
    if (is_profile_complete === "false") {
      window.location = "/edituserinfo";
    }
    // window.location="/edituserinfo"

    if (this.props.match.params.edit) {
      let job_id = localStorage.getItem("edit_jd");
      this.setState({
        job_id: job_id,
      });
      const user = {
        job_id: job_id,
      };
      editJob(user).then((response) => {
        console.log(response);
        if (response.status) {
          this.setState({
            companyName: response.company_name,
            requirements: response.requirment,
            radius: response.radius,
            city: response.address,
            averageSalary: response.salary,
            spockNo: response.spockNo,
            jobTitle: response.jobTitle,
            jobDescription: response.jobDescription,
            question1: response.question1,
            question_m: response.question_m,
            skill:response.skill
          });
        }
      });
    }
  }
  submitHandler = (e) => {
    e.preventDefault();
    var skill = document.getElementById("sk_type");
    var lang = document.getElementById("lg_type");
    var skill = skill.options[skill.selectedIndex].value;

    var lang = lang.selectedIndex;
    var client_id = JSON.parse(localStorage.getItem("jwt")).client_id;
    const user = {
      company_name: this.state.companyName,
      skill: skill,
      requirment: this.state.requirements,
      radius: this.state.radius,
      address: this.state.city,
      salary: this.state.averageSalary,
      phone_number: this.state.contactNo,
      lang: lang,
      spockNo: this.state.spockNo,
      jobTitle: this.state.jobTitle,
      jobDescription: this.state.jobDescription,
      question1: this.state.question1,
      question_m: this.state.question_m,
      client_id: client_id,
      job_id: this.state.job_id,
      
    };
    console.log(user);
    createJob(user)
      .then((response) => {
        console.log(response);

        if (!response.status) {
          this.setState({
            success: true,
            companyName: "",
            jobDescription: "",
            supervisor: "",
            jobTitle: "",
            requirements: 0,
            radius: 0,
            averageSalary: 0,
            city: "",
            contactNo: "",
            spockNo: "",
            sk_type: "",
            dl_type: "",
            cl_type: "",
            lg_type: "",
            error: "",
          });
          NotificationManager.warning(response.msg);
        } else {
          NotificationManager.success("Job Posted");
          window.location = "/";
        }
      })
      .catch((err) => {
        console.log("ERROR ", err);
        NotificationManager.warning("Something went wrong !");
      });
  };

  render() {
    const {
      companyName,
      requirements,
      jobDescription,
      spockNo,
      jobTitle,
      radius,
      averageSalary,
      city,
      question1,
      question_m,
    } = this.state;

    return (
      <div className="post-container container">
        <h1 className="heading">Post Job</h1>
        <br />

        {this.state.success && (
          <div class="card">
            <div class="card-body">Job Posted Successfully...</div>
          </div>
        )}
        <form onSubmit={this.submitHandler}>
          <div className="row">
            <div className="col lg-6">
              <div className="form-group">
                <label htmlFor="InputCompanyName" className="tag">
                  Company Name
                </label>
                <br />
                <input
                  name="companyName"
                  value={companyName}
                  type="text"
                  className="form-control"
                  id="InputCompanyName"
                  placeholder="Enter company name"
                  onChange={this.changeHandler}
                  required
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="InputRequirement1" className="tag">
                  {" "}
                </label>
                <br />
                <div
                  className="col lg-6"
                  style={{ "text-align": "center", "vertical-align": "middle" }}
                >
                  <div className="btn-group">
                    <select
                      id="sk_type"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        height: "40px",
                      }}
                      required="true"
                    >
                      <option selected disabled value="">
                        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                        &nbsp; {this.state.skill}
                      </option>

                      <option name="skill_type" onChange={this.changeHandler}>
                        &nbsp; Truck Driver
                      </option>
                      <option
                        name="skill_type"
                        value={0}
                        onChange={this.changeHandler}
                      >
                        &nbsp; 4 Wheeler Driver
                      </option>
                      <option
                        name="skill_type"
                        value={1}
                        onChange={this.changeHandler}
                      >
                        &nbsp; 2 Wheeler Driver
                      </option>

                      <option
                        name="skill_type"
                        value={2}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Security Guards/Custodian
                      </option>

                      <option
                        name="skill_type"
                        value={3}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Packer/ WareHouse Manager
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="InputRequirement1" className="tag">
                  Total Requirement
                </label>
                <br />
                <input
                  name="requirements"
                  value={requirements}
                  type="number"
                  className="form-control"
                  id="InputRequirement"
                  placeholder="10"
                  style={{ width: "30%" }}
                  onChange={this.changeHandler}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="InputexampleCity" className="tag">
                  City
                </label>
                <br />
                <input
                  name="city"
                  value={city}
                  type="text"
                  className="form-control"
                  id="City"
                  placeholder="Enter city"
                  onChange={this.changeHandler}
                  required
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="InputexampleRadius" className="tag">
                  Radius
                </label>
                <br />
                <input
                  name="radius"
                  value={radius}
                  type="number"
                  className="form-control"
                  id="Radius"
                  placeholder="Enter radius (KM)"
                  onChange={this.changeHandler}
                  required
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="InputAverageSalary" className="tag">
                  Salary
                </label>
                <br />
                <input
                  name="averageSalary"
                  value={averageSalary}
                  className="form-control"
                  id="AverageSalary"
                  placeholder="Enter salary"
                  onChange={this.changeHandler}
                  required
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="InputRequirement1" className="tag">
                  {" "}
                </label>
                <br />
                <div
                  className="col lg-6"
                  style={{ textAlign: "center", verticalAlign: "middle" }}
                >
                  <div className="">
                    <select
                      id="lg_type"
                      style={{
                        width: "100%",
                        borderRadius: "10px",
                        height: "40px",
                      }}
                    >
                      <option
                        name="language_type"
                        disabled
                        selected
                        onChange={this.changeHandler}
                      >
                        &nbsp; Other Language
                      </option>
                      <option
                        name="language_type"
                        value={0}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Bengali
                      </option>
                      <option
                        name="language_type"
                        value={1}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Gujarati
                      </option>
                      <option
                        name="language_type"
                        value={2}
                        onChange={this.changeHandler}
                      >
                        &nbsp; Kannada
                      </option>
                      <option
                        name="language_type"
                        value={3}
                        onChange={this.changeHandler}
                      >
                        &nbsp; marathi
                      </option>
                      <option
                        name="language_type"
                        value={4}
                        onChange={this.changeHandler}
                      >
                        &nbsp; odia
                      </option>
                      <option
                        name="language_type"
                        value={5}
                        onChange={this.changeHandler}
                      >
                        &nbsp; punjabi
                      </option>
                      <option
                        name="language_type"
                        value={6}
                        onChange={this.changeHandler}
                      >
                        &nbsp; tamil
                      </option>
                      <option
                        name="language_type"
                        value={7}
                        onChange={this.changeHandler}
                      >
                        &nbsp; telugu
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="InputJobTitle" className="tag">
                  Job Title
                </label>
                <br />
                <input
                  name="jobTitle"
                  value={jobTitle}
                  type="text"
                  className="form-control"
                  id="InputJobTitle"
                  placeholder="Open Body Truck Driver"
                  onChange={this.changeHandler}
                  required
                />
              </div>
            </div>

            <div className="col">
              <div className="form-group">
                <label htmlFor="SpockNo" className="tag">
                  Supervisor Mobile Number
                </label>
                <br />
                <input
                  name="spockNo"
                  value={spockNo}
                  type="number"
                  className="form-control"
                  id="InputSpockNo"
                  placeholder="Enter SPOC Mobile No"
                  onChange={this.changeHandler}
                  required
                />
                {/* <TimePicker value={selectedDate} onChange={handleDateChange} /> */}
                {/* <MuiPickersUtilsProvider utils={DateFnsUtils}><TimePicker /></MuiPickersUtilsProvider> */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col">
              <div className="form-group">
                <label htmlFor="JobDescription" className="tag">
                  Job Description
                </label>
                <br />
                <textarea
                  type="text"
                  name="jobDescription"
                  defaultValue={jobDescription}
                  onChange={this.changeHandler}
                  className="jb form-control"
                  id="brand"
                  placeholder="Enter Job Description (eg. Address, Salary Structure)"
                  rows="7"
                  cols="20"
                />
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col">
              <lable className="form-group tag">
                Job-Related Questions (Optional)
              </lable>
              <p className="form-group tag">
                <i>
                  Based on the questions provided by you candidate will br
                  filtered & shortlisted
                </i>
              </p>

              <div className="form-group">
                <label htmlFor="InputSupervisor" className="tag">
                  Question #1 (*This question will be asked to candidate)
                </label>
                <br />
                <input
                  name="question1"
                  defaultValue={question1}
                  type="text"
                  className="form-control"
                  id="question1"
                  placeholder="eg. Do you have a Bike?"
                  onChange={this.changeHandler}
                />
              </div>

              <div className="form-group">
                <label htmlFor="InputSupervisor" className="tag">
                  Question # (*optional add Multiple Question in Newline)
                </label>
                <br />
                <textarea
                  type="text"
                  name="question_m"
                  defaultValue={question_m}
                  onChange={this.changeHandler}
                  className="form-control"
                  id="question_m"
                  placeholder="eg. Is your age less than 28?"
                  rows="7"
                  cols="5"
                />
              </div>
            </div>
          </div>

          <br />
          {/* <button
                          type="submit"

                          className="btn btn-outline submit"
                          style={{ color: "white", borderRadius: "100px" }}
                      >
                          Submit
          </button>*/}

          {/*Pricing Section*/}

          {/*               <section id="pricing"> */}
          {/*  */}
          {/*                   <div className="header"> */}
          {/*                       <lable><b>Type of Jobs</b> (Please Choose from below)</lable> */}
          {/*                       <br></br> */}
          {/*                  */}
          {/*                   </div> */}
          {/*  */}
          {/*                   <div className="row"> */}
          {/*  */}
          {/*                       <div className="job-pricing-column col-lg-3 col-md-6"> */}
          {/*                           <div className="job-pricing-choose-card"> */}
          {/*                            */}
          {/*                               <div className="job-price-card" style={{ */}
          {/*                                   borderTop: "10px solid #e8e8e8" */}
          {/*                               }}> */}
          {/*                                */}
          {/*                                   <div className="card-header" style={{ height: "150px" }}> */}
          {/*                                       <h3>FREE $0</h3> */}
          {/*                               </div> */}
          {/*                                   <div className="card-body" style={{ height: "500px" }}> */}
          {/*                                    */}
          {/*                                           <h5 className="job-price-h5"><b>Normal</b > x applications</h5> */}
          {/*                                   <br></br> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5">Limited applications</h5> */}
          {/*                               </div> */}
          {/*                                */}
          {/*                               </div> */}
          {/*                                   <div className="card-header" style={{ borderTop: "1px solid #ffcf0d" }}> */}
          {/*                                      */}
          {/*                 <h5 style={{ color: "#ffcf0d" }}><input type="checkbox" />  <b>CHOOSE</b></h5>
           */}
          {/*  */}
          {/*  */}
          {/*                                   </div> */}
          {/*                               </div> */}
          {/*                       </div> */}
          {/*  */}
          {/*                       <div className="job-pricing-column col-lg-3 col-md-6"> */}
          {/*                           <div className="job-pricing-choose-card"> */}
          {/*                            */}
          {/*                               <div className="job-price-card" style={{ */}
          {/*                                   borderTop: "10px solid #ffcf0d" */}
          {/*                               }}> */}
          {/*                               <div className="card-header" style={{ height: "150px" }}> */}
          {/*                                   <h3 style={{ color: "#ffcf0d" }}><b>GOLD $300<br></br></b></h3> */}
          {/*                                           <h5 className="job-price-h5">Most Popular</h5> */}
          {/*                                           <h5 className="job-price-h5">10 Days</h5> */}
          {/*                                       <br></br> */}
          {/*                                    */}
          {/*                               </div> */}
          {/*                               <div className="card-header"> */}
          {/*                                   <a href="# " style={{ color: "#ffcf0d" }}>See preview</a> */}
          {/*                               </div> */}
          {/*                               <div className="card-body"> */}
          {/*                                           <h5 className="job-price-h5"><b>10</b > x applications</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon"/> View all applications</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon" /> Top of page promotion</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon" /> Highlighted as gold</h5> */}
          {/*                                   <br></br> */}
          {/*                               </div> */}
          {/*                               </div> */}
          {/*                                   <div className="card-header" style={{ borderTop: "1px solid #ffcf0d" }}> */}
          {/*                 <h5 style={{ color: "#ffcf0d" }}><input type="checkbox" />  <b>CHOOSE</b></h5>
           */}
          {/*  */}
          {/*  */}
          {/*                                   </div> */}
          {/*                               </div> */}
          {/*                       </div> */}
          {/*  */}
          {/*                           <div className="job-pricing-column col-lg-3 col-md-6"> */}
          {/*                               <div className="job-pricing-choose-card" > */}
          {/*                            */}
          {/*                               <div className="job-price-card" style={{ */}
          {/*                                   borderTop: "10px solid #ffcf0d" */}
          {/*                               }}> */}
          {/*                               <div className="card-header" style={{ height: "150px" }}> */}
          {/*                                   <h3 style={{ color: "#ffcf0d" }}><b>GOLD + URGENT $499</b></h3> */}
          {/*                                           <h5 className="job-price-h5">15 Days</h5> */}
          {/*                                       <br></br> */}
          {/*  */}
          {/*                               </div> */}
          {/*                               <div className="card-header"> */}
          {/*                                       <a href="# " style={{ color: "#ffcf0d" }}>See preview</a> */}
          {/*  */}
          {/*                               </div> */}
          {/*                               <div className="card-body"> */}
          {/*                                           <h5 className="job-price-h5"><b>20</b > x applications</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon" /> View all applications</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon" /> Top of page promotion</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon" /> Highlighted as 'Gold' and 'Urgent'</h5> */}
          {/*                                   <br></br> */}
          {/*                               </div> */}
          {/*                               </div> */}
          {/*                                   <div className="card-header" style={{ borderTop: "1px solid #ffcf0d" }}> */}
          {/*                 <h5 style={{ color: "#ffcf0d" }}><input type="checkbox" />  <b>CHOOSE</b></h5>
           */}
          {/*  */}
          {/*  */}
          {/*                                   </div> */}
          {/*                               </div> */}
          {/*                       </div> */}
          {/*  */}
          {/*                           <div className="job-pricing-column col-lg-3 col-md-6"> */}
          {/*                                */}
          {/*                           <div className="job-pricing-choose-card"> */}
          {/*  */}
          {/*                            */}
          {/*                               <div className="job-price-card" style={{ */}
          {/*                                   borderTop: "10px solid #28abb9" */}
          {/*                               }}> */}
          {/*                                   <div class="ribbon ribbon-top-right"><span>Best choice</span></div> */}
          {/*                               <div className="card-header" style={{ height: "150px" }}> */}
          {/*                                        */}
          {/*                                       <h3 style={{ color: "#ffcf0d" }}><b>RAPID <br></br>HIRE $800</b></h3> */}
          {/*                                           <h5 className="job-price-h5">90 Days</h5> */}
          {/*                                           <br></br> */}
          {/*                               </div> */}
          {/*                               <div className="card-header"> */}
          {/*                                           <h5 className="job-price-h5" style={{ color: "#ffcf0d" }}>Instant 60 profiles</h5> */}
          {/*                                        */}
          {/*                               </div> */}
          {/*                               <div className="card-body"> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon" /> Post this job and get instant candidate profiles on mail</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon"/> Candidates filtered based on your job</h5> */}
          {/*                                   <br></br> */}
          {/*                                           <h5 className="job-price-h5"><DoneOutlineIcon className="tick-icon" /> Candidates get your job details on SMS</h5> */}
          {/*                                   <br></br> */}
          {/*                                   <h6 style={{color: "green"}}><VerifiedUserIcon /><i><b> GARENTEE</b></i></h6> */}
          {/*                                   <br></br> */}
          {/*                               </div> */}
          {/*                                   </div> */}
          {/*                                   <div className="card-header" style={{ borderTop: "1px solid #ffcf0d" }}> */}
          {/*                   <h5 style={{ color: "#ffcf0d" }}><input type="checkbox" />  <b>CHOOSE</b></h5>
           */}
          {/*  */}
          {/*                                   </div> */}
          {/*                               </div> */}
          {/*                       </div> */}
          {/*  */}
          {/*  */}
          {/*  */}
          {/*                   </div> */}
          {/*               </section> */}

          <button
            type="submit"
            className="btn submit"
            style={{ color: "white" }}
          >
            Submit
          </button>
        </form>
      </div>
    );
  }
}

export default PostJob;
